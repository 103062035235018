import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { TextDocumentDto } from "../../autogen/bff-api";
import { TipTap } from "../input/TipTap/TipTap";

export const TextDocumentModal = ({
  textDocument,
  onClose,
  onUpdate,
  isUpdating,
  editable = true,
}: {
  textDocument: TextDocumentDto;
  onClose: () => void;
  onUpdate?: ({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => void | Promise<void>;
  isUpdating: boolean;
  editable?: boolean;
}) => {
  const [name, setName] = useState<string>();
  const [modifiedAt, setModifiedAt] = useState<Date>();
  const [tags, setTags] = useState<string[]>();
  const [showTagInput, setShowTagInput] = useState(false);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    setName(textDocument.name);
  }, [textDocument.name]);

  useEffect(() => {
    setTags(textDocument.tags);
  }, [textDocument.tags]);

  return (
    <Modal isOpen={!!textDocument} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent height="80%" maxHeight="80%" overflow="hidden">
        <ModalCloseButton right="4" />
        {isUpdating && <Spinner position="absolute" top="2" left="6" color="smMuted" />}
        <ModalBody width="full" height="full" maxHeight="full">
          <Flex
            justifyContent="start"
            flexDirection={"column"}
            height={"full"}
            maxHeight="full"
            overflowY="hidden"
            width="full"
            pt="4"
            pb="6"
          >
            <Flex flexDirection="column" columnGap="2" pt="5" pb="3" px="1px">
              <Input
                placeholder={`${t("Document name")}...`}
                value={name ?? ""}
                isDisabled={!editable}
                _disabled={{ opacity: 1 }}
                fontWeight="semibold"
                onChange={async (e) => {
                  setName(e.target.value);
                  await onUpdate?.({ title: e.target.value });
                  setModifiedAt(new Date());
                }}
              />
            </Flex>
            <Flex flexWrap="wrap" columnGap="1" rowGap="1">
              {tags?.map((tag) => (
                <Tag key={tag} size="sm" alignItems="center">
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton
                    aria-label="remove tag"
                    onClick={async () => {
                      const updatedTags = tags.filter((t) => t !== tag);
                      setTags(updatedTags);
                      await onUpdate?.({ tags: updatedTags });
                      setModifiedAt(new Date());
                    }}
                  />
                </Tag>
              ))}
              {editable && !showTagInput && (
                <Button
                  size="xs"
                  variant="outline"
                  rightIcon={<Icon as={FaPlus} />}
                  colorScheme="teal"
                  onClick={() => setShowTagInput(true)}
                >
                  {t("Add tag")}
                </Button>
              )}
              {showTagInput && (
                <InputGroup display="inline-flex" width="40">
                  <Input size="xs" rounded="md" pr="6" value={newTag} onChange={(e) => setNewTag(e.target.value)} />
                  <InputRightElement width="6" height="6">
                    <IconButton
                      size="xs"
                      aria-label="save tag"
                      icon={<Icon as={FaCheck} />}
                      roundedLeft="none"
                      colorScheme="teal"
                      onClick={async () => {
                        const updatedTags = [...(tags ?? []), newTag];
                        setTags(updatedTags);
                        await onUpdate?.({ tags: updatedTags });
                        setModifiedAt(new Date());
                        setNewTag("");
                        setShowTagInput(false);
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
              )}
            </Flex>
            <Text fontSize="sm" color="smMuted" pt="2" pb="1">{`${t("Last updated")} ${(
              modifiedAt ?? new Date(textDocument.modifiedAt)
            ).toLocaleString()}`}</Text>
            <Flex flexDirection="column" grow="1" overflow="hidden">
              <TipTap
                editable={editable}
                content={textDocument.text}
                maxHeight="full"
                onUpdate={async (content) => {
                  await onUpdate?.({ content });
                  setModifiedAt(new Date());
                }}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
