import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Spinner,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { PhoneNumberInput } from '../../../../../personal-profile/edit/PhoneNumberInput'
import { displayPersonNameWithEmail } from '../../../../view-single/sharing/AddParticipantModal'
import { useContractState } from '../../../useContractState'
import { RemoveDataFieldButton } from '../RemoveDataFieldButton'
import { PersonSelectValue, PersonSelector } from './PersonSelector'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const CounterpartyContactPerson: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const contractState = useContractState()
    const [isLoading, setIsLoading] = useState(false)
    const errorMessage = containsError(
        contractState,
        'EditCounterpartyContactPerson'
    )
        ? t('Update failed')
        : null
    const dispatch = useAppDispatch()
    const [fullName, setFullName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [selectedPhoneNumber, setSelectedPhoneNumber] =
        useState<PhoneNumberInput | null>(null)

    const [selectedPerson, setSelectedPerson] =
        useState<PersonSelectValue | null>(null)

    useEffect(() => {
        if (
            contractState.contract.dataFields?.counterpartyContactPerson?.person
        ) {
            setSelectedPerson({
                value: contractState.contract.dataFields
                    .counterpartyContactPerson.person.id,
                label: displayPersonNameWithEmail(
                    contractState.contract.dataFields.counterpartyContactPerson
                        .person
                ),
            })
        }
    }, [contractState.contract.dataFields?.counterpartyContactPerson?.person])

    useEffect(() => {
        if (contractState.contract.dataFields?.counterpartyContactPerson) {
            setSelectedPhoneNumber(
                contractState.contract.dataFields.counterpartyContactPerson
                    .phoneNumber
                    ? {
                          country: {
                              name: contractState.contract.dataFields
                                  .counterpartyContactPerson.phoneNumber
                                  .callingCode.countryName,
                              alpha3Code:
                                  contractState.contract.dataFields
                                      .counterpartyContactPerson.phoneNumber
                                      .callingCode.countryAlpha3Code,
                              callingCode:
                                  contractState.contract.dataFields
                                      .counterpartyContactPerson.phoneNumber
                                      .callingCode.value,
                          },
                          number: contractState.contract.dataFields
                              .counterpartyContactPerson.phoneNumber.number,
                      }
                    : null
            )

            setFullName(
                contractState.contract.dataFields.counterpartyContactPerson
                    .fullName
            )
            setEmail(
                contractState.contract.dataFields.counterpartyContactPerson
                    .email
            )
        }
    }, [])

    const eventHandler = async (values: {
        fullName: string | null
        email: string | null
        phoneNumber: PhoneNumberInput | null
        personId: string | null
    }) => {
        setIsLoading(true)
        await dispatch(
            editContractThunk({
                command: {
                    type: 'EditCounterpartyContactPerson',
                    value: {
                        fullName: values.fullName,
                        email: values.email,
                        phoneNumber: values.phoneNumber?.country
                            ? {
                                  callingCode: {
                                      countryName:
                                          values.phoneNumber.country.name,
                                      countryAlpha3Code:
                                          values.phoneNumber.country.alpha3Code,
                                      value: values.phoneNumber.country
                                          .callingCode,
                                  },
                                  number: values.phoneNumber.number,
                              }
                            : null,
                        personId: values.personId,
                    },
                },
            })
        )
        setIsLoading(false)
    }

    const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), [])

    useEffect(() => {
        return () => {
            debouncedEventHandler.cancel()
        }
    }, [])

    const customValuesAreSet = (): boolean => {
        if (fullName && fullName.trim().length !== 0) {
            return true
        }

        if (email && email.trim().length !== 0) {
            return true
        }

        if (selectedPhoneNumber) {
            return true
        }

        return false
    }

    const counterpartyPersonIsSelected = (): boolean => {
        if (contractState.contract.counterparty?.organization) {
            return selectedPerson !== null
        }
        return false
    }

    return (
        <FormControl
            isInvalid={errorMessage !== null}
            isRequired={isRequiredButNotProvided}
        >
            <FormLabel htmlFor={'value'}>
                {t('Counterparty contact person')}{' '}
                {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Flex w="100%">
                <Flex borderRadius={'10px'} w="100%" flexDirection={'column'}>
                    {contractState.contract.counterparty?.organization && (
                        <PersonSelector
                            options={contractState.contract.counterparty?.organization.persons.map(
                                (e) => ({
                                    label: displayPersonNameWithEmail(e),
                                    value: e.id,
                                })
                            )}
                            value={selectedPerson ?? null}
                            onChange={(e) => {
                                setSelectedPerson(e)
                                debouncedEventHandler({
                                    fullName: null,
                                    email: null,
                                    phoneNumber: null,
                                    personId: e?.value ?? null,
                                })
                            }}
                            label={''}
                            placeholder={null}
                            helperText={''}
                            errorMessage={null}
                            isDisabled={customValuesAreSet()}
                        />
                    )}

                    <Input
                        borderRadius={'3px'}
                        bg="white"
                        placeholder={t('Full name') ?? ''}
                        type="text"
                        size="sm"
                        value={fullName ?? ''}
                        isDisabled={counterpartyPersonIsSelected()}
                        onChange={(e) => {
                            setFullName(e.target.value)
                            debouncedEventHandler({
                                fullName: e.target.value,
                                email: email ?? null,
                                phoneNumber: selectedPhoneNumber,
                                personId: null,
                            })
                        }}
                    />
                    <Input
                        borderRadius={'3px'}
                        bg="white"
                        mt="5px"
                        placeholder={t('Email') ?? ''}
                        type="text"
                        size="sm"
                        value={email ?? ''}
                        isDisabled={counterpartyPersonIsSelected()}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            debouncedEventHandler({
                                fullName: fullName ?? null,
                                email: e.target.value,
                                phoneNumber: selectedPhoneNumber,
                                personId: null,
                            })
                        }}
                    />
                    <Box mt="5px">
                        <PhoneNumberInput
                            bg="white"
                            size="sm"
                            onChange={(phoneNumberInput) => {
                                setSelectedPhoneNumber(phoneNumberInput)
                                debouncedEventHandler({
                                    fullName: fullName ?? null,
                                    email: email ?? null,
                                    phoneNumber: phoneNumberInput,
                                    personId: null,
                                })
                            }}
                            onClear={() => {
                                console.log('clearing')
                            }}
                            value={selectedPhoneNumber}
                            isDisabled={counterpartyPersonIsSelected()}
                        />
                    </Box>
                </Flex>
                <Flex>
                    <RemoveDataFieldButton removeDataField={removeDataField} />
                </Flex>
            </Flex>
            <FormHelperText>{t('Provide contact info')}</FormHelperText>
            <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
