import { UserRole } from "./types";

export const selectableRoles: UserRole[] = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "SourcingCreator",
    label: "Sourcing Creator",
  },
  {
    value: "SourcingViewer",
    label: "Sourcing Viewer",
  },
  {
    value: "ContractCreator",
    label: "Contract Creator",
  },
  {
    value: "ContractViewer",
    label: "Contract Viewer",
  },
];

export const roles: UserRole[] = [
  ...selectableRoles,
  {
    value: "Owner",
    label: "Owner",
  },
];
