import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { UppyUploader } from "../../../../../common/documents/UppyUploader/UppyUploader";
import { eventIsLive } from "../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";

export const BasicSourcingEventDocumentUploader = () => {
  const eventState = useBasicSourcingEventState();
  const { t } = useTranslation();
  return (
    <>
      {eventIsLive(eventState.event) && (
        <Alert status="info" borderRadius={"10px"} mt="37px">
          <AlertIcon />
          <AlertTitle>{t("You are live!")}</AlertTitle>
          <AlertDescription>{t("Files you upload will immediately be visible to invited suppliers")}</AlertDescription>
        </Alert>
      )}
      <UppyUploader
        uploadEntityType={"SourcingEvent"}
        entityId={eventState.id}
        uploads={eventState.uploads.value}
        documents={eventState.documents.value}
        isRequired={false}
      />
    </>
  );
};
