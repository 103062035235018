import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, JSXElementConstructor, ReactElement, SetStateAction } from "react";
import { Populater } from "../../../../../autogen/bff-api";
import { ProductGroup, ProductGroupDraft } from "../../../../../common/types";
import { ConfirmationModalConfig } from "../../../../../common/useConfirmationModal";
import { ProductFieldForm } from "./ProductFieldForm";

export const ProductFieldTable = ({
  group,
  populatedBy,
  setGroup,
  confirmationModal,
}: {
  group: ProductGroup | ProductGroupDraft;
  populatedBy: Populater;
  setGroup: Dispatch<SetStateAction<ProductGroup | ProductGroupDraft>>;
  confirmationModal: {
    show: (config: ConfirmationModalConfig) => void;
    modal: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  };
}) => {
  return (
    <Table size={"sm"}>
      <Thead>
        <Tr>
          <Th width="80">{t("Name")}</Th>
          <Th width="40">{t("Type")}</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {group.productFields
          .filter((field) => field.populatedBy === populatedBy)
          .map((field, i) => (
            <ProductFieldForm
              key={i}
              field={field}
              group={group}
              setGroup={setGroup}
              confirmationModal={confirmationModal}
            />
          ))}
        {populatedBy === "Buyer" && (
          <ProductFieldForm
            isDisabled={true}
            field={{
              id: "quantity",
              name: t("Quantity"),
              isRequired: false,
              populatedBy: "Buyer",
              type: "Quantity",
            }}
            group={group}
            setGroup={setGroup}
            confirmationModal={confirmationModal}
          />
        )}
        {populatedBy === "Supplier" && (
          <ProductFieldForm
            isDisabled={true}
            field={{
              id: "unitPrice",
              name: t("Unit Price"),
              isRequired: false,
              populatedBy: "Supplier",
              type: "Number",
            }}
            group={group}
            setGroup={setGroup}
            confirmationModal={confirmationModal}
          />
        )}
      </Tbody>
    </Table>
  );
};
