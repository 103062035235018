import { Button, Flex, Icon, Input, Select, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, isEqual, isFinite } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import {
  ContractRole,
  DefaultNotificationSettingsDto,
  IntervalUnit,
  OrganizationRoleType,
  RecurringContractReminderDto,
  ReminderIntervalDto,
  useUpdateOrganizationMutation,
} from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../common/input/Selector/MultiSelector";
import { useAppDispatch } from "../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../common/redux/reducers/authStateReducer";
import { initSettings } from "./Settings";

type RecurringContractReminder = Omit<RecurringContractReminderDto, "interval"> & {
  interval: Partial<ReminderIntervalDto>;
};

const initSetting = (): RecurringContractReminder => ({
  interval: {
    amount: undefined,
    unit: "Days",
  },
  receivers: {
    persons: [],
    organizationRoles: [],
    contractRoles: [],
  },
});

const organizationContractRoles: OrganizationRoleType[] = ["Owner", "Admin", "ContractCreator", "ContractViewer"];
const contractRoles: ContractRole[] = ["CounterParty", "InternalOwner", "InvitedParty"];

const isRecurringContractReminderDto = (
  setting: RecurringContractReminder
): setting is RecurringContractReminderDto => {
  return !!setting.interval.unit && isFinite(setting.interval.amount);
};

export const RecurringContractReminderSettingForm = () => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const [isVisible, setIsVisible] = useState(false);
  const [setting, setSetting] = useState<RecurringContractReminder>(initSetting());

  const saveSetting = useCallback(
    async (setting: RecurringContractReminder) => {
      if (!isRecurringContractReminderDto(setting)) throw Error("Invalid recurring reminder setting!");
      const settings: DefaultNotificationSettingsDto =
        cloneDeep(authState.selectedOrg.defaultNotificationSettings) ?? initSettings();
      if (settings?.contractNotificationSettings.noEndDateNotifications.find((s) => isEqual(s, setting))) {
        toast({ description: "This setting already exists", variant: "subtle", status: "error" });
        return;
      }
      settings.contractNotificationSettings.noEndDateNotifications.push(setting);
      const response = await updateOrg({
        orgId: authState.selectedOrg.id,
        editOrganizationRequest: {
          defaultNotificationSettings: settings,
        },
      });

      if ("data" in response) {
        dispatch(updateSelectedOrg(response.data as OrgDto));
        setSetting(initSetting());
        setIsVisible(false);
      }
    },
    [authState.selectedOrg.defaultNotificationSettings, authState.selectedOrg.id, dispatch, toast, updateOrg]
  );

  const usersWithContractAccess = useMemo(() => {
    return authState.selectedOrg.users.filter((u) => u.roles.some((role) => organizationContractRoles.includes(role)));
  }, [authState.selectedOrg.users]);

  if (!isVisible)
    return (
      <Flex>
        <Button
          rightIcon={<Icon as={FaPlus} />}
          size="xs"
          variant={"ghost"}
          colorScheme="teal"
          onClick={() => setIsVisible(true)}
        >
          {t("Create new")}
        </Button>
      </Flex>
    );

  return (
    <Flex
      position={"relative"}
      zIndex={"2"}
      flexDirection={"column"}
      rowGap={"2"}
      border="1px solid"
      borderColor={"gray.100"}
      rounded="md"
      p="2"
      pb="48"
    >
      <Flex columnGap={"2"}>
        <Input
          type="number"
          width="30%"
          size={"md"}
          rounded="md"
          placeholder={`${t("Duration")}...`}
          min={0}
          value={setting.interval.amount === undefined ? "" : setting.interval.amount}
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.interval.amount = e.target.value === "" ? undefined : +e.target.value;
            setSetting(updatedSetting);
          }}
        />
        <Select
          width={"30%"}
          value={setting.interval.unit}
          size={"md"}
          rounded="md"
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.interval.unit = e.target.value as IntervalUnit;
            setSetting(updatedSetting);
          }}
        >
          <option value="Days">{t("Days")}</option>
          <option value="Months">{t("Months")}</option>
        </Select>
      </Flex>
      <MultiSelector
        value={usersWithContractAccess
          .filter((u) => setting.receivers.persons.includes(u.person.id))
          .map((u) => ({ label: `${u.person.firstName} ${u.person.lastName}`, value: u.person.id }))}
        options={usersWithContractAccess.map((u) => ({
          label: `${u.person.firstName} ${u.person.lastName}`,
          value: u.person.id,
        }))}
        noOptionsAvailableMessage={`${t("No users found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.persons = options.map((o) => o.value);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select individual receivers")}...` ?? "", color: "gray.500" }}
      />
      <MultiSelector
        value={setting.receivers.organizationRoles.map((role) => ({ label: role, value: role }))}
        options={organizationContractRoles.map((role) => ({
          label: role,
          value: role,
        }))}
        noOptionsAvailableMessage={`${t("No roles found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.organizationRoles = options.map((o) => o.value as OrganizationRoleType);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select receivers by role in organization")}...` ?? "", color: "gray.500" }}
      />
      <MultiSelector
        value={setting.receivers.contractRoles.map((role) => ({ label: role, value: role }))}
        options={contractRoles.map((role) => ({
          label: role,
          value: role,
        }))}
        noOptionsAvailableMessage={`${t("No roles found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.contractRoles = options.map((o) => o.value as ContractRole);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select receivers by role in contract")}...` ?? "", color: "gray.500" }}
      />
      <Flex>
        <Button
          colorScheme="blue"
          size={"xs"}
          rightIcon={<Icon as={FaCheck} />}
          isLoading={isLoading}
          isDisabled={setting.interval.amount === undefined}
          onClick={() => saveSetting(setting)}
        >
          {t("Save")}
        </Button>
      </Flex>
    </Flex>
  );
};
