import { Box, Button, Flex, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";

export interface SignerInput {
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  id: string;
  index: number;
  remove: () => void;
  showRemoveButton: boolean;
  containsError: boolean;
}

export const InvitedSigner = ({ id, index, remove, showRemoveButton, containsError }: Props) => {
  const { t } = useTranslation();

  const { register } = useFormContext<{
    signers: SignerInput[];
  }>();

  return (
    <FormControl isInvalid={containsError} borderRadius={"10px"}>
      <Flex minH={"40px"} alignItems="center" columnGap="2">
        <Box flex={1}>
          <Input
            key={id}
            {...register(`signers.${index}.email`, {
              required: true,
              validate: isEmail,
            })}
            placeholder="Email"
            type={"email"}
            size="sm"
            rounded="md"
          />
        </Box>
        <Box flex={1}>
          <Input
            key={id}
            {...register(`signers.${index}.firstName`, {
              required: true,
              minLength: 1,
              maxLength: 200,
            })}
            placeholder={t("First name") ?? ""}
            size="sm"
            rounded="md"
          />
        </Box>
        <Box flex={1}>
          <Input
            key={id}
            {...register(`signers.${index}.lastName`, {
              required: true,
              minLength: 1,
              maxLength: 200,
            })}
            placeholder={t("Last name") ?? ""}
            size="sm"
            rounded="md"
          />
        </Box>
        {showRemoveButton && (
          <Box>
            <Button variant={"solid"} colorScheme="red" size="sm" onClick={remove}>
              Remove
            </Button>
          </Box>
        )}
      </Flex>

      <FormErrorMessage>{containsError && t("Please provide a valid email,first name and last name")}</FormErrorMessage>
    </FormControl>
  );
};
