import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from '@chakra-ui/react'
import {
    GroupBase,
    OptionsOrGroups,
    Select,
    useChakraSelectProps,
} from 'chakra-react-select'
import React from 'react'
import styles from './PersonSelector.module.css'

export interface PersonSelectValue {
    value: string
    label: string
}
interface Props {
    value: PersonSelectValue | null
    onChange: (value: PersonSelectValue | null) => void
    label?: string
    placeholder: string | null
    helperText: string
    errorMessage: string | null
    options: OptionsOrGroups<PersonSelectValue, GroupBase<PersonSelectValue>>
    isDisabled?: boolean
}

export const PersonSelector: React.FC<Props> = ({
    value,
    onChange,
    options,
    label,
    placeholder,
    helperText,
    errorMessage,
    isDisabled,
}) => {
    console.log(isDisabled)

    return (
        <FormControl id="persons" isInvalid={errorMessage != null}>
            {label && <FormLabel>{label}</FormLabel>}
            <Select
                size="md"
                {...useChakraSelectProps({
                    isDisabled: isDisabled,
                    isMulti: false,
                    value: value,
                    onChange: (e) => {
                        if (e) {
                            onChange({
                                value: e.value,
                                label: e.label,
                            })
                        } else {
                            onChange(null)
                        }
                    },
                    isClearable: true,
                    selectedOptionStyle: 'check',
                    selectedOptionColor: 'blue',
                    placeholder: placeholder,
                })}
                options={options}
                className={styles.multiSelect}
            />

            <FormHelperText>{helperText}</FormHelperText>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
