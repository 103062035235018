import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Spinner, Text } from "@chakra-ui/react";
import { isNumber } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const NoticePeriod = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditNoticePeriod") ? t("Update failed") : null;

  const [noticePeriod, setNoticePeriod] = useState<number>();

  const dispatch = useAppDispatch();

  const eventHandler = async (value: number) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditNoticePeriod",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (contractState.contract.dataFields?.noticePeriod) {
      setNoticePeriod(contractState.contract.dataFields.noticePeriod);
    }
  }, [contractState.contract.dataFields?.noticePeriod]);

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Notice period (months)")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%" justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          <Input
            bg="white"
            type="number"
            value={noticePeriod ?? ""}
            onChange={(e) => {
              setNoticePeriod(e.target.value ? +e.target.value : undefined);
              eventHandler(+e.target.value);
            }}
          />
          {contractState.contract.dataFields?.endDate?.date && isNumber(noticePeriod) && (
            <Text whiteSpace={"nowrap"} pl="4" color="gray.500">
              {moment(contractState.contract.dataFields?.endDate.date)
                .add(-(noticePeriod ?? 0), "M")
                .format("DD MMM YYYY")}
            </Text>
          )}
        </Flex>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide the duration of the notice period in months for this contract")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
