import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useOrganizationSearch } from "../../../pages/organizations/search/useOrganizationSearch";
import { useLoggedInWithOrgContextState } from "../../auth/useLoggedInWithOrgContextState";
import { OrganizationSelector } from "./OrganizationSelector";

interface Props<T extends FieldValues> {
  label: string;
  helperText: string;
  control: Control<T>;
  name: FieldPath<T>;
  excludeCurrentOrg: boolean;
}

export const OrganizationFormSelector = <T extends FieldValues>({
  control,
  helperText,
  name,
  label,
  excludeCurrentOrg,
}: Props<T>) => {
  const organizationSearch = useOrganizationSearch();
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();

  const organizations = organizationSearch.organizationCombinations;

  if (!organizations) return <div>{t("Loading...")}</div>;

  const currentOrg = organizations.find((o) => o.type === "Organization" && o.content.id == authState.selectedOrg.id);

  if (!currentOrg) throw new Error("Current organization not found.");

  const filteredOrganizations = organizations.filter(
    (e) => e.type === "Organization" && e.content.id != authState.selectedOrg.id
  );

  if (!excludeCurrentOrg) {
    filteredOrganizations.unshift(currentOrg);
  }

  return (
    <FormControl isRequired>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: true,
            message: t("Please select an organization"),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <OrganizationSelector
            options={filteredOrganizations}
            placeholderText={t("Select an organization")}
            value={value}
            onChange={(value) => {
              if (value.type !== "Organization") {
                throw new Error("Should never happen");
              }
              onChange(value);
            }}
          />
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
