import { Flex, Icon, Input, Spacer, Text } from "@chakra-ui/react";
import { useLayoutEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { OrganizationCombination } from "../../../pages/organizations/search/useOrganizationSearch";
import { OrganizationType, OrganizationTypeTag } from "../../organization/OrganizationTypeTag";

interface Props {
  options: OrganizationCombination[];
  value: OrganizationCombination | null;
  onChange: (value: OrganizationCombination) => void;
  placeholderText: string;
}

export const OrganizationSelector = ({ options, value, onChange, placeholderText }: Props) => {
  const [showOptions, setShowOptions] = useState(false);

  const [searchText, setSearchText] = useState<string>("");
  const [optionsWidth, setOptionsWidth] = useState<number>(0);
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      setOptionsWidth(ref.current.offsetWidth);
    }
  });

  const getSelectedValueText = () => {
    if (value) {
      return `${value.content.name} (${organizationTypeToString(value.type)})`;
    }
    return "";
  };

  const organizationTypeToString = (type: OrganizationType): string => {
    switch (type) {
      case "Organization":
        return t("Registered organization");
      case "OrganizationEntry":
        return t("Unregistered organization");
    }
  };

  if (!options) return <div>{t("Loading...")}</div>;

  const filteredOptions = options.filter((e) => e.content.name.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <>
      <Flex flexDirection={"column"}>
        <Input
          ref={ref}
          placeholder={placeholderText}
          size="md"
          value={!isFocused ? getSelectedValueText() : searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => {
            setIsFocused(true);
            setShowOptions(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            setShowOptions(false);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter" && filteredOptions.length === 1) {
              onChange(filteredOptions[0]);
              setIsFocused(false);
              setShowOptions(false);
            }
          }}
        />
        {showOptions && (
          <Flex
            mt="45px"
            zIndex={1}
            width={`${optionsWidth}px`}
            maxHeight="72"
            overflowY="auto"
            backgroundColor="smBackground"
            position={"absolute"}
            flexDirection={"column"}
            rounded="md"
            border="1px solid"
            borderColor="smBorder"
          >
            {filteredOptions.map((option) => {
              const isChecked = option.content.id === value?.content.id;
              return (
                <Flex
                  key={option.content.id}
                  padding={"10px"}
                  width="full"
                  borderTop="1px solid"
                  borderColor="smBorder"
                  _first={{ rounded: "md", borderTop: "none" }}
                  _hover={{ cursor: "pointer", backgroundColor: "smBackgroundSecondary" }}
                  onMouseDown={() => {
                    onChange(option);
                    setShowOptions(false);
                  }}
                >
                  <Flex>
                    <Text fontSize="md" fontWeight={isChecked ? "bold" : "normal"}>
                      {option.content.name}
                    </Text>
                  </Flex>
                  <Flex justifyContent="center" alignItems="center" ml="7px">
                    {isChecked && <Icon as={FaCheck} w="15px" h="15px" mt="-3px" />}
                  </Flex>
                  <Spacer />
                  <Flex>
                    <OrganizationTypeTag type={option.type} />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Flex>
    </>
  );
};
