import { Tag } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Explanation } from '../support/Explanation'

export type OrganizationType = 'Organization' | 'OrganizationEntry'

interface Props {
    type: OrganizationType
    isCurrentlyLoggedIn?: boolean
    size?: 'sm' | 'md' | 'lg'
}

export const OrganizationTypeTag: React.FC<Props> = ({
    type,
    size,
    isCurrentlyLoggedIn,
}) => {
    const { t } = useTranslation()
    const getEventTagMeta = (
        type: OrganizationType
    ): {
        color: string
        text: string
    } => {
        switch (type) {
            case 'Organization':
                return {
                    color: 'blue',
                    text: isCurrentlyLoggedIn ? t('You') : t('Registered'),
                }
            case 'OrganizationEntry':
                return {
                    color: 'gray',
                    text: t('Created by your org'),
                }
        }
    }

    const tagMeta = getEventTagMeta(type)

    let explanationText: string | null = null
    switch (type) {
        case 'Organization':
            if (isCurrentlyLoggedIn) {
                explanationText = t(
                    'You are currently logged in as this company'
                )
            } else {
                explanationText = t(
                    'This company was registered by its representatives, and is managed by them'
                )
            }

            break
        case 'OrganizationEntry':
            explanationText = t(
                'This company entry was created by your org, and is only available for internal use'
            )
            break
    }

    return (
        <Explanation enabled={explanationText !== null} text={explanationText}>
            <Tag
                colorScheme={tagMeta.color}
                size={size}
                textAlign="center"
                variant={isCurrentlyLoggedIn ? 'solid' : 'outline'}
                paddingTop={'2px'}
                paddingBottom={'2px'}
            >
                {tagMeta.text}
            </Tag>
        </Explanation>
    )
}
