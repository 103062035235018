import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ContractTemplateDto, useCreateContractMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { SelectorValue } from "../../../common/input/Selector/SelectorValue";
import { SingleFormSelector } from "../../../common/input/Selector/SingleFormSelector";
import { urls } from "../../../urls";

interface FormValues {
  title: string;
  template: SelectorValue | null;
}

interface Props {
  templates: ContractTemplateDto[];
}

export const CreateContractForm = ({ templates }: Props) => {
  const authState = useLoggedInWithOrgContextState();

  const navigate = useNavigate();
  const toast = useToast();

  const [create, { isLoading }] = useCreateContractMutation();
  const apiErrorDisplayer = useApiError();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>();

  const submit = async (values: FormValues) => {
    const result = await create({
      depId: authState.selectedOrg.departments[0].id,
      createContractRequest: {
        title: values.title,
        timezone: moment.tz.guess(),
        templateId: values.template?.value ?? undefined,
      },
    });

    if ("data" in result) {
      setValue("title", "");
      toast({
        title: t("New draft created!"),
        status: "success",
        position: "top-right",
      });
      if (urls.contracts.edit.isCurrentPage()) {
        window.location.replace(urls.contracts.edit.go(result.data.id).details.fullPathName("details"));
      } else {
        navigate(urls.contracts.edit.go(result.data.id).details, {
          replace: true,
        });
      }
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  const getTemplateName = (template: ContractTemplateDto): string => {
    if (template.name) {
      return template.name;
    } else if (template.commonType) {
      switch (template.commonType) {
        case "ExpirationDate":
          return t("Contract with expiration date");
      }
    }

    throw new Error("Should never happen");
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormControl isInvalid={errors.title !== undefined} mt="10px">
        <FormLabel htmlFor={"title"}>{t("Contract title")}</FormLabel>
        <Input
          id={"title"}
          type="title"
          {...register("title", {
            required: t("Please provide a title for your contract") ?? "",
            minLength: {
              value: 2,
              message: t("Must be at least 2 characters long"),
            },
          })}
        />
        <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
        <FormHelperText>{t("Please provide a title for your contract")}</FormHelperText>
      </FormControl>
      <Box mt="30px">
        <SingleFormSelector
          options={templates.map((t) => ({
            label: getTemplateName(t),
            value: t.id,
          }))}
          name={"template"}
          label={t("Select type (optional)")}
          helperText={t("Select a type")}
          control={control}
          placeholderText={""}
          noOptionsProvidedMessage={t("No types provided")}
          noMatchingOptionsMessage={t("No matching types")}
          errorMessage={undefined}
        />
      </Box>
      <Flex mt="10" justifyContent={"end"}>
        <Button type="submit" colorScheme="teal" isLoading={isLoading}>
          {t("Create contract")}
        </Button>
      </Flex>
    </form>
  );
};
