import { Icon, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { Explanation } from "../../../../../common/support/Explanation";

interface Props {
  removeDataField?: () => Promise<void> | void;
}

export const RemoveDataFieldButton = ({ removeDataField }: Props) => {
  const { t } = useTranslation();
  const [isRemoving, setisRemoving] = useState(false);

  const isDisabled = removeDataField === undefined;

  return (
    <Explanation enabled={isDisabled} text={t("Field is required and cannot be removed")}>
      <IconButton
        ml="5px"
        aria-label="Remove"
        colorScheme={"blue"}
        variant={"ghost"}
        isLoading={isRemoving}
        isDisabled={isDisabled}
        onClick={async () => {
          if (removeDataField) {
            setisRemoving(true);
            await removeDataField();
            setisRemoving(false);
          } else {
            console.error("removeDataField is undefined");
          }
        }}
        icon={<Icon as={FaTimes} />}
      />
    </Explanation>
  );
};
