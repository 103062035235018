import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { assets } from "../../../../assets/assets";

export const Location: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex
      border="1px solid #e1e6ee"
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      padding="25px"
      bg="white"
      w="100%"
      h="auto"
    >
      <Flex>
        <Text fontSize={"md"} fontWeight="bold" color="#a4a7a6">
          {t("Location")}
        </Text>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex mt="5px">
          <Link to="https://vg.no" target={"_blank"}>
            <Text color="blue.500">Otto Sverdrups Plass 4, 1337 Sandvika</Text>
          </Link>
        </Flex>

        <Flex mt="5px">
          {" "}
          <Text fontWeight={"bold"}>{t("Distance")}:</Text>
          <Text ml="4px">15km</Text>
        </Flex>
      </Flex>
      <Flex mt="10px">
        <Image src={assets.map} alt="Logo" w="100%" h="auto" />
      </Flex>
    </Flex>
  );
};
