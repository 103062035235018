import { Box, Flex, Icon, IconButton, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCompressAlt, FaExpandAlt, FaPlus, FaTrash } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { useDeleteProductGroupMutation } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { addProduct, removeProductGroup } from "../../../../../common/redux/reducers/productReducer";
import { editProductGroupThunk } from "../../../../../common/redux/thunks/product/editProductGroupThunk";
import { Product, ProductField, ProductGroup } from "../../../../../common/types";
import { ProductRowForm } from "./ProductRowForm";

const initializeProduct = (productFields: ProductField[]): Product => ({
  id: uuid(),
  quantity: {
    id: uuid(),
    name: "Quantity",
    populatedBy: "Supplier",
    isRequired: false,
  },
  productFields: productFields.map((field) => ({ ...field, value: field.populatedBy === "Buyer" ? "" : undefined })),
  conversation: [],
});

export const ProductGroupForm = ({ group }: { group: ProductGroup }) => {
  const dispatch = useAppDispatch();
  const [deleteProductGroupMutation, { isLoading }] = useDeleteProductGroupMutation();

  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <Box
      width={"full"}
      left="0"
      backgroundColor="smBackground"
      border="teal 1px solid"
      rounded={"lg"}
      position={showFullScreen ? "absolute" : "relative"}
      zIndex={showFullScreen ? "docked" : "auto"}
      my="8"
      p="5"
    >
      <Flex justifyContent={"space-between"}>
        <Flex alignItems={"center"} pb="4" pl="4">
          <Text size="lg" fontWeight={"bold"}>
            {group.name}
          </Text>
          <IconButton
            aria-label="Toggle product group size"
            icon={<Icon as={showFullScreen ? FaCompressAlt : FaExpandAlt} />}
            size="sm"
            variant={"ghost"}
            onClick={() => setShowFullScreen(!showFullScreen)}
          />
        </Flex>
        <IconButton
          aria-label="Delete product group"
          icon={<Icon as={FaTrash} />}
          variant={"ghost"}
          color="gray.400"
          isLoading={isLoading}
          onClick={() => {
            dispatch(removeProductGroup({ groupId: group.id }));
            deleteProductGroupMutation({ productGroupId: group.id });
          }}
        />
      </Flex>
      <Table variant={"simple"} size="sm">
        <Thead>
          <Tr>
            {group.productFields
              .filter((field) => field.populatedBy === "Buyer")
              .map((field) => (
                <Th key={field.name}>{field.name}</Th>
              ))}
            <Th>{t("Quantity")}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {group.products?.map((product) => (
            <ProductRowForm key={product.id} groupId={group.id} product={product} />
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent={"end"} pr="2">
        <IconButton
          aria-label="Add Product"
          variant={"outline"}
          colorScheme={"teal"}
          size={"xs"}
          float={"right"}
          icon={<Icon as={FaPlus} />}
          onClick={() => {
            const product = initializeProduct(group.productFields);
            dispatch(addProduct({ groupId: group.id, product }));
            dispatch(
              editProductGroupThunk({
                id: group.id,
                addProduct: {
                  product: {
                    id: product.id,
                    quantity: product.quantity,
                    productFields: product.productFields.map((field) => ({
                      id: field.id,
                      name: field.name,
                      value: field.value ? field.value : undefined,
                      populatedBy: field.populatedBy,
                      isRequired: field.isRequired,
                      type: field.type,
                    })),
                  },
                },
              })
            );
          }}
          ml="5"
          mt="4"
        />
      </Flex>
    </Box>
  );
};
