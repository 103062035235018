import { Flex, Grid, GridItem, Heading, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FaBell } from "react-icons/fa";
import { DefaultNotificationSettingsDto } from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../config";
import { BidDeliveryDeadlineSetting } from "./BidDeliveryDeadlineSetting";
import { BidDeliverySettingForm } from "./BidDeliverySettingForm";
import { ContractDateReminderSetting } from "./ContractDateReminderSetting";
import { ContractNotificationSettingForm } from "./ContractDateReminderSettingForm";
import { ContractTaskDueDateReminderSetting } from "./ContractTaskDueDateReminderSetting";
import { ContractTaskNotificationSettingForm } from "./ContractTaskNotificationSettingForm";
import { RecurringContractReminderSetting } from "./RecurringContractReminderSetting";
import { RecurringContractReminderSettingForm } from "./RecurringContractReminderSettingForm";

export const initSettings = (): DefaultNotificationSettingsDto => ({
  contractNotificationSettings: {
    startDateNotifications: [],
    endDateNotifications: [],
    renewalDateNotifications: [],
    warrantyExpirationNotifications: [],
    terminationDateNotifications: [],
    noticePeriodNotifications: [],
    optionExecutionDeadlineNotifications: [],
    optionStartDateNotifications: [],
    noEndDateNotifications: [],
  },
  contractTaskNotificationSettings: {
    dueDateNotifications: [],
  },
  sourcingEventNotificationSettings: {
    bidDeliveryDeadlineNotifications: [],
  },
});

export const Settings = () => {
  const authState = useLoggedInWithOrgContextState();

  return (
    <Layout>
      <Grid
        h="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem height="full" area={"content"} display={"flex"} justifyContent={"center"} px="2">
          <Flex flexDirection={"column"} width={"container.md"} maxWidth={"container.md"} pb="5">
            <Flex alignItems={"center"} py="4">
              <Icon as={FaBell} boxSize={"6"} mr="2" />
              <Heading as="h2" size="lg">
                {t("Notification Settings")}
              </Heading>
            </Flex>
            <Text fontWeight={"bold"} fontSize={"lg"} pb="2">
              {t("Contracts")}
            </Text>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Start date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.startDateNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="StartDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="StartDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("End date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.endDateNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="EndDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="EndDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Warranty expiration date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.warrantyExpirationNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="WarrantyExpirationDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="WarrantyExpirationDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Renewal date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.renewalDateNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="RenewalDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="RenewalDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Termination date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.terminationDateNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="TerminationDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="TerminationDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Notice period start date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.noticePeriodNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="NoticePeriodReminder" />
                )
              )}
              <ContractNotificationSettingForm type="NoticePeriodReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Option execution deadline")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionExecutionDeadlineNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="OptionExecutionDeadlineReminder" />
                )
              )}
              <ContractNotificationSettingForm type="OptionExecutionDeadlineReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Option start date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.optionStartDateNotifications.map(
                (setting, i) => (
                  <ContractDateReminderSetting key={i} setting={setting} type="OptionStartDateReminder" />
                )
              )}
              <ContractNotificationSettingForm type="OptionStartDateReminder" />
            </Flex>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Contracts with no end date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractNotificationSettings.noEndDateNotifications.map(
                (setting, i) => (
                  <RecurringContractReminderSetting key={i} setting={setting} />
                )
              )}
              <RecurringContractReminderSettingForm />
            </Flex>
            <Text fontWeight={"bold"} fontSize={"lg"} pb="2">
              {t("Contract Tasks")}
            </Text>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Due date")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.contractTaskNotificationSettings.dueDateNotifications.map(
                (setting, i) => (
                  <ContractTaskDueDateReminderSetting key={i} setting={setting} />
                )
              )}
              <ContractTaskNotificationSettingForm />
            </Flex>
            <Text fontWeight={"bold"} fontSize={"lg"} pb="2">
              {t("Sourcing Events")}
            </Text>
            <Flex flexDirection={"column"} pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"} pb="1">
                {t("Bid delivery deadline")}
              </Text>
              {authState.selectedOrg.defaultNotificationSettings?.sourcingEventNotificationSettings.bidDeliveryDeadlineNotifications.map(
                (setting, i) => (
                  <BidDeliveryDeadlineSetting key={i} setting={setting} />
                )
              )}
              <BidDeliverySettingForm />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Layout>
  );
};
