import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Spinner,
} from '@chakra-ui/react'
import { debounce, parseInt } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { useContractState } from '../../../useContractState'
import { RemoveDataFieldButton } from '../RemoveDataFieldButton'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const PaymentTermsInDays: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const contractState = useContractState()
    const [isLoading, setIsLoading] = useState(false)
    const errorMessage = containsError(contractState, 'EditPaymentTermsInDays')
        ? t('Update failed')
        : null
    const dispatch = useAppDispatch()
    const [days, setDays] = useState<number>()

    useEffect(() => {
        if (contractState.contract.dataFields?.paymentTermsInDays) {
            setDays(contractState.contract.dataFields.paymentTermsInDays)
        }
    }, [])

    const eventHandler = async (value: number | null) => {
        setIsLoading(true)
        await dispatch(
            editContractThunk({
                command: {
                    type: 'EditPaymentTermsInDays',
                    value: value,
                },
            })
        )
        setIsLoading(false)
    }
    const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), [])

    useEffect(() => {
        return () => {
            debouncedEventHandler.cancel()
        }
    }, [])

    return (
        <FormControl
            isInvalid={errorMessage !== null}
            isRequired={isRequiredButNotProvided}
        >
            <FormLabel htmlFor={'value'}>
                {t('Payment terms (days)')} {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Flex w="100%">
                <Box borderRadius={'10px'} bg="white" w="100%">
                    <Input
                        type="number"
                        value={days ?? ''}
                        onChange={(e) => {
                            if (e.target.value.trim() !== '') {
                                setDays(parseInt(e.target.value))
                                debouncedEventHandler(parseInt(e.target.value))
                            } else {
                                setDays(undefined)
                                debouncedEventHandler(null)
                            }
                        }}
                    />
                </Box>
                <RemoveDataFieldButton removeDataField={removeDataField} />
            </Flex>
            <FormHelperText>
                {t('Provide payment terms (in days)')}
            </FormHelperText>
            <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
