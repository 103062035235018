import { Box, Flex, Icon, Input, Progress, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle, FaFile } from "react-icons/fa";
import { DownloadDocumentIconButton } from "../DownloadDocumentIconButton/DownloadDocumentIconButton";
import { RemoveFileButton } from "./RemoveFileButton";

interface Props {
  file: FileUpload;
  name: string;
  remove: (file: FileUpload) => Promise<void>;
  onUpdateName?: (name: string) => Promise<void>;
}

export type FileUpload =
  | {
      status: "UploadingToBrowser";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadingToServer";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadFailed";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadCompleted";
      name: string;
      documentId: string;
      uploadId: string;
    };

export const FileRow = ({ file, name, remove, onUpdateName }: Props) => {
  const { t } = useTranslation();
  const getProgressBar = () => {
    let text: string;
    let value: number;
    let color = "blue";
    switch (file.status) {
      case "UploadCompleted":
        return null;
      case "UploadFailed":
        text = t("Upload failed!");
        value = 0;
        color = "red";
        break;
      case "UploadingToBrowser":
        text = t("Uploading") + "...";
        value = 34;
        break;
      case "UploadingToServer":
        text = t("Scanning files") + "...";
        value = 64;
        break;
    }
    return (
      <Box mt="5px" mb="5px">
        <Progress value={value} colorScheme={color} hasStripe />
        <Text fontWeight={"bold"} fontSize="sm">
          {text}
        </Text>
      </Box>
    );
  };

  return (
    <Flex
      key={file.uploadId}
      backgroundColor={"smBackgroundSecondary"}
      minHeight={"50px"}
      alignItems="center"
      borderRadius="md"
      py="1"
      mt="1"
    >
      <Icon as={FaFile} color="blue.500" mx="5" />
      <Flex flexDirection="column" width="full" pr="4">
        <Input
          size="sm"
          rounded="md"
          fontWeight={"normal"}
          value={name}
          _focus={{
            borderColor: onUpdateName ? "default" : "inherit",
            boxShadow: onUpdateName ? "default" : "inherit",
          }}
          isDisabled={file.status !== "UploadCompleted"}
          onChange={(e) => onUpdateName?.(e.target.value)}
        />
        {getProgressBar()}
      </Flex>
      <Spacer />
      <Flex>
        {file.status === "UploadFailed" && (
          <Flex>
            <Icon as={FaExclamationCircle} color={"red.500"} />
          </Flex>
        )}
        {file.status === "UploadCompleted" && <DownloadDocumentIconButton documentId={file.documentId} />}
      </Flex>
      <RemoveFileButton
        confirmRemoval={async () => {
          await remove(file);
        }}
      />
    </Flex>
  );
};
