import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { OrgPublicProfileDto, OrganizationEntryDto, bffApi } from "../../../autogen/bff-api";
import { useApiError } from "../../../common/errors/useApiError";
import { useAppDispatch } from "../../../common/redux/hooks";

export const useOrganizationSearch = (props?: { excludedOrgId: string }) => {
  const dispatch = useAppDispatch();
  const displayer = useApiError();
  const [searchString, setSearchString] = useState("");
  const [organizations, setOrganizations] = useState<OrgPublicProfileDto[] | null>(null);
  const [organizationEntries, setOrganizationEntries] = useState<OrganizationEntryDto[] | null>(null);
  const [organizationCombinations, setOrganizationCombiations] = useState<OrganizationCombination[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const load = async (input: { searchString: string }) => {
    const query = dispatch(
      bffApi.endpoints.searchOrganizationsAndEntries.initiate({
        name: input.searchString,
      })
    );
    query.unsubscribe();
    const res = await query;

    if ("data" in res && res.data) {
      setIsSearching(false);
      setOrganizations(
        res.data.organizations.filter((e) => {
          if (props?.excludedOrgId) {
            return e.id !== props.excludedOrgId;
          }
          return true;
        })
      );
      setOrganizationEntries(res.data.organizationEntries);

      const organizationCombos: OrganizationCombination[] = res.data.organizations
        .filter((e) => {
          if (props?.excludedOrgId) {
            return e.id !== props.excludedOrgId;
          }
          return true;
        })
        .map((e) => ({
          type: "Organization",
          content: e,
        }));

      const organizationEntryCombos: OrganizationCombination[] = res.data.organizationEntries.map((e) => ({
        type: "OrganizationEntry",
        content: e,
      }));

      const combos = [...organizationCombos, ...organizationEntryCombos];

      setOrganizationCombiations(combos);
    } else {
      displayer.trigger(res.error);
      throw Error("Could not load Organizations");
    }
  };

  const eventHandler = (input: { searchString: string }) => {
    load(input);
  };

  const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), []);

  useEffect(() => {
    setIsSearching(true);
    debouncedEventHandler({
      searchString: searchString,
    });
  }, [debouncedEventHandler, searchString]);

  return {
    organizations: organizations,
    organizationEntries: organizationEntries,
    organizationCombinations:
      organizationCombinations?.sort((a, b) => (a.content.name < b.content.name ? -1 : 1)) ?? null,
    textSearch: (input: string) => setSearchString(input),
    reload: async (): Promise<void> => {
      await load({
        searchString: searchString,
      });
    },
    isSearching: isSearching,
  };
};

export type OrganizationCombination =
  | {
      type: "Organization";
      content: OrgPublicProfileDto;
    }
  | {
      type: "OrganizationEntry";
      content: OrganizationEntryDto;
    };
