import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Spinner,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useListEventsCreatedByDepartmentQuery } from '../../../../../../autogen/bff-api'
import { useLoggedInWithOrgContextState } from '../../../../../../common/auth/useLoggedInWithOrgContextState'
import { SingleSelector } from '../../../../../../common/input/Selector/SingleSelector'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { useContractState } from '../../../useContractState'
import { RemoveDataFieldButton } from '../RemoveDataFieldButton'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const OriginSourcingEvent: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const contractState = useContractState()
    const [isLoading, setIsLoading] = useState(false)
    const authState = useLoggedInWithOrgContextState()
    const errorMessage = containsError(contractState, 'EditOriginSourcingEvent')
        ? t('Update failed')
        : null
    const dispatch = useAppDispatch()
    const { data } = useListEventsCreatedByDepartmentQuery({
        depId: authState.selectedOrg.departments[0].id,
        status: 'Completed',
    })

    if (!data) {
        return <div>{t('Loading...')}</div>
    }

    const eventHandler = async (eventId: string | null) => {
        setIsLoading(true)
        await dispatch(
            editContractThunk({
                command: {
                    type: 'EditOriginSourcingEvent',
                    value: eventId,
                },
            })
        )
        setIsLoading(false)
    }

    let originSourcingEvent:
        | {
              value: string
              label: string
          }
        | undefined = undefined
    if (contractState.contract.dataFields?.originSourcingEvent) {
        originSourcingEvent = {
            value: contractState.contract.dataFields?.originSourcingEvent.id,
            label: contractState.contract.dataFields?.originSourcingEvent.title,
        }
    }

    return (
        <FormControl
            isInvalid={errorMessage !== null}
            isRequired={isRequiredButNotProvided}
        >
            <FormLabel htmlFor={'value'}>
                {t('Origin sourcing event')}{' '}
                {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Box borderRadius={'10px'}>
                <Flex>
                    <SingleSelector
                        value={originSourcingEvent ?? null}
                        options={data.events.map((e) => ({
                            value: e.id,
                            label: e.title,
                        }))}
                        onChange={(e) => {
                            eventHandler(e?.value ?? null)
                        }}
                        noMatchingOptionsMessage={
                            t('No matching sourcing events found') ?? ''
                        }
                        noOptionsProvidedMessage={
                            t('No awarded events available') ?? ''
                        }
                        isClearable={true}
                    />
                    <RemoveDataFieldButton removeDataField={removeDataField} />
                </Flex>
            </Box>
            <FormHelperText>
                {t('Provide the sourcing event this contract originates from')}
            </FormHelperText>
            <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
