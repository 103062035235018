import { Button, Flex, FormControl, FormHelperText, FormLabel, Icon } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import {
  TextDocumentDto,
  useCreateSourcingEventTextDocumentMutation,
  useDeleteTextDocumentMutation,
  useListTextDocumentsForSourcingEventQuery,
  useUpdateTextDocumentMutation,
} from "../../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../../common/documents/TextDocumentModal";
import { useApiError } from "../../../../../common/errors/useApiError";
import { TextDocumentTable } from "../../../../../common/input/TipTap/TextDocumentTable";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { BasicSourcingEventDocumentUploader } from "./BasicSourcingEventDocumentUploader";

interface Props {
  nextStep: () => void;
  previousStep: () => void;
}

export const Docs = ({ nextStep, previousStep }: Props) => {
  const { t } = useTranslation();

  const eventState = useBasicSourcingEventState();
  const displayer = useApiError();

  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateSourcingEventTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();

  const { data } = useListTextDocumentsForSourcingEventQuery({ eventId: eventState.id });

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  return (
    <>
      <BasicSourcingEventDocumentUploader />
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
        />
      )}
      <FormControl pt={8}>
        <FormLabel>{t("Create document")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingTextDocument}
            onClick={async () => {
              const res = await createTextDocument({
                createSourcingEventTextDocumentRequest: {
                  name: "",
                  text: "",
                  sourcingEventId: eventState.id,
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>{t("Create a new document from scratch or based on templates")}</FormHelperText>
        </Flex>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        {data?.documents.length ? (
          <TextDocumentTable
            documents={data.documents}
            onClick={setTextDocumentToEdit}
            onDelete={async (id: string) => {
              await deleteTextDocument({ id });
            }}
          />
        ) : null}
        {!data?.documents.length && <FormHelperText>{t("No text documents yet")}</FormHelperText>}
      </FormControl>
      <Flex py="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
