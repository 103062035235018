import { Badge, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { SeDto } from "../../../autogen/bff-api";
import { displayWrappedWithEllipsis } from "../../../common/formatting/displayWrappedWithEllipsis";
import { urls } from "../../../urls";
import { EventStatusTag } from "../../sourcing-events/EventStatusTag";
import styles from "./Event.module.css";
interface Props {
  event: SeDto;
}

export const LatestEvent = ({ event }: Props) => {
  const navigate = useNavigate();

  return (
    <Flex
      key={event.id}
      w="100%"
      borderRadius={"6px"}
      mt="15px"
      padding={"10px"}
      backgroundColor="smBackgroundSecondary"
      className={styles.event}
      onClick={() => {
        navigate(urls.events.view.go(event.id));
      }}
    >
      <Grid
        w="100%"
        key={event.id}
        gridTemplateAreas={`
            "title unresolved status"
            "sub unresolved status"
            `}
        gridTemplateColumns={"1fr minmax(100px, max-content) 100px"}
      >
        <GridItem area={"title"}>
          <Flex flex={3} alignItems={"center"} paddingLeft="10px">
            <Text fontSize={"md"} color="smMuted">
              {displayWrappedWithEllipsis(event.title, 30)}
            </Text>
          </Flex>
        </GridItem>
        <GridItem area={"sub"}>
          <Flex flex={3} alignItems={"center"} paddingLeft="10px">
            <Text
              fontSize={"sm"}
              fontWeight={"light"}
              color="smMuted"
            >{`${event.createdBy.firstName} ${event.createdBy.lastName}`}</Text>
          </Flex>
        </GridItem>
        <GridItem area={"unresolved"} display="flex" alignItems="center" pr="10px">
          {event.unansweredQuestionsCount > 0 && (
            <Badge colorScheme="red" ml="6px">
              {event.unansweredQuestionsCount} {t("unresolved!")}
            </Badge>
          )}
        </GridItem>
        <GridItem area={"status"} display="flex" alignItems={"center"} justifyContent="end">
          <EventStatusTag status={event.status} />
        </GridItem>
      </Grid>
    </Flex>
  );
};
