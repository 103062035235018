import { t } from "i18next";
import { isNumber } from "lodash";
import { utils, write } from "xlsx";
import { Product, ProductDraft, ProductGroup } from "../types";

export const getTotalProductPrice = (product: Product | ProductDraft): number | undefined =>
  isNumber(product.unitPrice?.value) && isNumber(product.quantity.value)
    ? (product.unitPrice?.value ?? 0) * product.quantity.value
    : undefined;

export const displayTotalProductPrice = (product: Product | ProductDraft) =>
  getTotalProductPrice(product)?.toLocaleString() ?? "";

export const getProductGroupPrice = (group: ProductGroup) =>
  group.products?.reduce((sum, p) => sum + (getTotalProductPrice(p) ?? 0), 0);

export const displayProductGroupPrice = (group: ProductGroup) => getProductGroupPrice(group)?.toLocaleString();

export const getSumOfProductGroupPrices = (groups: ProductGroup[]): number | undefined =>
  groups.reduce((sum, g) => sum + (getProductGroupPrice(g) ?? 0), 0);

export const displaySumOfProductGroupPrices = (groups: ProductGroup[]) =>
  getSumOfProductGroupPrices(groups)?.toLocaleString();

export const downloadExcel = ({ productGroups, fileName }: { productGroups: ProductGroup[]; fileName: string }) => {
  const workbook = utils.book_new();
  for (const group of productGroups) {
    const headings = group.productFields.filter((f) => f.populatedBy === "Buyer").map((field) => field.name);
    headings.push(t("Quantity"));
    headings.push(t("Unit Price"));
    headings.push(t("Total price"));
    headings.push(...group.productFields.filter((f) => f.populatedBy === "Supplier").map((f) => f.name));
    const table: (string | number | undefined | boolean | null)[][] = [headings];
    if (!group.products) continue;
    for (const product of group.products) {
      if (!product.id) throw Error("No product id");
      const productRow = group.productFields
        .filter((f) => f.populatedBy === "Buyer")
        .map((field) => product.productFields.find((f) => f.id === field.id)?.value);
      productRow.push(product.quantity.value);
      productRow.push(product.unitPrice?.value);
      productRow.push(getTotalProductPrice(product));
      productRow.push(
        ...group.productFields
          .filter((f) => f.populatedBy === "Supplier")
          .map((field) => product.productFields.find((f) => f.id === field.id)?.value)
      );
      table.push(productRow);
    }
    const sheet = utils.aoa_to_sheet(table);
    utils.book_append_sheet(workbook, sheet, group.name.slice(0, 31));
  }
  const arrayBuffer = write(workbook, { bookType: "xlsx", type: "buffer" });
  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`;

  a.click();

  window.URL.revokeObjectURL(url);
};
