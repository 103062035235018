import { Box, Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../common/layout/Layout";
import { useSub } from "../../common/subscription/useSub";
import { CONTENT_WIDTH } from "../../config";
import styles from "./Home.module.css";
import { LatestBidInvitations } from "./latest-bid-invitations/LatestBidInvitations";
import { LatestContracts } from "./latest-contracts/LatestContracts";
import { LatestEvents } from "./latest-events/LatestEvents";
import { PersonalProfile } from "./personal-profile-completion/PersonalProfile";
import { usePersonalProfileCompletion } from "./personal-profile-completion/usePersonalProfileCompletion";
import { QuickLinks } from "./quick-links";
import { ContractStats } from "./stats/ContractStats";
import { UpcomingContractTasks } from "./upcoming-contract-tasks/UpcomingContractTasks";

export const Home = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation(["translation"]);
  const profileCompletion = usePersonalProfileCompletion();
  const sub = useSub();
  const authState = useLoggedInWithOrgContextState();

  return (
    <Layout>
      <Grid
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`
                    "leftContent content rightContent"
                    `}
      >
        <GridItem height="full" area={"content"} px="2">
          <Grid className={styles.grid} height="full">
            {isLargerThan768 && (
              <GridItem display="flex" alignItems="center" area="welcome">
                <Heading as="h2" size="sm" color={"smMuted"}>
                  {t("Welcome to SourceMagnet")}
                  {authState.me.firstName ? `, ${authState.me.firstName}!` : "!"}
                </Heading>
              </GridItem>
            )}
            <GridItem area="quick-links" paddingTop={"15px"} mb="15px">
              <Box backgroundColor="smBackgroundSecondary" h="100%" rounded="lg" padding="5">
                {sub.hasContractAccess && (
                  <>
                    <UpcomingContractTasks />
                    <Box h="10px" />
                  </>
                )}
                {!sub.hasContractAccess && <QuickLinks />}
                <ContractStats />
              </Box>
            </GridItem>
            <GridItem area="info" h="100%">
              {sub.hasBuyerAccess && <LatestEvents />}
              {!sub.hasBuyerAccess && <LatestBidInvitations />}
              {sub.hasContractAccess && <LatestContracts />}
              {profileCompletion.completionPercentage !== 100 && <PersonalProfile />}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
