import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRumError } from "../RumComponentContext/useRumError";

interface Props {
  title?: string;
  message?: string;
}

export const CriticalError = ({ title, message }: Props) => {
  const rumError = useRumError();
  const { t } = useTranslation();

  useEffect(() => {
    rumError(title, undefined);
  }, [rumError, title]);

  return (
    <Box padding="10px">
      <Alert status="error" borderRadius={"10px"}>
        <AlertIcon />
        <AlertTitle>{title ?? t("Wups!")}</AlertTitle>
        <AlertDescription>{message ?? `${t("Something went wrong and our team has been notified")}!`}</AlertDescription>
      </Alert>
    </Box>
  );
};
