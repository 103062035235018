import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaDownload, FaFileExcel } from "react-icons/fa";
import { DocumentsAsOwnerTable } from "../../../../common/documents/DocumentsAsOwnerTable";
import { useApiError } from "../../../../common/errors/useApiError";
import { useProductGroupsAsSupplier } from "../../../../common/hooks/useProductGroupsAsSupplier";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { ProductTableRow } from "../../../../common/products/ProductTableRow";
import {
  displayProductGroupPrice,
  displaySumOfProductGroupPrices,
  downloadExcel,
} from "../../../../common/products/productUtils";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { loadBasicBidThunk } from "../../../../common/redux/thunks/basic-bid/load-basic-bid-thunk";
import { FetchState } from "../../../../common/types";
import { useSkeletons } from "../../../../common/useSkeletons";
import { isAwardedBseSupplierView } from "../../typeguards";

interface Props {
  bidId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewDeliveredBseBid = ({ bidId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const displayer = useApiError();
  const dispatch = useAppDispatch();
  const skeletons = useSkeletons().stackedLines;

  const { bid, fetchState } = useAppSelector((state) => state.basicBid);

  const { productGroups, isLoading: isLoadingProductGroups, error: productGroupError } = useProductGroupsAsSupplier();

  useEffect(() => {
    if (bidId && bid?.id !== bidId) {
      dispatch(loadBasicBidThunk({ bidId }));
    }
  }, [bidId, bid?.id, dispatch]);

  if (productGroupError) return displayer.show(productGroupError);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent height={"80%"} maxHeight={"80%"} overflowY={"hidden"}>
        <ModalHeader>{t("Bid")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display={"flex"}
          flexDirection={"column"}
          width="full"
          height="full"
          maxHeight={"full"}
          overflow="hidden"
          px="4"
          pb="5"
        >
          <Flex
            flexDir={"column"}
            flexGrow={1}
            overflowY={"auto"}
            overflowX={"hidden"}
            minHeight={0}
            shadow="inner"
            rounded="md"
            p="2"
          >
            {fetchState === FetchState.Loading && skeletons(0, 5)}
            {fetchState === FetchState.Success && bid && (
              <>
                {isAwardedBseSupplierView(bid.forEvent) && bid.forEvent.awardedFieldsDto.iamWinningDepartment && (
                  <>
                    <Alert status="success" bgColor={"gray.50"} rounded="md" p="10" mb="4">
                      <AlertIcon />
                      <Text>{t("You won this event!")}</Text>
                    </Alert>
                    {bid.forEvent.awardedFieldsDto.message && (
                      <Box paddingLeft={"50px"} pb="4" bg="gray.50">
                        <Text fontWeight={"bold"}>{t("Message from the buyer")}:</Text>
                        <Text>{bid.forEvent.awardedFieldsDto.message}</Text>
                      </Box>
                    )}
                  </>
                )}
                {bid.forEvent.awardedFieldsDto && !bid.forEvent.awardedFieldsDto.iamWinningDepartment && (
                  <>
                    <Alert status="info" rounded="md" bgColor={"gray.50"} p="10" mb="4">
                      <AlertIcon />
                      <Text>
                        {t("You did")} <b>{t("not")}</b> {t("win this event!")}
                      </Text>
                    </Alert>
                    <br />
                    {bid.forEvent.awardedFieldsDto.message && (
                      <Box
                        paddingLeft={"50px"}
                        paddingTop="10px"
                        paddingBottom="15px"
                        borderRadius={"10px"}
                        bg="gray.50"
                      >
                        <>
                          <Text fontWeight={"bold"}>{t("Message from the buyer")}:</Text>
                          <Text>{bid.forEvent.awardedFieldsDto.message}</Text>
                        </>
                      </Box>
                    )}
                  </>
                )}
                <Text as="h2" fontWeight={"semibold"}>
                  {t("Comment")}
                </Text>
                <TipTap content={bid.comment.value ?? ""} editable={false} maxHeight="60" />
                <Text as="h2" fontWeight={"semibold"} py="4">
                  {t("Documents")}
                </Text>
                <Box pb="4">
                  <DocumentsAsOwnerTable documents={bid.documents.value} />
                </Box>
                {productGroups?.length ? (
                  <Flex alignItems={"center"} justifyContent={"space-between"} pb="2">
                    <Text as="h2" fontWeight={"semibold"}>
                      {t("Products")}
                    </Text>
                    <Button
                      size={"sm"}
                      colorScheme="blue"
                      variant={"ghost"}
                      leftIcon={<Icon as={FaFileExcel} />}
                      rightIcon={<Icon as={FaDownload} />}
                      onClick={() => downloadExcel({ productGroups, fileName: `bid_${bid.id}` })}
                    >
                      {t("Download")}
                    </Button>
                  </Flex>
                ) : null}
                {isLoadingProductGroups && !productGroups && skeletons(0, 3)}
                {productGroups && (
                  <Flex justifyContent="center" width="full" pb="6">
                    <Flex
                      flexDirection={"column"}
                      shadow={"sm"}
                      width="fit-content"
                      border="2px solid"
                      borderColor="blue.100"
                      rounded={"md"}
                      p="4"
                    >
                      <Text fontSize={"3xl"} fontWeight={"semibold"} textAlign={"center"}>
                        {displaySumOfProductGroupPrices(productGroups)}
                      </Text>
                      <Text>{t("Sum of product prices")}</Text>
                    </Flex>
                  </Flex>
                )}
                {productGroups?.map((group) => (
                  <Box key={group.id} border="1px solid" borderColor="teal.100" rounded="lg" mt="5" pb="2.5">
                    <Text as="h2" fontWeight={"semibold"} p="4">
                      {group.name}
                    </Text>
                    <Box overflowX={"auto"}>
                      <Table variant={"simple"} overflowX={"auto"} overflowY={"visible"} size="sm">
                        <Thead>
                          <Tr>
                            {group.productFields
                              .filter((field) => field.populatedBy === "Buyer")
                              .map((field) => (
                                <Th key={field.id}>{field.name}</Th>
                              ))}
                            <Th>{t("Quantity")}</Th>
                            <Th>{t("Unit Price")}</Th>
                            <Th>{t("Total Price")}</Th>
                            {group.productFields
                              .filter((field) => field.populatedBy === "Supplier")
                              .map((field) => (
                                <Th key={field.id}>{field.name}</Th>
                              ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {group.products?.map((product) => (
                            <ProductTableRow key={product.id} product={product} templateFields={group.productFields} />
                          ))}
                          <Tr fontWeight={"bold"}>
                            <Td>SUM</Td>
                            {group.productFields
                              .filter((field) => field.populatedBy === "Buyer")
                              .map((field) => (
                                <Td key={field.id}></Td>
                              ))}
                            <Td></Td>
                            <Td textAlign={"center"} verticalAlign={"top"}>
                              {displayProductGroupPrice(group)}
                            </Td>
                            {group.productFields
                              .filter((field) => field.populatedBy === "Supplier")
                              .map((field) => (
                                <Td key={field.id}></Td>
                              ))}
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  </Box>
                ))}
                {productGroups?.length === 0 && <Box>{t("No products included in this event")}</Box>}
              </>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
