import { useAppSelector } from "../../../common/redux/hooks";

export const useContractState = () => {
  const state = useAppSelector((state) => state.contract.state);

  if (!state) {
    throw Error("No contract state.");
  }
  return state;
};
