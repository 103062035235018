import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AdminOrgDto, useAdminUpdateOrganizationMutation } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { RadioFormSelector } from "../../../common/input/Radio/RadioFormSelector";

interface Props {
  organization: AdminOrgDto;
  onClose: () => void;
}

interface FormValues {
  name: string;
  hasBuyerAccess: string;
  hasContractAccess: string;
  hasSignatureAccess: string;
  hasProductAccess: string;
  hasProjectAccess: string;
  hasAiAccess: string;
  hasDocumentTemplateAccess: string;
  canSeeDeliveredBidsBeforeDeadline: string;
}

export const AdminEditOrganizationModal = ({ organization, onClose }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();
  const [update, { isLoading }] = useAdminUpdateOrganizationMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      name: organization.name,
      hasBuyerAccess: organization.subscription.hasBuyerAccess ? "true" : "false",
      hasContractAccess: organization.subscription.hasContractAccess ? "true" : "false",
      hasSignatureAccess: organization.subscription.hasSignatureAccess ? "true" : "false",
      hasProductAccess: organization.subscription.hasProductAccess ? "true" : "false",
      hasProjectAccess: organization.subscription.hasProjectAccess ? "true" : "false",
      hasAiAccess: organization.subscription.hasAiAccess ? "true" : "false",
      hasDocumentTemplateAccess: organization.subscription.hasDocumentTemplateAccess ? "true" : "false",
      canSeeDeliveredBidsBeforeDeadline: organization.canSeeDeliveredBidsBeforeDeadline ? "true" : "false",
    },
  });

  const submit = async (values: FormValues) => {
    const result = await update({
      organizationId: authState.selectedOrg.id,
      orgId: organization.id,
      adminUpdateOrganizationRequest: {
        name: values.name,
        hasBuyerAccess: values.hasBuyerAccess === "true",
        hasContractAccess: values.hasContractAccess === "true",
        hasSignatureAccess: values.hasSignatureAccess === "true",
        hasProductAccess: values.hasProductAccess === "true",
        hasProjectAccess: values.hasProjectAccess === "true",
        hasAiAccess: values.hasAiAccess === "true",
        hasDocumentTemplateAccess: values.hasDocumentTemplateAccess === "true",
        canSeeDeliveredBidsBeforeDeadline: values.canSeeDeliveredBidsBeforeDeadline === "true",
      },
    });

    if ("data" in result) {
      toast({
        title: t("Organization updated!"),
        status: "success",
        position: "top-right",
      });
      onClose();
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight={"90%"} overflowY={"hidden"}>
        <ModalHeader>{t("Edit organization")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} overflowY={"auto"}>
          <form onSubmit={handleSubmit(submit)}>
            <FormControl isInvalid={errors.name !== undefined}>
              <FormLabel htmlFor={"name"}>{t("Organization name")}</FormLabel>
              <Input
                id={"name"}
                type="name"
                size={"sm"}
                rounded="md"
                {...register("name", {
                  required: t("Please provide a name for the organization") ?? "",
                  minLength: {
                    value: 2,
                    message: t("Must be at least 2 characters long"),
                  },
                })}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  {
                    value: "true",
                    label: "Yes",
                  },
                  {
                    value: "false",
                    label: "No",
                  },
                ]}
                label={"Has buyer access?"}
                control={control}
                name={"hasBuyerAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  {
                    value: "true",
                    label: "Yes",
                  },
                  {
                    value: "false",
                    label: "No",
                  },
                ]}
                label={"Has contract access?"}
                control={control}
                name={"hasContractAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  {
                    value: "true",
                    label: "Yes",
                  },
                  {
                    value: "false",
                    label: "No",
                  },
                ]}
                label={"Has signature access?"}
                control={control}
                name={"hasSignatureAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                label={"Has product access?"}
                control={control}
                name={"hasProductAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                label={"Has project access?"}
                control={control}
                name={"hasProjectAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                label={"Has AI access?"}
                control={control}
                name={"hasAiAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                label={"Has document template access?"}
                control={control}
                name={"hasDocumentTemplateAccess"}
              />
            </Box>
            <Box pt="2">
              <RadioFormSelector
                options={[
                  { value: "true", label: "Yes" },
                  { value: "false", label: "No" },
                ]}
                label={"Can view delivered bids before sourcing event deadline?"}
                control={control}
                name={"canSeeDeliveredBidsBeforeDeadline"}
              />
            </Box>
            <Flex justifyContent={"end"}>
              <Button type="submit" colorScheme="teal" isLoading={isLoading}>
                {t("Update")}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
