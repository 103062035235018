import { Flex, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import { ContractTaskDto } from '../../../../../../autogen/bff-api'
import { displayDate } from '../../../../../../common/formatting/displayDate'
import { EditButton } from '../EditButton'
import { EditDueDate } from './EditDueDate'

interface Props {
    task: ContractTaskDto
    onEditComleted: (task: ContractTaskDto) => void
}

export const DueDate: React.FC<Props> = ({ task, onEditComleted }) => {
    const [isEditing, setIsEditing] = useState(false)
    return (
        <>
            {!isEditing && (
                <Flex mt="7px">
                    <Text fontSize={'md'} color="red.600" fontWeight={'light'}>
                        {t('Due date')}:&nbsp;
                    </Text>
                    <Text fontSize={'md'} color="red.600" fontWeight={'bold'}>
                        {displayDate(task.dueDate)}
                    </Text>
                    {task.canEdit && (
                        <EditButton
                            name="edit-due-date-button"
                            onClick={() => setIsEditing(true)}
                        />
                    )}
                </Flex>
            )}
            {isEditing && (
                <EditDueDate
                    task={task}
                    onEditComleted={(e) => {
                        setIsEditing(false)
                        onEditComleted(e)
                    }}
                    onCancel={() => setIsEditing(false)}
                />
            )}
        </>
    )
}
