import { Box } from "@chakra-ui/react";
import { Select, useChakraSelectProps } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Selector.module.css";
import { SelectorOptions, SelectorValue } from "./SelectorValue";

interface Props {
  value: SelectorValue[];
  options: SelectorOptions;
  noOptionsAvailableMessage: string;
  onChange: (values: SelectorValue[]) => void;
  placeholder?: {
    text: string;
    color: string;
  };
  searchValues?: boolean;
}

export const MultiSelector = ({
  value,
  options,
  noOptionsAvailableMessage,
  onChange,
  placeholder,
  searchValues = true,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<SelectorValue[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const getNoOptionsMessage = (inputValue: string): React.ReactElement => {
    if (options.length === 0) {
      return <Box>{noOptionsAvailableMessage}</Box>;
    } else {
      return <Box>{inputValue.trim().length === 0 ? t("Loading") + "..." : t("No values found")}</Box>;
    }
  };

  return (
    <Select
      size="md"
      {...useChakraSelectProps({
        isMulti: true,
        value: selectedValues,
        onChange: (e) => {
          const values = e.map((e) => ({
            value: e.value,
            label: e.label,
          }));
          setSelectedValues(values);
          onChange(values);
        },
        isClearable: false,
        selectedOptionStyle: "check",
        selectedOptionColor: "blue",
        placeholder: placeholder ? <Box color={placeholder.color}>{placeholder.text}</Box> : <Box></Box>,
        noOptionsMessage: (inputValue) => getNoOptionsMessage(inputValue.inputValue),
      })}
      filterOption={searchValues ? undefined : customFilterOption}
      options={options}
      className={styles.multiSelect}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
        }),
      }}
    />
  );
};

const customFilterOption = (option: SelectorValue, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
