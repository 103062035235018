import { generateHTML } from "@tiptap/react";
import { requireStringEnvVar } from "../../../config";
import { getImpersonatedPersonId } from "../../local-storage/impersonated-person";
import { getPreferredLanguage } from "../../local-storage/language";
import { getOrganizationId } from "../../local-storage/selected-organization";
import { extensions, parseTiptapContent } from "./TipTap";

export const downloadTextDocument = async ({ fileName, content }: { fileName: string; content: string }) => {
  if (!content) throw Error("No content");
  const persistedOrganizationId = getOrganizationId();
  const impersonatedPersonId = getImpersonatedPersonId();
  const response = await fetch(`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/download/text-documents`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      ...(persistedOrganizationId ? { organizationId: persistedOrganizationId } : undefined),
      ...(impersonatedPersonId ? { personId: impersonatedPersonId } : undefined),
      "preferred-language": getPreferredLanguage(),
    },
    body: JSON.stringify({
      content: generateHTML(parseTiptapContent(content), extensions),
      fileName,
    }),
  });

  if (!response.ok) throw Error("Failed to download document");

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.pdf`;
  document.body.appendChild(a);
  a.click();
};
