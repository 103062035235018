import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BseDto, Currency, EventValueDto, useUpdateBasicSourcingEventMutation } from "../../../../autogen/bff-api";

interface Props {
  event: BseDto;
  showModal: boolean;
  onClose: () => void;
}

export const EditEventValueModal = ({ event, showModal, onClose }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [editEvent, { isLoading }] = useUpdateBasicSourcingEventMutation();
  const [eventValue, setEventValue] = useState<Partial<EventValueDto>>(
    event.awardedFields?.eventValue ?? {
      value: 0,
      currency: "NOK",
    }
  );

  const editEventValue = async () => {
    if (!eventValue.value || !eventValue.currency) return;
    const result = await editEvent({
      eventId: event.id,
      editSourcingEventRequest: {
        eventValue: {
          value: eventValue as EventValueDto,
        },
      },
    });

    if ("data" in result) {
      toast({
        title: t("Event value updated!"),
        status: "success",
      });
      onClose();
    }
  };
  return (
    <Modal isOpen={showModal} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Update event value")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex>
            <Input
              type="number"
              value={eventValue.value ?? ""}
              onChange={(e) => {
                const value = e.target.value === "" ? undefined : parseFloat(e.target.value);
                setEventValue({
                  currency: eventValue.currency,
                  value,
                });
              }}
            />
            <Select
              placeholder={t("Currency") ?? ""}
              ml="1"
              value={eventValue?.currency ?? "NOK"}
              onChange={(e) => {
                const currency = e.target.value as Currency;
                setEventValue({ currency, value: eventValue.value });
              }}
            >
              <option key={"NOK"} value={"NOK"}>
                NOK
              </option>
              <option key={"USD"} value={"USD"}>
                USD
              </option>
            </Select>
          </Flex>
          <Button
            mt="25px"
            variant={"solid"}
            colorScheme="teal"
            size={"md"}
            isLoading={isLoading}
            onClick={editEventValue}
          >
            {t("Save")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
