import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPaperPlane } from 'react-icons/fa'
import { Questionnaire } from './Questionnaire'

export const Questionnaires: React.FC = () => {
    const { t } = useTranslation()
    return (
        <Flex
            flexDirection={'column'}
            alignItems="start"
            justifyContent={'start'}
            borderRadius={'10px'}
            boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
            padding="25px"
            bg="white"
            w="100%"
        >
            <Box w="100%">
                <Text fontSize={'md'} fontWeight="bold" color="#a4a7a6">
                    {t('Questionnaires')}
                </Text>
                <Flex flexDirection={'column'} w="100%">
                    <Questionnaire
                        name="Åpenhetsloven del 4"
                        status="NotCompleted"
                    />
                    <Questionnaire
                        name="Åpenhetsloven del 3"
                        status="Completed"
                    />
                    <Questionnaire
                        name="Åpenhetsloven del 2"
                        status="Completed"
                    />
                    <Questionnaire
                        name="Åpenhetsloven del 1"
                        status="Declined"
                    />
                </Flex>
                <Flex mt="20px">
                    <Button
                        colorScheme="blue"
                        size="sm"
                        variant="solid"
                        leftIcon={<Icon as={FaPaperPlane} w="10px" h="10px" />}
                    >
                        {t('Send new questionnaire')}
                    </Button>
                </Flex>
            </Box>
        </Flex>
    )
}
