import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelector } from "../../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useOrganizationSearch } from "../../../../../organizations/search/useOrganizationSearch";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const InternalParties = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditInternalParties") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const organizationsData = useOrganizationSearch();
  const [selectedOrganizations, setSelectedOrganizations] = useState<SelectorValue[]>([]);

  useEffect(() => {
    if (contractState.contract.dataFields?.internalParties) {
      setSelectedOrganizations(
        contractState.contract.dataFields.internalParties.parties.map((e) => ({
          label: e.organizationName,
          value: e.organizationId,
        }))
      );
    }
  }, [contractState.contract.dataFields?.internalParties]);

  const eventHandler = async (orgIds: string[]) => {
    setIsLoading(true);
    const parties = orgIds.filter((id) => organizationsData.organizations?.map((o) => o.id)?.includes(id));
    const selfRegisteredParties = orgIds.filter((id) =>
      organizationsData.organizationEntries?.map((o) => o.id)?.includes(id)
    );
    await dispatch(
      editContractThunk({
        command: {
          type: "EditInternalParties",
          value: {
            parties,
            selfRegisteredParties,
          },
        },
      })
    );
    setIsLoading(false);
  };

  if (!organizationsData.organizationCombinations) return <div>{t("Loading...")}</div>;

  return (
    <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Internal parties")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Box borderRadius={"10px"} bg="white" w="100%">
          <MultiSelector
            value={selectedOrganizations}
            options={organizationsData.organizationCombinations
              .filter((o) => o.content.id !== contractState.contract.id)
              .map((o) => ({
                label: o.content.name,
                value: o.content.id,
              }))}
            noOptionsAvailableMessage={t("No contracts available")}
            onChange={(value) => {
              setSelectedOrganizations(value);
              eventHandler(value.map((e) => e.value));
            }}
          />
        </Box>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide a list of internal parties")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
