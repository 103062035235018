import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import isEmail from "validator/lib/isEmail";
import { ViewDocumentAsOwnerDto } from "../../../../../autogen/bff-api";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { SingleSelector } from "../../../../../common/input/Selector/SingleSelector";
import { Explanation } from "../../../../../common/support/Explanation";
import { SignerInput } from "./InvitedSigner";
import { InvitedSigners } from "./InvitedSigners";

interface Props {
  documents: ViewDocumentAsOwnerDto[];
  sendForSigning: (values: FormValues) => Promise<boolean>;
}

interface FormValues {
  contractDocument: SelectorValue;
  signers: SignerInput[] | undefined;
}

export const CreateSignedDocument = ({ documents, sendForSigning }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const methods = useForm<FormValues>({
    defaultValues: {},
  });

  const send = async (values: FormValues) => {
    setIsLoading(true);
    await sendForSigning(values);
    setIsLoading(false);
  };

  const contractDocument = methods.watch("contractDocument");
  const signers = methods.watch("signers");

  const allSignersAreValid =
    signers &&
    signers.length > 0 &&
    signers.every((e) => isEmail(e.email.trim()) && e.firstName.trim().length > 1 && e.lastName.trim().length > 1);

  return (
    <Box backgroundColor="smBackgroundSecondary" pt="20px" pl="20px" pr="20px" borderRadius={"10px"} mb="20px">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(send)}>
          <FormControl
            id="documentToBeSigned"
            isInvalid={methods.formState.errors.contractDocument !== undefined}
            borderRadius={"10px"}
          >
            <FormLabel>
              {t("Document to be signed")} {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Controller
              name="contractDocument"
              control={methods.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SingleSelector
                    value={value}
                    options={documents.map((e) => {
                      if (!e.id) throw Error("sould never happen");
                      return {
                        label: e.name,
                        value: e.id,
                      };
                    })}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    placeholder={{
                      text: t("Select document"),
                      color: "inherit",
                    }}
                    noOptionsProvidedMessage={t("No documents have been uploaded") ?? ""}
                    noMatchingOptionsMessage={t("No matching documents") ?? ""}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {methods.formState.errors.contractDocument && methods.formState.errors.contractDocument.message}
            </FormErrorMessage>
          </FormControl>
          <br />
          {contractDocument && (
            <>
              {signers && signers.length > 0 && (
                <FormLabel htmlFor={"signedDocuments"}>
                  {t("Signers")} {isLoading && <Spinner size="xs" />}
                </FormLabel>
              )}
              <InvitedSigners />
            </>
          )}
          {contractDocument && signers && signers.length > 0 && (
            <Flex justifyContent={"end"} mt="30px">
              <Explanation
                enabled={!allSignersAreValid}
                text={t(
                  "Make sure all signers have a valid email, first name and last name before sending the document for signing."
                )}
              >
                <Button
                  type="submit"
                  variant={"solid"}
                  size="md"
                  colorScheme={"blue"}
                  isLoading={isLoading}
                  isDisabled={!allSignersAreValid}
                  mb="20px"
                  leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
                >
                  {t("Send out for signing")}
                </Button>
              </Explanation>
            </Flex>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};
