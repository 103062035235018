// create a new typed react component with props named "Props"

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SignedDocumentDto } from '../../../../../autogen/bff-api'
import { DocumentSignerStatusTag } from './DocumentSignerStatusTag'

interface Props {
    signedDocument: SignedDocumentDto
    onClose: () => void
}

export const DocumentSignersModal: React.FC<Props> = ({ signedDocument, onClose }) => {
    const { t } = useTranslation()
    return (
        <Modal isOpen={true} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('Status for signers')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant="simple" mb="30px">
                        <Thead>
                            <Tr>
                                <Th>{t('Name')}</Th>
                                <Th>{t('Email')}</Th>
                                <Th>{t('Status')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {signedDocument.signers.map((e) => {
                                return (
                                    <Tr key={e.id}>
                                        <Td>{`${e.firstName} ${e.lastName}`}</Td>
                                        <Td>{e.email}</Td>
                                        <Td>
                                            <DocumentSignerStatusTag
                                                status={e.status}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })}
                            {signedDocument.signers.length === 0 && (
                                <Tr>
                                    <Td
                                        colSpan={3}
                                        width={'100%'}
                                        textAlign="center"
                                    >
                                        {t('No signers found')}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
