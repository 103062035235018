import {
    Box,
    Button,
    ModalBody,
    ModalFooter,
    Spinner,
    Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Explanation } from '../../support/Explanation'
import { FileUpload } from './FileRow'
import { SustainabilityFileRow } from './SustainabilityFileRow'

interface Props {
    fileRows: FileUpload[]
    showResults: () => void
}

export const SustainabilityAnalysisContentDocumentsStep: React.FC<Props> = ({
    fileRows,
    showResults,
}) => {
    const [selectedFileRows, setSelectedFileRows] = useState<FileUpload[]>([])
    const [isAnalysing, setIsAnalysing] = useState(false)
    const [startAnalyisText, setStartAnalyisText] = useState('Start analyse')
    const [isDone, setIsDone] = useState(false)
    const analysisStepTimeout = 3000

    return (
        <>
            <ModalBody>
                <Text>
                    Velg dokumentene som beskriver hvilke produkter og tjenester
                    anskaffelsen gjelder.
                </Text>
                <Box h="20px"></Box>
                {fileRows.map((file) => {
                    const isChecked = selectedFileRows.some(
                        (e) => e.uploadId === file.uploadId
                    )
                    return (
                        <SustainabilityFileRow
                            key={file.uploadId}
                            file={file}
                            isChecked={isChecked}
                            toggle={() => {
                                if (isChecked) {
                                    setSelectedFileRows((e) =>
                                        e.filter(
                                            (e) => e.uploadId !== file.uploadId
                                        )
                                    )
                                } else {
                                    setSelectedFileRows((e) => [...e, file])
                                }
                            }}
                        />
                    )
                })}
            </ModalBody>
            <ModalFooter>
                {isAnalysing && !isDone && (
                    <Spinner color="green.600" speed="0.65s" />
                )}
                <Explanation
                    text="Velg minst ett dokument før du starter analysen"
                    fontSize="sm"
                    enabled={selectedFileRows.length === 0}
                >
                    <Button
                        ml="20px"
                        variant="solid"
                        colorScheme={'green'}
                        isDisabled={
                            selectedFileRows.length === 0 || isAnalysing
                        }
                        onClick={() => {
                            setIsAnalysing(true)
                            setStartAnalyisText('Analyserer...')
                            setTimeout(() => {
                                setStartAnalyisText('Henter ut krav...')
                                setTimeout(() => {
                                    setIsDone(true)
                                    setStartAnalyisText('Ferdig!')
                                    setTimeout(() => {
                                        setIsAnalysing(false)
                                        showResults()
                                    }, 800)
                                }, analysisStepTimeout)
                            }, analysisStepTimeout)
                        }}
                    >
                        {startAnalyisText}
                    </Button>
                </Explanation>
            </ModalFooter>
        </>
    )
}
