import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../assets/assets";
import { useGetOrgUserEmailInviteQuery } from "../../../autogen/bff-api";
import { useSkeletons } from "../../../common/useSkeletons";
import { AlreadyAccepted } from "../AlreadyAccepted";
import { LoginDrawer } from "./LoginDrawer";
import { SignupDrawer } from "./SignupDrawer/SignupDrawer";
import { SignupOrLogin } from "./SignupOrLogin";

interface Props {
  inviteId: string;
  secret: string;
}

export const OrgUserEmailInvite: React.FC<Props> = ({ inviteId, secret }) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const skeletons = useSkeletons();
  const { t } = useTranslation();
  const { data, error } = useGetOrgUserEmailInviteQuery({
    secret: secret,
    inviteId: inviteId,
  });

  if (error) {
    return <AlreadyAccepted />;
  }

  if (!data) {
    return <div>{t("Loading")}...</div>;
  }

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {showSignup && (
        <SignupDrawer
          asOrg={false}
          isOpen={showSignup}
          onClose={() => setShowSignup(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      {showLogin && (
        <LoginDrawer
          isOpen={showLogin}
          onClose={() => setShowLogin(false)}
          invitationContext={{
            emailInviteId: inviteId,
            emailInviteType: "SourcingEvent",
            secret: secret,
          }}
        />
      )}
      <Grid
        gridTemplateColumns={"1fr minmax(0, 500px) 1fr"}
        height="100%"
        gridTemplateRows={"1fr minmax(480px, auto) 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"} bg="#f8f8f8"></GridItem>
        <GridItem area={"top"} bg="#f8f8f8"></GridItem>
        <GridItem area={"bottom"} bg="#f8f8f8"></GridItem>
        <GridItem area={"right"} bg="#f8f8f8"></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          {!data && skeletons.stackedLines(20)}
          {data && (
            <Flex w="100%" h={"100%"} flexDirection="column">
              <Flex paddingTop={"20px"} paddingBottom="35px">
                <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
              </Flex>
              <Text fontSize="2xl" mt="5px">
                <b>{t("Invitation to join organization")}</b>
              </Text>
              <Text fontSize="md" mt="20px">
                {t("You have been invited to join")}: <b>{data.organizationName}</b>.
              </Text>
              <Text fontSize="md" mt="20px" color="black">
                {t("You need to")} <b>{t("sign up")}</b> {t("or")} <b>{t("log in")}</b> {t("to accept this invitation")}
                .
              </Text>
              <SignupOrLogin onSignup={() => setShowSignup(true)} onLogin={() => setShowLogin(true)} />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </div>
  );
};
