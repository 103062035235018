import { Grid, GridItem, Spinner, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrganizationEntryDto } from "../../../autogen/bff-api";
import { urls } from "../../../urls";
import { OrganizationEntryModal } from "../entry/view";
import { LinkWithExistingOrgModal } from "../entry/view/LinkWithExistingOrgModal";
import { SearchResult } from "./SearchResult";
import styles from "./SearchResults.module.css";
import { OrganizationCombination } from "./useOrganizationSearch";

interface Props {
  organizations: OrganizationCombination[] | null;
  isSearching: boolean;
}

export const SearchResults = ({ organizations, isSearching }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showOrganizationEntryModal, setShowOrganizationEntryModal] = useState<OrganizationEntryDto>();
  const [showLinkWithExistingOrgModal, setShowLinkWithExistingOrgModal] = useState<OrganizationEntryDto>();

  return (
    <>
      {showOrganizationEntryModal && (
        <OrganizationEntryModal
          entry={showOrganizationEntryModal}
          onClose={() => setShowOrganizationEntryModal(undefined)}
          linkWithExistingOrg={() => {
            setShowOrganizationEntryModal(undefined);
            setShowLinkWithExistingOrgModal(showOrganizationEntryModal);
          }}
        />
      )}
      {showLinkWithExistingOrgModal && (
        <LinkWithExistingOrgModal
          entry={showLinkWithExistingOrgModal}
          onClose={() => {
            setShowLinkWithExistingOrgModal(undefined);
          }}
        />
      )}
      <Grid gridTemplateColumns={"1fr"} gridTemplateRows="minmax(50px, 1fr)" gridAutoRows={"minmax(100px, auto)"}>
        <GridItem display="flex" alignItems={"center"}>
          <Text fontSize="md" marginLeft={4}>
            {organizations && (
              <>
                <b>{organizations.length}</b> {t("companies found")}{" "}
              </>
            )}
          </Text>
          {isSearching && <Spinner ml="8px" size={"xs"} />}
        </GridItem>

        {organizations ? (
          organizations.map((e) => (
            <GridItem
              key={e.content.id}
              className={styles.result}
              py="5"
              onClick={(): boolean => {
                switch (e.type) {
                  case "Organization":
                    navigate(urls.organizations.profile.go(e.content.id));
                    return true;
                  case "OrganizationEntry":
                    setShowOrganizationEntryModal(e.content);
                    return true;
                }
              }}
            >
              <SearchResult organization={e} />
            </GridItem>
          ))
        ) : (
          <div>{t("Loading...")}</div>
        )}
      </Grid>
    </>
  );
};
