import { Box, Checkbox, Td, Tr } from "@chakra-ui/react";
import { useState } from "react";
import { Product, ProductDraft, ProductTemplateField } from "../types";
import { displayTotalProductPrice } from "./productUtils";

export const ProductTableRow = ({
  product,
  templateFields,
}: {
  product: Product | ProductDraft;
  templateFields: ProductTemplateField[];
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>();

  return (
    <Tr key={product.id} maxWidth={"full"} overflow={"hidden"}>
      {templateFields
        .filter((f) => f.populatedBy === "Buyer")
        .map((field) => (
          <Td key={field.id} position={"relative"} verticalAlign={"top"}>
            <Box
              maxHeight={isExpanded ? "none" : "10"}
              overflow={"hidden"}
              cursor={"pointer"}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {field.type === "Boolean" ? (
                <Checkbox
                  isDisabled={true}
                  isChecked={!!product.productFields.find((f) => f.name === field.name)?.value}
                />
              ) : (
                <Box as="pre" fontFamily={"body"} whiteSpace={"pre-wrap"}>
                  {product.productFields.find((f) => f.name === field.name)?.value}
                </Box>
              )}
            </Box>
            <Box
              width={"full"}
              position={"absolute"}
              top="5"
              bottom="0"
              left="0"
              bgGradient={isExpanded ? "" : "linear(to bottom, transparent, white)"}
              pointerEvents={"none"}
            ></Box>
          </Td>
        ))}
      <Td textAlign={"center"} verticalAlign={"top"}>
        {product.quantity.value?.toLocaleString()}
      </Td>
      <Td textAlign={"center"} verticalAlign={"top"}>
        {product.unitPrice?.value?.toLocaleString()}
      </Td>
      <Td textAlign={"center"} verticalAlign={"top"}>
        {displayTotalProductPrice(product)}
      </Td>
      {templateFields
        .filter((f) => f.populatedBy === "Supplier")
        .map((field) => (
          <Td key={field.id} position={"relative"} verticalAlign={"top"}>
            <Box
              maxHeight={isExpanded ? "none" : "10"}
              overflow={"hidden"}
              cursor={"pointer"}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {field.type === "Boolean" ? (
                <Checkbox
                  isDisabled={true}
                  isChecked={!!product.productFields.find((f) => f.name === field.name)?.value}
                />
              ) : (
                <Box as="pre" fontFamily={"body"} whiteSpace={"pre-wrap"}>
                  {product.productFields.find((f) => f.name === field.name)?.value}
                </Box>
              )}
            </Box>
            <Box
              width={"full"}
              position={"absolute"}
              top="5"
              bottom="0"
              left="0"
              bgGradient={isExpanded ? "" : "linear(to bottom, transparent, white)"}
              pointerEvents={"none"}
            ></Box>
          </Td>
        ))}
    </Tr>
  );
};
