import { Box, Button, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaSignature } from "react-icons/fa";
import {
  ContractDto,
  SignedDocumentDto,
  TextDocumentDto,
  ViewDocumentAsOwnerDto,
  useCreateSignedDocumentMutation,
  useListTextDocumentsForContractQuery,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { useApiError } from "../../../../common/errors/useApiError";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { Explanation } from "../../../../common/support/Explanation";
import { useSkeletons } from "../../../../common/useSkeletons";
import { requireBooleanEnvVar } from "../../../../config";
import { SignerInput } from "../../edit/docs/signatures/InvitedSigner";
import { SendForSigningModal } from "../../edit/docs/signatures/SendForSigningModal";
import { requireDocumentFields } from "../../typeguards";
import { ContractDocumentRow } from "./ContractDocumentRow";
import { DocumentAnalysisModal } from "./DocumentAnalysisModal";

interface Props {
  data: ContractDto;
  reload: () => void;
}

export const Documents = ({ data, reload }: Props) => {
  const [showSigningModal, setShowSigningModal] = useState(false);
  const [documentIdForAnalysis, setDocumentIdForAnalysis] = useState<string>();
  const documentFields = requireDocumentFields(data);
  const [createSignedDocument] = useCreateSignedDocumentMutation();
  const toast = useToast();
  const displayer = useApiError();
  const skeletons = useSkeletons();

  const { data: textDocRes, isLoading: isLoadingTextDocs } = useListTextDocumentsForContractQuery({
    contractId: data.id,
  });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  const sendForSigning = async (values: {
    contractDocument: SelectorValue;
    signers: SignerInput[] | undefined;
  }): Promise<boolean> => {
    if (!values.signers) {
      throw new Error("Signers are not defined");
    }

    const result = await createSignedDocument({
      contractId: data.id,
      createSignedDocumentRequest: {
        mainDocumentId: values.contractDocument.value,
        signers: values.signers,
        language: "No",
      },
    });
    let success = false;
    if ("data" in result) {
      toast({
        title: t("Contract sent for signing"),
        status: "success",
      });
      success = true;
      reload();
      setShowSigningModal(false);
    } else {
      displayer.trigger(result.error);
    }
    return success;
  };

  const documents: (ViewDocumentAsOwnerDto | SignedDocumentDto)[] = [
    ...documentFields.documents,
    ...documentFields.signedDocuments,
  ];

  const signingDisabled = documentFields.documents.length === 0;

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex w="100%" flexDirection={"column"}>
        {showSigningModal && (
          <SendForSigningModal
            documents={documentFields.documents}
            sendForSigning={sendForSigning}
            onClose={() => setShowSigningModal(false)}
          />
        )}
        {data.owningOrgHasSignatureAccess && data.canEditEverything && (
          <Flex w="100%" justifyContent={"end"}>
            <Explanation
              enabled={signingDisabled}
              text={t("At least 1 unsigned document must be added to start the signing process.")}
            >
              <Button
                leftIcon={<Icon as={FaSignature} w="15px" h="15px" />}
                variant={"solid"}
                mr="5px"
                colorScheme={"teal"}
                size="sm"
                isDisabled={signingDisabled}
                mb="22px"
                onClick={() => setShowSigningModal(true)}
              >
                {t("Document signing")}
              </Button>
            </Explanation>
          </Flex>
        )}
        <Box borderRadius={10} width="full" border="1px solid" borderColor={"smBorder"} pb="2">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("Name")}</Th>
                <Th></Th>
                <Th>{t("Uploaded by")}</Th>
                <Th>{t("Date")}</Th>
                <Th></Th>
                {requireBooleanEnvVar("VITE_ENABLE_DOCUMENT_ANALYSIS") && <Th></Th>}
              </Tr>
            </Thead>
            <Tbody>
              <>
                {documents.map((e) => {
                  return (
                    <ContractDocumentRow
                      key={e.id}
                      contractDocument={e}
                      setDocumentIdForAnalysis={setDocumentIdForAnalysis}
                    />
                  );
                })}
                {documentFields.documents.length === 0 && (
                  <Tr>
                    <Td colSpan={5} width="full" textAlign="center" fontSize="small">
                      {t("No documents found")}
                    </Td>
                  </Tr>
                )}
              </>
            </Tbody>
          </Table>
        </Box>
        {documentIdForAnalysis && (
          <DocumentAnalysisModal docId={documentIdForAnalysis} setDocumentIdForAnalysis={setDocumentIdForAnalysis} />
        )}
      </Flex>
      <Text fontSize={"md"} fontWeight="bold" pt="5" pb="3">
        {t("Text documents")}
      </Text>
      {!!textDocRes?.documents.length && (
        <TextDocumentTable documents={textDocRes.documents} onClick={setTextDocumentToView} isDownloadable={true} />
      )}
      {textDocRes?.documents.length === 0 && (
        <Flex width="full" justifyContent="center">
          <Text fontSize="small">{t("No text documents")}</Text>
        </Flex>
      )}
      {isLoadingTextDocs && !textDocRes && skeletons.stackedLines(0, 5)}
    </>
  );
};
