import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, isEqual } from "lodash";
import { useCallback } from "react";
import { FaTrash } from "react-icons/fa";
import {
  ContractDateReminderDto,
  ContractDateReminderType,
  DefaultNotificationSettingsDto,
  useUpdateOrganizationMutation,
} from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../common/redux/reducers/authStateReducer";
import { initSettings } from "./Settings";

export const ContractDateReminderSetting = ({
  setting,
  type,
}: {
  setting: ContractDateReminderDto;
  type: ContractDateReminderType;
}) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const leadTimeUnits = {
    Days: t("days"),
    Months: t("months"),
  };

  const deleteSetting = useCallback(async () => {
    const settings: DefaultNotificationSettingsDto =
      cloneDeep(authState.selectedOrg.defaultNotificationSettings) ?? initSettings();
    switch (type) {
      case "StartDateReminder":
        settings.contractNotificationSettings.startDateNotifications =
          settings.contractNotificationSettings.startDateNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "EndDateReminder":
        settings.contractNotificationSettings.endDateNotifications =
          settings.contractNotificationSettings.endDateNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "RenewalDateReminder":
        settings.contractNotificationSettings.renewalDateNotifications =
          settings.contractNotificationSettings.renewalDateNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "WarrantyExpirationDateReminder":
        settings.contractNotificationSettings.warrantyExpirationNotifications =
          settings.contractNotificationSettings.warrantyExpirationNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "TerminationDateReminder":
        settings.contractNotificationSettings.terminationDateNotifications =
          settings.contractNotificationSettings.terminationDateNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "NoticePeriodReminder":
        settings.contractNotificationSettings.noticePeriodNotifications =
          settings.contractNotificationSettings.noticePeriodNotifications.filter((s) => !isEqual(s, setting));
        break;
      case "OptionExecutionDeadlineReminder":
        settings.contractNotificationSettings.optionExecutionDeadlineNotifications =
          settings.contractNotificationSettings.optionExecutionDeadlineNotifications.filter(
            (s) => !isEqual(s, setting)
          );
        break;
      case "OptionStartDateReminder":
        settings.contractNotificationSettings.optionStartDateNotifications =
          settings.contractNotificationSettings.optionExecutionDeadlineNotifications.filter(
            (s) => !isEqual(s, setting)
          );
        break;
      case "NoEndDateReminder":
        settings.contractNotificationSettings.noEndDateNotifications =
          settings.contractNotificationSettings.noEndDateNotifications.filter((s) => !isEqual(s, setting));
        break;
    }
    const response = await updateOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        defaultNotificationSettings: settings,
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
    }
  }, [authState.selectedOrg.defaultNotificationSettings, authState.selectedOrg.id, dispatch, setting, type, updateOrg]);

  return (
    <Flex flexDirection={"column"} fontSize={"sm"} backgroundColor={"gray.50"} rounded="md" p="2" my="1">
      <Flex justifyContent={"space-between"} width="full">
        <Text>{t("Time of notification")}</Text>
        <IconButton
          aria-label="delete setting"
          icon={<Icon as={FaTrash} />}
          size={"xs"}
          isLoading={isLoading}
          onClick={() => deleteSetting()}
        />
      </Flex>
      <Text fontWeight={"bold"}>
        {setting.leadTime.amount} {leadTimeUnits[setting.leadTime.unit]} {t("before date")}
      </Text>
      <Text>{t("Individual receivers")}</Text>
      {authState.selectedOrg.users
        .filter((u) => setting.receivers.persons.includes(u.person.id))
        .map((user) => (
          <Text key={user.person.id} fontWeight={"bold"}>
            {user.person.firstName} {user.person.lastName}
          </Text>
        ))}
      <Text>{t("Organization role receivers")}</Text>
      {setting.receivers.organizationRoles.map((role) => (
        <Text key={role} fontWeight={"bold"}>
          {role}
        </Text>
      ))}
      <Text>{t("Contract role receivers")}</Text>
      {setting.receivers.contractRoles.map((role) => (
        <Text key={role} fontWeight={"bold"}>
          {role}
        </Text>
      ))}
    </Flex>
  );
};
