import { Button, Flex, useToast } from '@chakra-ui/react'
import { t } from 'i18next'
import React, { useState } from 'react'
import {
    ContractTaskDto,
    useUpdateContractTaskMutation,
} from '../../../../../../autogen/bff-api'
import { TimestampSelector } from '../../../../../../common/input/TimestampSelector/TimestampSelector'

interface Props {
    task: ContractTaskDto
    onEditComleted: (task: ContractTaskDto) => void
    onCancel: () => void
}

export const EditDueDate: React.FC<Props> = ({
    task,
    onEditComleted,
    onCancel,
}) => {
    const [date, setDate] = useState(task.dueDate)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const [update] = useUpdateContractTaskMutation()
    return (
        <Flex flexDirection={'column'} w="100%" mt="10px">
            <TimestampSelector
                datePlaceholderText={''}
                showTimeInput={false}
                date={date}
                errorMessage={null}
                dateFormat={'dd MMM yyyy'}
                onDateChange={async (e) => setDate(e)}
                fontSize={'15px'}
            />
            <Flex mt="10px">
                <Button
                    id="save-due-date-button"
                    variant={'solid'}
                    colorScheme="teal"
                    size="xs"
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true)
                        const result = await update({
                            taskId: task.id,
                            updateTaskRequest: {
                                dueDate: date,
                            },
                        })
                        setIsLoading(false)

                        if ('data' in result) {
                            onEditComleted(result.data)
                        } else {
                            toast({
                                title: t('Could not edit due date'),
                                status: 'error',
                            })
                        }
                    }}
                >
                    {t('Save')}
                </Button>
                <Button
                    variant={'solid'}
                    colorScheme="gray"
                    size="xs"
                    ml="5px"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
            </Flex>
        </Flex>
    )
}
