import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  ContractDto,
  ContractRoleType,
  PersonDto,
  useCreateContractParticipantMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { RadioFormSelector } from "../../../../common/input/Radio/RadioFormSelector";
import { OrganizationFormSelector } from "../../../../common/input/Selector/OrganizationFormSelector";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { SingleFormSelector } from "../../../../common/input/Selector/SingleFormSelector";
import { OrganizationCombination } from "../../../organizations/search/useOrganizationSearch";

interface Props {
  data: ContractDto;
  onClose: () => void;
}

interface FormValues {
  organization?: OrganizationCombination;
  person?: SelectorValue;
  roleType: ContractRoleType;
  shouldNotifyByEmail: boolean;
}

export const AddParticipantModal: React.FC<Props> = ({ data, onClose }) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      roleType: "Editor",
      shouldNotifyByEmail: true,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [createParticipant] = useCreateContractParticipantMutation();
  const toast = useToast();
  const displayer = useApiError();

  const organization = methods.watch("organization");

  if (organization && organization.type !== "Organization") throw new Error("Should never happen");

  const create = async (values: FormValues) => {
    if (!values.organization) {
      toast({ title: t("Please select an organization"), status: "error" });
      return;
    }
    setIsLoading(true);
    const result = await createParticipant({
      contractId: data.id,
      createContractParticipantRequest: {
        organizationId: values.organization.content.id,
        personId: values.person?.value,
        roleType: values.roleType,
        shouldNotifyByEmail: values.shouldNotifyByEmail,
      },
    });
    if ("data" in result) {
      toast({
        title: t("Participant added!"),
        status: "success",
      });
      onClose();
    } else {
      displayer.trigger(result.error);
    }
  };

  const options: {
    label: string;
    description: React.ReactElement;
    value: ContractRoleType;
  }[] = [
    {
      label: t("Editor"),
      description: (
        <Text fontSize="sm" color="#4a5568">
          {t("Can see and edit everything")}.
        </Text>
      ),
      value: "Editor",
    },
    {
      label: t("Viewer"),
      description: (
        <Text fontSize="sm" color="#4a5568">
          {t("Can see everything, but not edit anything")}.
        </Text>
      ),
      value: "Viewer",
    },
    {
      label: t("Counterparty"),
      description: (
        <>
          <Text fontSize="sm" color="#4a5568">
            {t("Can see all details, data-fields and documents")}.
          </Text>{" "}
          <Text fontSize="sm" color="#4a5568">
            {t("Can see and edit all tasks that are delegated to them")}.
          </Text>
        </>
      ),
      value: "Counterparty",
    },
  ];

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add new participant")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              <OrganizationFormSelector
                label={t("Organization")}
                helperText={t("Please select an organization to share this contract with")}
                control={methods.control}
                name="organization"
                excludeCurrentOrg={false}
              />
              {organization && (
                <Box mt="30px">
                  <SingleFormSelector<FormValues>
                    options={organization.content.persons.map((e) => ({
                      label: displayPersonNameWithEmail(e),
                      value: e.id,
                    }))}
                    label={t("Share with specific person in organization (optional)")}
                    control={methods.control}
                    name={"person"}
                    helperText={t("Please select a person")}
                    placeholderText={t("Please select a person")}
                    noMatchingOptionsMessage={t("No matching persons")}
                    noOptionsProvidedMessage={t("No persons available")}
                    required={{
                      value: false,
                      message: "",
                    }}
                    errorMessage={undefined}
                  />
                </Box>
              )}
              <Box mt="30px">
                <RadioFormSelector options={options} label={t("Role")} control={methods.control} name={"roleType"} />
              </Box>
              <FormControl pt="5">
                <Controller<FormValues>
                  name={"shouldNotifyByEmail"}
                  control={methods.control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox isChecked={!!value} name="shouldNotifyByEmail" onChange={onChange}>
                      {t("Send email notification to participant(s)")}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button type="submit" variant="solid" colorScheme={"blue"} isLoading={isLoading}>
                {t("Add")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export const displayPersonNameWithEmail = (person: PersonDto): string => {
  return `${person.firstName} ${person.lastName} (${person.email})`;
};

export const displayPersonName = (person: PersonDto): string => {
  return `${person.firstName} ${person.lastName}`;
};
