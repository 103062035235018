import { Button, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrganizationQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { Explanation } from "../../../common/support/Explanation";
import { CONTENT_WIDTH } from "../../../config";
import { urls } from "../../../urls";
import { Banner } from "./Banner";
import { Content } from "./Content";

export const ViewOrganizationProfile = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const displayer = useApiError();
  const { data, error } = useGetOrganizationQuery(organizationId ? { orgId: organizationId } : skipToken);

  if (error) return displayer.show(error);

  if (!data) return <div>Loading...</div>;

  const canEdit = authState.selectedOrg.canEdit;

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"170px"}
        templateAreas={`
                "leftTop top rightTop"
                "leftContent content rightContent"
                `}
        backgroundColor="smBackgroundSecondary"
      >
        <GridItem area="top" display={"flex"} alignItems="center" backgroundColor="smBackground" px="2">
          <Flex justifyContent={"end"} w="100%">
            <Banner name={data.name} country={data.country} />
            {data.id === authState.selectedOrg.id && (
              <Explanation enabled={!canEdit} text={t("Only admins can edit the organization")}>
                <Button
                  leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
                  variant={"outline"}
                  colorScheme="teal"
                  size={"sm"}
                  isDisabled={!canEdit}
                  onClick={() => navigate(urls.myProfile.organization.edit)}
                >
                  {t("Edit")}
                </Button>
              </Explanation>
            )}
          </Flex>
        </GridItem>
        <GridItem area={"content"} borderTop="1px solid" borderColor="smBorder" paddingTop="25px" px="2">
          <Content organization={data} />
        </GridItem>
      </Grid>
    </Layout>
  );
};
