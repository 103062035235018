import { Button, Flex, Icon, Input, Select, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, isEqual, isFinite } from "lodash";
import { useCallback, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import {
  ContractTaskDateReminderDto,
  DefaultNotificationSettingsDto,
  LeadTimeDto,
  LeadTimeUnit,
  useUpdateOrganizationMutation,
} from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../common/redux/reducers/authStateReducer";
import { initSettings } from "./Settings";

type ContractTaskDateReminder = Omit<ContractTaskDateReminderDto, "leadTime"> & { leadTime: Partial<LeadTimeDto> };

const initSetting = (): ContractTaskDateReminder => ({
  leadTime: {
    amount: undefined,
    unit: "Days",
  },
  receivers: {
    contractTaskRoles: ["Responsible"],
  },
});

const isContractTaskDateReminderDto = (setting: ContractTaskDateReminder): setting is ContractTaskDateReminderDto => {
  return !!setting.leadTime.unit && isFinite(setting.leadTime.amount);
};

export const ContractTaskNotificationSettingForm = () => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const [isVisible, setIsVisible] = useState(false);
  const [setting, setSetting] = useState<ContractTaskDateReminder>(initSetting());

  const saveSetting = useCallback(
    async (setting: ContractTaskDateReminder) => {
      if (!isContractTaskDateReminderDto(setting)) throw Error("Invalid date reminder setting!");
      const settings: DefaultNotificationSettingsDto =
        cloneDeep(authState.selectedOrg.defaultNotificationSettings) ?? initSettings();
      if (settings?.contractTaskNotificationSettings.dueDateNotifications.find((s) => isEqual(s, setting))) {
        toast({ description: "This setting already exists", variant: "subtle", status: "error" });
        return;
      }
      settings.contractTaskNotificationSettings.dueDateNotifications.push(setting);
      const response = await updateOrg({
        orgId: authState.selectedOrg.id,
        editOrganizationRequest: {
          defaultNotificationSettings: settings,
        },
      });

      if ("data" in response) {
        dispatch(updateSelectedOrg(response.data as OrgDto));
        setSetting(initSetting());
        setIsVisible(false);
      }
    },
    [authState.selectedOrg.defaultNotificationSettings, authState.selectedOrg.id, dispatch, toast, updateOrg]
  );

  if (!isVisible)
    return (
      <Flex>
        <Button
          rightIcon={<Icon as={FaPlus} />}
          size="xs"
          variant={"ghost"}
          colorScheme="teal"
          onClick={() => setIsVisible(true)}
        >
          {t("Create new")}
        </Button>
      </Flex>
    );

  return (
    <Flex flexDirection={"column"} rowGap={"2"} border="1px solid" borderColor={"gray.100"} rounded="md" p="2">
      <Flex columnGap={"2"}>
        <Input
          type="number"
          width="30%"
          size={"md"}
          rounded="md"
          placeholder={`${t("Duration")}...`}
          min={0}
          value={setting.leadTime.amount === undefined ? "" : setting.leadTime.amount}
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.leadTime.amount = e.target.value === "" ? undefined : +e.target.value;
            setSetting(updatedSetting);
          }}
        />
        <Select
          width={"30%"}
          value={setting.leadTime.unit}
          size={"md"}
          rounded="md"
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.leadTime.unit = e.target.value as LeadTimeUnit;
            setSetting(updatedSetting);
          }}
        >
          <option value="Days">{t("Days")}</option>
          <option value="Months">{t("Months")}</option>
        </Select>
      </Flex>
      <Flex>
        <Button
          colorScheme="blue"
          size={"xs"}
          rightIcon={<Icon as={FaCheck} />}
          isLoading={isLoading}
          isDisabled={setting.leadTime.amount === undefined}
          onClick={() => saveSetting(setting)}
        >
          {t("Save")}
        </Button>
      </Flex>
    </Flex>
  );
};
