import { Box, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { ReactElement } from "react";
import {
  BidNotificationMetaDto,
  ContractDocumentNotificationMetaDto,
  ContractNotificationMetaDto,
  ContractReminderNotificationMetaDto,
  ContractTaskNotificationMetaDto,
  NotificationDto,
  SourcingEventAnnouncementNotificationMetaDto,
  SourcingEventNotificationMetaDto,
  SourcingEventQuestionCommentNotificationMetaDto,
  SourcingEventQuestionNotificationMetaDto,
} from "../../autogen/bff-api";
import { displayDate } from "../../common/formatting/displayDate";
import { displayWrappedWithEllipsis } from "../../common/formatting/displayWrappedWithEllipsis";

export const getNotificationDescription = (notification: NotificationDto): ReactElement => {
  switch (notification.type) {
    case "ContractTaskDelegated": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("delegated the task")}{" "}
            <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("to you for the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "ContractTaskStatusUpdated": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("changed the status for the task")}{" "}
            <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("in the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "ContractDateReminder": {
      const meta = requireContractReminderMeta(notification);

      const getNotificationName = (): string => {
        switch (meta.reminderType) {
          case "EndDateReminder":
            return t("end date");
          case "RenewalDateReminder":
            return t("renewal date");
          case "WarrantyExpirationDateReminder":
            return t("warranty expiration date");
          case "StartDateReminder":
            return t("start date");
          case "TerminationDateReminder":
            return t("termination date");
          case "NoticePeriodReminder":
            return t("start of notice period");
          case "OptionExecutionDeadlineReminder":
            return t("option execution deadline");
          case "OptionStartDateReminder":
            return t("option start date");
          case "NoEndDateReminder":
            return t("no end date");
        }
      };

      if (meta.reminderType === "NoEndDateReminder") {
        return (
          <Box>
            <Text fontSize={"sm"}>
              {t("You have an active contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}</b>{" "}
              {t("without an end date created the")} <b>{displayDate(meta.date)}</b>.
            </Text>
          </Box>
        );
      }

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The")} <b>{getNotificationName()}</b> {t("for the contract")}
            {" '"}
            {displayWrappedWithEllipsis(meta.contractName, 100)}
            {"' "}
            {t("is approaching at the")} <b>{displayDate(meta.date)}</b>.
          </Text>
        </Box>
      );
    }
    case "ContractDocumentCompletedSigning": {
      const meta = requireContractDocumentMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The document")} <b>{displayWrappedWithEllipsis(meta.documentName, 100)} </b>
            {t("for the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)} </b>
            {t("has completed signing!")}
          </Text>
        </Box>
      );
    }
    case "ContractSharedWithDepartment": {
      const meta = requireContractkMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("shared the contract")}{" "}
            <b>{displayWrappedWithEllipsis(meta.contractName, 100)} </b>
            {t("with your department")}.
          </Text>
        </Box>
      );
    }
    case "ContractSharedWithOrg": {
      const meta = requireContractkMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("shared the contract")}{" "}
            <b>{displayWrappedWithEllipsis(meta.contractName, 100)} </b>
            {t("with your organization")}.
          </Text>
        </Box>
      );
    }
    case "ContractSharedWithPerson": {
      const meta = requireContractkMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("shared the contract")}{" "}
            <b>{displayWrappedWithEllipsis(meta.contractName, 100)} </b>
            {t("with you")}.
          </Text>
        </Box>
      );
    }
    case "ContractTaskCommentAdded": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("added a comment for the task")}{" "}
            <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("in the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "ContractTaskDeleted": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("deleted the task")} <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("in the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "ContractTaskDueDateUpdated": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {meta.triggeredByFirstName} {t("changed the due date for the task")}{" "}
            <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("in the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "ContractTaskDueDateReminder": {
      const meta = requireTaskMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The due date is approaching for the task")} <b>{displayWrappedWithEllipsis(meta.taskName, 100)} </b>
            {t("in the contract")} <b>{displayWrappedWithEllipsis(meta.contractName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "SourcingEventAnnouncement": {
      const meta = requireSourcingEventAnnouncementMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The sourcing event")} <b>{displayWrappedWithEllipsis(meta.eventTitle, 100)}</b>{" "}
            {t("has a new announcement!")}
          </Text>
        </Box>
      );
    }
    case "SourcingEventAwarded": {
      const meta = requireSourcingEventMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The sourcing event")} <b>{displayWrappedWithEllipsis(meta.eventTitle, 100)}</b> {t("has been awarded!")}
          </Text>
        </Box>
      );
    }
    case "SourcingEventNewQuestion": {
      const meta = requireSourcingEventQuestionMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("A new question has been asked for the sourcing event")}{" "}
            <b>{displayWrappedWithEllipsis(meta.eventTitle, 100)}</b>.
          </Text>
        </Box>
      );
    }
    case "SourcingEventNewQuestionComment": {
      const meta = requireSourcingEventQuestionCommentMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("A new comment has been added to the question")}{" "}
            <b>{displayWrappedWithEllipsis(meta.questionSubject, 100)} </b>
          </Text>
        </Box>
      );
    }
    case "SourcingEventOrganizationInvite": {
      const meta = requireSourcingEventMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("You have been invited to deliver a bid for the sourcing event")}{" "}
            <b>{displayWrappedWithEllipsis(meta.eventTitle, 100)} </b>
            {t("by")} <b>{displayWrappedWithEllipsis(meta.eventOwningOrganizationName, 100)}.</b>
          </Text>
        </Box>
      );
    }
    case "SourcingEventQuestionStatusUpdated": {
      const meta = requireSourcingEventQuestionMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The status of your question")} <b>{displayWrappedWithEllipsis(meta.questionSubject, 100)} </b>
            {t("has been updated")}{" "}
          </Text>
        </Box>
      );
    }
    case "BidDeliveryDeadline": {
      const meta = requireSourcingEventMeta(notification);

      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("The bid delivery deadline for")} <b>{displayWrappedWithEllipsis(meta.eventTitle, 100)} </b>
            {t("is approaching")}{" "}
          </Text>
        </Box>
      );
    }
    case "BidDelivered": {
      const meta = requireBidMeta(notification);
      return (
        <Box>
          <Text fontSize={"sm"}>
            {t("A bid was delivered for")} <b>{meta.eventTitle}</b>.
          </Text>
        </Box>
      );
    }
  }
};

export const requireTaskMeta = (notification: NotificationDto): ContractTaskNotificationMetaDto => {
  if (!notification.meta.contractTask) {
    throw new Error("should never happen");
  }
  return notification.meta.contractTask;
};

export const requireContractkMeta = (notification: NotificationDto): ContractNotificationMetaDto => {
  if (!notification.meta.contract) {
    throw new Error("should never happen");
  }
  return notification.meta.contract;
};

export const requireContractReminderMeta = (notification: NotificationDto): ContractReminderNotificationMetaDto => {
  if (!notification.meta.contractReminder) {
    throw new Error("should never happen");
  }
  return notification.meta.contractReminder;
};

export const requireContractDocumentMeta = (notification: NotificationDto): ContractDocumentNotificationMetaDto => {
  if (!notification.meta.contractDocument) {
    throw new Error("should never happen");
  }
  return notification.meta.contractDocument;
};

export const requireSourcingEventMeta = (notification: NotificationDto): SourcingEventNotificationMetaDto => {
  if (!notification.meta.sourcingEvent) {
    throw new Error("should never happen");
  }
  return notification.meta.sourcingEvent;
};

export const requireBidMeta = (notification: NotificationDto): BidNotificationMetaDto => {
  if (!notification.meta.bid) throw new Error("Notification does not have bid meta");
  return notification.meta.bid;
};

export const requireSourcingEventQuestionMeta = (
  notification: NotificationDto
): SourcingEventQuestionNotificationMetaDto => {
  if (!notification.meta.sourcingEventQuestion) {
    throw new Error("should never happen");
  }
  return notification.meta.sourcingEventQuestion;
};

export const requireSourcingEventQuestionCommentMeta = (
  notification: NotificationDto
): SourcingEventQuestionCommentNotificationMetaDto => {
  if (!notification.meta.sourcingEventQuestionComment) {
    throw new Error("should never happen");
  }
  return notification.meta.sourcingEventQuestionComment;
};

export const requireSourcingEventAnnouncementMeta = (
  notification: NotificationDto
): SourcingEventAnnouncementNotificationMetaDto => {
  if (!notification.meta.sourcingEventAnnouncement) {
    throw new Error("should never happen");
  }
  return notification.meta.sourcingEventAnnouncement;
};
