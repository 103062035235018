import { Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BseSupplierViewDto,
  useAcceptInvitationToBidMutation,
  useCreateBseBidDraftAsDepartmentMutation,
} from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../../common/errors/useApiError";

interface Props {
  event: BseSupplierViewDto;
  shouldAcceptInvite: boolean;
  refetch: () => void;
}

export const CreateDraftButton = ({ event, shouldAcceptInvite, refetch }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();
  const [acceptInvitationToBid] = useAcceptInvitationToBidMutation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();

  const [createBidDraft] = useCreateBseBidDraftAsDepartmentMutation();

  const acceptInvite = async (): Promise<boolean> => {
    const result = await acceptInvitationToBid({
      eventId: event.id,
    });
    if ("data" in result) {
      return true;
    } else {
      apiErrorDisplayer.trigger(result.error);
      return false;
    }
  };

  const createBid = async (): Promise<boolean> => {
    const result = await createBidDraft({
      eventId: event.id,
      depId: authState.selectedOrg.departments[0].id,
    });

    if ("data" in result) {
      return true;
    } else {
      apiErrorDisplayer.trigger(result.error);
      return false;
    }
  };

  return (
    <Button
      variant={"outline"}
      colorScheme="teal"
      onClick={async () => {
        setIsLoading(true);
        if (shouldAcceptInvite) {
          const acceptInviteSuccess = await acceptInvite();
          if (acceptInviteSuccess) {
            const createBidSuccess = await createBid();
            if (createBidSuccess) {
              toast({
                title: t("Bid draft created!"),
                status: "success",
              });
              refetch();
            }
          }
        } else {
          const createBidSuccess = await createBid();

          if (createBidSuccess) {
            toast({
              title: t("Bid draft created!"),
              status: "success",
            });
            refetch();
          }
        }
        setIsLoading(false);
      }}
      isLoading={isLoading}
    >
      {t("Yes, create a draft")}
    </Button>
  );
};
