import { Box } from "@chakra-ui/react";
import { Select, useChakraSelectProps } from "chakra-react-select";
import { SizeProp } from "chakra-react-select/dist/types/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorOptions, SelectorValue } from "./SelectorValue";

interface Props {
  value: SelectorValue | null;
  options: SelectorOptions;
  onChange: (value: SelectorValue | null) => void;
  placeholder?: {
    text: string;
    color: string;
  };
  noOptionsProvidedMessage?: string;
  noMatchingOptionsMessage?: string;
  isClearable?: boolean;
  size?: SizeProp;
  isLoading?: boolean;
}

export const SingleSelector = ({
  value,
  options,
  onChange,
  placeholder,
  noOptionsProvidedMessage,
  noMatchingOptionsMessage,
  isClearable,
  size,
  isLoading,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<SelectorValue | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const optionsMessage = noOptionsProvidedMessage ? noOptionsProvidedMessage : t("No options provided");
  const matchingOptionsMessage = noMatchingOptionsMessage ? noMatchingOptionsMessage : t("No matching elements found");

  return (
    <Select
      {...useChakraSelectProps({
        size: size ?? "md",
        isMulti: false,
        value: selectedValue,
        onChange: (option) => {
          if (option) {
            const value = {
              label: option.label,
              value: option.value,
            };
            setSelectedValue(value);
            onChange(value);
          } else {
            onChange(null);
          }
        },
        isClearable: isClearable ?? false,
        selectedOptionStyle: "check",
        placeholder: placeholder ? <Box color={placeholder.color}>{placeholder.text}</Box> : <Box></Box>,
        noOptionsMessage: (inputValue) => (
          <Box>{inputValue.inputValue.trim().length === 0 ? optionsMessage : matchingOptionsMessage}</Box>
        ),
      })}
      options={options}
      isLoading={isLoading}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
        }),
        container: (provided) => ({
          ...provided,
          width: "full",
        }),
      }}
    />
  );
};
