import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormHelperText, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { ContractDto, ContractEndDateDto } from "../../../../../../autogen/bff-api";
import { TimestampSelector } from "../../../../../../common/input/TimestampSelector/TimestampSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const ExpirationDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const expirationDate = requireExpirationDate(contractState.contract as ContractDto);
  const date = expirationDate.date ?? null;
  const hasNoExpirationDate = expirationDate.hasNoEndDate;
  const errorMessage = containsError(contractState, "EditExpirationDate") ? t("Update failed") : null;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

  useEffect(() => {
    setCheckboxValue(hasNoExpirationDate);
  }, [hasNoExpirationDate]);

  return (
    <FormControl id={"expirationDate"} isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <Box fontWeight={"medium"} pb="2">
        {t("End date")}
        {isLoading && <Spinner ml="5px" size="xs" />}
      </Box>

      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Flex>
          <Flex width="300px">
            <TimestampSelector
              isDisabled={checkboxValue}
              datePlaceholderText={""}
              date={!checkboxValue ? date : null}
              showTimeInput={false}
              errorMessage={errorMessage}
              dateFormat={"dd MMM yyyy"}
              allowPastDates={true}
              onDateChange={async (newDate) => {
                setIsLoading(true);
                await dispatch(
                  editContractThunk({
                    command: {
                      type: "EditExpirationDate",
                      value: {
                        date: newDate === "" ? null : newDate,
                        hasNoExpirationDate: checkboxValue,
                      },
                    },
                  })
                );
                setIsLoading(false);
              }}
            />
          </Flex>
          <Checkbox
            ml="10px"
            isChecked={checkboxValue}
            onChange={async () => {
              const newValue = !checkboxValue;
              setCheckboxValue(newValue);
              setIsLoading(true);
              await dispatch(
                editContractThunk({
                  command: {
                    type: "EditExpirationDate",
                    value: {
                      date: date,
                      hasNoExpirationDate: newValue,
                    },
                  },
                })
              );
              setIsLoading(false);
            }}
          >
            {t("Contract has no end date")}
          </Checkbox>
        </Flex>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide an end date")}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export const requireExpirationDate = (contract: ContractDto): ContractEndDateDto => {
  if (!contract.dataFields?.endDate) {
    throw new Error("should never happen");
  }

  return contract.dataFields.endDate;
};
