import { Box, Button, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";

interface Props {
  title: string;
  description: string;
  iconType: IconType;
  buttonTitle: string;
  onClick: () => void;
}

export const LinkItem = ({ title, description, iconType, buttonTitle, onClick }: Props) => {
  return (
    <Box
      my="2.5"
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius="10px"
      shadow="md"
      padding="4"
      backgroundColor="smBackground"
    >
      <Grid
        gridTemplateColumns="35px 1fr"
        gridTemplateRows="minmax(35px, auto) minmax(70px, auto) minmax(40px, auto)"
        gridTemplateAreas={`
                "icon title"
                "unused1 info"
                "unused2 button"
                `}
      >
        <GridItem area="icon">
          <Box
            bg="blue.400"
            h="35px"
            borderRadius={"10px"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Icon as={iconType} w="15px" h="15px" color={"white"} />
          </Box>
        </GridItem>
        <GridItem area="title" display={"flex"} alignItems="start">
          <Text fontSize="xl" fontWeight={"semibold"} ml="10px">
            {title}
          </Text>
        </GridItem>
        <GridItem area="info" display={"flex"} alignItems="center">
          <Text fontSize="xs" color="#8390a3" ml="10px">
            {description}
          </Text>
        </GridItem>
        <GridItem area="button">
          <Flex w="100%" h="100%" justifyContent={"end"} alignItems="flex-end">
            <Button size="sm" variant={"solid"} colorScheme="blue" w="100px" onClick={onClick}>
              {buttonTitle}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
