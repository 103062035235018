import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect } from "react";
import { UploadDto, bffApi } from "../../../autogen/bff-api";
import { requireStringEnvVar } from "../../../config";
import { useApiError } from "../../errors/useApiError";
import { getPusher } from "../../pusher";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadBasicBidThunk } from "../../redux/thunks/basic-bid/load-basic-bid-thunk";
import { loadBseThunk } from "../../redux/thunks/basic-sourcing-event/load-bse-thunk";
import { loadContractThunk } from "../../redux/thunks/contract/load-contract-thunk";

export type DocumentUploadContext = "SourcingEvent" | "Bid" | "Contract";

export const useDocumentsUploader = (props: { entityType: DocumentUploadContext; entityId: string }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((e) => e);
  const displayer = useApiError();

  const documentAddedHandler = useCallback(async (): Promise<boolean> => {
    switch (props.entityType) {
      case "Bid":
        if (!state.basicBid.bid?.id) throw Error("No bid state - could not handle document");
        dispatch(loadBasicBidThunk({ bidId: state.basicBid.bid.id }));
        return true;
      case "Contract":
        if (!state.contract.state?.id) throw Error("No contract state - could not handle document");
        dispatch(loadContractThunk({ contractId: state.contract.state.id }));
        return true;
      case "SourcingEvent":
        if (!state.basicSourcingEvent.state?.id) throw Error("No sourcing event state - could not handle document");
        dispatch(loadBseThunk({ eventId: state.basicSourcingEvent.state.id }));
        return true;
    }
  }, [
    dispatch,
    props.entityType,
    state.basicBid.bid?.id,
    state.basicSourcingEvent.state?.id,
    state.contract.state?.id,
  ]);

  useEffect(() => {
    const channel = getPusher().subscribe(props.entityId);
    channel.bind("document-added", documentAddedHandler);

    return () => {
      channel.unbind("document-added", documentAddedHandler);
    };
  }, [documentAddedHandler, props.entityId]);

  const createPostRequest = async (file: {
    id: string;
    name: string;
    extension: string;
    file: File;
  }): Promise<{ data: UploadDto } | { error: FetchBaseQueryError | SerializedError }> => {
    switch (props.entityType) {
      case "Bid": {
        const response = dispatch(
          bffApi.endpoints.createUploadForBseBid.initiate({
            bidId: props.entityId,
            uploadId: file.id,
            createUploadRequestDto: {
              fileName: file.name,
              fileSize: file.file.size,
              fileExtension: file.extension,
              mimeType: file.file.type,
            },
          })
        );
        response.reset();
        return await response;
      }
      case "Contract": {
        const response = dispatch(
          bffApi.endpoints.createUploadForContract.initiate({
            contractId: props.entityId,
            uploadId: file.id,
            createUploadRequestDto: {
              fileName: file.name,
              fileSize: file.file.size,
              fileExtension: file.extension,
              mimeType: file.file.type,
            },
          })
        );
        response.reset();
        return await response;
      }
      case "SourcingEvent": {
        const response = dispatch(
          bffApi.endpoints.createUploadForBse.initiate({
            eventId: props.entityId,
            uploadId: file.id,
            createUploadRequestDto: {
              fileName: file.name,
              fileSize: file.file.size,
              fileExtension: file.extension,
              mimeType: file.file.type,
            },
          })
        );
        response.reset();
        return await response;
      }
    }
  };

  const upload = async (file: { id: string; name: string; extension: string; file: File }): Promise<string | null> => {
    const postRequestResult = await createPostRequest(file);

    if ("data" in postRequestResult) {
      const data = postRequestResult.data;
      const form = new FormData();

      form.append("x-amz-date", data.renamedXAmzDate);
      form.append("x-amz-signature", data.renamedXAmzSignature);
      form.append("x-amz-algorithm", data.renamedXAmzAlgorithm);
      form.append("x-amz-credential", data.renamedXAmzCredential);
      form.append("policy", data.policy);
      form.append("key", data.key);
      form.append("file", file.file);

      const response = await fetch(requireStringEnvVar("VITE_S3_BASE_URL"), {
        method: "POST",
        body: form,
      });

      if (!response.ok) {
        displayer.trigger({
          message: `could not perform upload, response: ${response.body}`,
        });
      }

      return data.key;
    } else {
      displayer.trigger(postRequestResult.error);
      return null;
    }
  };

  return {
    upload,
    createPostRequest,
  };
};
