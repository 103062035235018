import { Search2Icon } from "@chakra-ui/icons";
import { Box, Flex, Grid, GridItem, Heading, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetContractStatisticsQuery,
  useListContractTemplatesQuery,
  useListContractsQuery,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { PublishedContractsTable } from "./PublishedContractsTable";
import { ContractFilters } from "./filtering/ContractFilters";
import { MultiSelectOption } from "./filtering/MultiSelectFilter";

export const PublishedContracts = () => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();
  const [selectedOwners, setSelectedOwners] = useState<MultiSelectOption[]>([]);
  const [selectedCounterparties, setSelectedCounterparties] = useState<MultiSelectOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<MultiSelectOption[]>([
    { label: t("Active"), value: "Active" },
  ]);
  const [selectedTypes, setSelectedTypes] = useState<MultiSelectOption[]>([]);
  const [selectedInternalParties, setSelectedInternalParties] = useState<MultiSelectOption[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<MultiSelectOption[]>([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const {
    data,
    error,
    isLoading: isLoadingContracts,
    isFetching,
  } = useListContractsQuery({
    orgId: authState.selectedOrg.id,
    title: debouncedSearchString,
    state: "Published",
    statuses: selectedStatuses.map((e) => e.value).join(","),
    owners: selectedOwners.map((e) => e.value).join(","),
    counterparties: selectedCounterparties.map((e) => e.value).join(","),
    templates: selectedTypes.map((e) => e.value).join(","),
    internalparties: selectedInternalParties.map((e) => e.value).join(","),
    projects: selectedProjects.map((p) => p.value).join(","),
    limit,
    skip,
  });
  const { data: stats } = useGetContractStatisticsQuery({
    orgId: authState.selectedOrg.id,
    organizationId: authState.selectedOrg.id,
  });
  const { data: templateData } = useListContractTemplatesQuery({
    orgId: authState.selectedOrg.id,
    organizationId: authState.selectedOrg.id,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchString = useCallback(
    debounce((searchString) => setDebouncedSearchString(searchString), 500),
    [setDebouncedSearchString]
  );

  if (error) {
    return displayer.show(error);
  }

  const isLoading = isLoadingContracts || isFetching;

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"} px="2">
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"auto 1fr"}>
            <GridItem display="flex" justifyContent={"space-between"}>
              <Box>
                <Heading as="h2" size="lg" mt="10px">
                  {t("All contracts")}
                </Heading>
                <Box mt="5px">
                  <Text fontSize="medium" color="smMuted" mb="10px">
                    {t("All contracts that you have access to")}
                  </Text>
                </Box>
              </Box>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p="4"
                  rounded="lg"
                  backgroundColor="smBackgroundSecondary"
                  mr="2"
                >
                  <Text fontSize={"sm"}>{t("All contracts")}</Text>
                  <Text fontWeight={"bold"} fontSize={"sm"}>
                    {stats?.numberOfContracts}
                  </Text>
                </Flex>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p="4"
                  rounded="lg"
                  backgroundColor="smBackgroundSecondary"
                >
                  <Text fontSize={"sm"}>{t("Active contracts")}</Text>
                  <Text fontWeight={"bold"} fontSize={"sm"}>
                    {stats?.numberOfActiveContracts}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
            {data && templateData && (
              <GridItem width={"full"} maxWidth={"full"} overflow={"hidden"} pb="2">
                <Box mt="15px" mb="10px">
                  <Flex w="100%">
                    <InputGroup mr="5px" size="sm">
                      <InputLeftElement pointerEvents="none">
                        <Search2Icon />
                      </InputLeftElement>
                      <Input
                        type="text"
                        size="sm"
                        placeholder={t("Contract title") ?? ""}
                        rounded="md"
                        value={searchString}
                        onChange={(e) => {
                          setSearchString(e.target.value);
                          handleDebouncedSearchString(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  <Flex mt="15px" w="100%">
                    <ContractFilters
                      selectedCounterparties={selectedCounterparties}
                      onCounterpartsChange={(e) => {
                        setSelectedCounterparties(e);
                        setSkip(0);
                      }}
                      selectedOwners={selectedOwners}
                      onOwnersChange={(e) => {
                        setSelectedOwners(e);
                        setSkip(0);
                      }}
                      selectedStatuses={selectedStatuses}
                      onStatusesChange={(e) => {
                        setSelectedStatuses(e);
                        setSkip(0);
                      }}
                      selectedTypes={selectedTypes}
                      onTypesChange={(e) => {
                        setSelectedTypes(e);
                        setSkip(0);
                      }}
                      templates={templateData.templates}
                      selectedInternalParties={selectedInternalParties}
                      onInternalPartiesChange={(value) => {
                        setSelectedInternalParties(value);
                        setSkip(0);
                      }}
                      selectedProjects={selectedProjects}
                      onProjectsChange={(value) => {
                        setSelectedProjects(value);
                        setSkip(0);
                      }}
                    />
                  </Flex>
                </Box>
                <PublishedContractsTable
                  contracts={data.contracts}
                  isLoading={isLoading}
                  limit={limit}
                  skip={skip}
                  setSkip={setSkip}
                />
              </GridItem>
            )}
            {(!data || !templateData) && !isLoading && <Text>{t("Something went wrong")}...</Text>}
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
