import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaMapMarker } from "react-icons/fa";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { requireCounterparty } from "../../typeguards";
import { getCounterpartyCountry, getCounterpartyName } from "../../view-all-published/PublishedContractRow";

interface Props {
  data: ContractState["contract"];
}

export const Counterparty = ({ data }: Props) => {
  const { t } = useTranslation();

  const counterparty = requireCounterparty(data);
  const name = getCounterpartyName(counterparty);
  const country = getCounterpartyCountry(counterparty);

  return (
    <Box padding="15px" w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="smMuted">
        {t("Counterparty")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      <Flex mt="13px">
        <Flex>
          <ProfileImageRounded name={name} width="30px" backgroundColor="blue.500" fontSize={"xs"} />
        </Flex>
        <Flex justifyContent={"center"} mt="-1px" ml="7px">
          <Text fontSize={"xl"} fontWeight="semibold">
            {name}
          </Text>
        </Flex>
      </Flex>
      <Flex mt="15px">
        <Icon as={FaMapMarker} ml="6px" w="15px" h="15px" color={"smMuted"} />
        <Text fontSize={"sm"} color="smMuted" ml="17px" mt="-3px">
          {country.name}
        </Text>
      </Flex>
    </Box>
  );
};
