import { Box, Grid, GridItem, Image, Text, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { assets } from "../../assets/assets";
import styles from "./Footer.module.css";
import { FooterLinks } from "./FooterLinks";

export const Footer = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const theme = useColorMode();

  return (
    <Grid h="100%" paddingTop={"50px"} className={styles.grid} zIndex={"0"}>
      <GridItem area="copyright" mt={isLargerThan768 ? "0px" : "30px"}>
        <Image src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo} alt="Logo" h="30px" mt="10px" />
        <br />
        <Box mr="50px">
          <Text fontSize="xs" color="smMuted">
            Systematic scraping, storing, indexing, distribution and all other forms of aggregation of the data on this
            page is not allowed without explicit and written consent from SourceMagnet AS.
          </Text>
          <br />
          <Text fontSize="xs" color="smMuted">
            © 2023 SourceMagnet AS
          </Text>
        </Box>
      </GridItem>
      <GridItem area="links">
        <FooterLinks />
      </GridItem>
    </Grid>
  );
};
