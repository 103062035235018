import { Box } from "@chakra-ui/react";
import { UppyUploader } from "../../../../common/documents/UppyUploader/UppyUploader";
import { useBasicBidState } from "../../../sourcing-events/useBasicBidEventState";

export const BasicBidDocumentUploader = () => {
  const bidState = useBasicBidState();

  return (
    <Box pb="5">
      <UppyUploader
        uploadEntityType={"Bid"}
        entityId={bidState.id}
        documents={bidState.documents.value}
        uploads={bidState.uploads.value}
        isRequired={false}
      />
    </Box>
  );
};
