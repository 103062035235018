import { Tag } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "react-transition-group";

interface Props {
  timestamp: string;
  size?: "sm" | "md" | "lg";
}

const transitionStyles = {
  entering: { color: "white" },
  exiting: { color: "white" },
  unmounted: { color: "white" },
  entered: { color: "teal" },
  exited: { color: "teal" },
};

const duration = 400;

const defaultStyle = {
  transition: `color ${duration}ms ease-in-out`,
  color: "teal",
};

export const LastChangedTag = ({ timestamp, size }: Props) => {
  const [inProp, setInProp] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState<string>();
  const m = moment(timestamp);
  const isSameDay = moment().isSame(m, "day");
  const nodeRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentTimestamp(timestamp);
    if (currentTimestamp && currentTimestamp !== timestamp) {
      setInProp((e) => !e);
    }
  }, [timestamp]);

  if (!currentTimestamp) {
    return null;
  }
  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {(state) => (
        <Tag
          variant="outline"
          colorScheme={"cyan"}
          mr="10px"
          size={size}
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {t("Last updated")} {m.format("HH:mm:ss")} {!isSameDay && `${t("the")} ${m.format("DD MMM YYYY")}`}
        </Tag>
      )}
    </Transition>
  );
};
