import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRobot } from "react-icons/fa";
import { useListContractTemplatesQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../common/subscription/useSub";
import { CreateContractForm } from "./CreateContractForm";
import { CreateContractWithAIForm } from "./CreateContractWithAIForm";

interface Props {
  onClose: () => void;
}

type FormType = "manual" | "ai";

export const CreateContractModal = ({ onClose }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  const sub = useSub();
  const [formType, setFormType] = useState<FormType>("manual");

  const { data: templatesRes } = useListContractTemplatesQuery({
    organizationId: authState.selectedOrg.id,
    orgId: authState.selectedOrg.id,
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("New contract")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {templatesRes ? (
            <>
              {sub.hasAiAccess && (
                <Flex width="full" justifyContent={"center"}>
                  <Flex>
                    <Button
                      roundedRight="none"
                      variant={formType === "manual" ? "solid" : "ghost"}
                      colorScheme={"teal"}
                      rounded={"md"}
                      border="1px solid teal"
                      onClick={() => setFormType("manual")}
                    >
                      {t("Manual")}
                    </Button>
                    <Button
                      roundedLeft="none"
                      variant={formType === "ai" ? "solid" : "ghost"}
                      overflow={"hidden"}
                      colorScheme={"teal"}
                      rounded={"md"}
                      border="1px solid teal"
                      borderLeft={"none"}
                      rightIcon={<Icon as={FaRobot} />}
                      onClick={() => setFormType("ai")}
                    >
                      {t("AI")}
                    </Button>
                  </Flex>
                </Flex>
              )}
              {formType === "manual" && <CreateContractForm templates={templatesRes.templates} />}
              {formType === "ai" && <CreateContractWithAIForm templates={templatesRes.templates} />}
            </>
          ) : (
            <Box padding="20px">{t("Loading...")}</Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
