import { Box } from "@chakra-ui/react";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { BseBidDto, BseSupplierViewDto, bffApi } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { EditBidDraftButton } from "../../../bids/view-all/EditBidDraftButton";
import { StatusBanner } from "./StatusBanner";
import { CreateDraftButton } from "./buttons/CreateDraftButton";
import { EditDeliveredBidButton } from "./buttons/EditDeliveredBidButton";
import { ViewBidButton } from "./buttons/ViewBidButton";

interface Props {
  event: BseSupplierViewDto;
  refetch: () => void;
}

export const BseBid = ({ event, refetch }: Props) => {
  const [createdBid, setCreatedBid] = useState<BseBidDto>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const displayer = useApiError();

  useEffect(() => {
    const run = async () => {
      if (event.createdBidId) {
        const response = dispatch(bffApi.endpoints.getBseBid.initiate({ bidId: event.createdBidId }));
        response.unsubscribe();
        const result = await response;
        if ("data" in result && result.data) setCreatedBid(result.data);
        if (result.isError) displayer.trigger(result.error);
      }
    };
    run();
  }, [dispatch, displayer, event]);

  if (event.createdBidId && !createdBid) return <div>Loading...</div>;

  switch (event.status) {
    case "DeadlinePassedAndBidDelivered":
      if (!createdBid) throw Error("In deadline passed and bid delivered state, but no bid found.");
      return (
        <StatusBanner
          text={`${t("Your bid is delivered")}! ${t("The deadline has now passed")}.`}
          border="teal 1px solid"
          borderColor="teal.100"
          color="teal"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "BidDelivered":
      if (!createdBid) throw Error("In bid delivered state, but no bid found.");
      return (
        <StatusBanner text={t("Your bid is delivered!")} border="1px solid" borderColor="teal.100" color="teal">
          {!deadlineHasPassed(moment(event.deadline)) && <EditDeliveredBidButton bid={createdBid} />}
          <Box width="5px"></Box>
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "DraftCreated":
      if (!createdBid) throw Error("In draft created state, but no draft found.");
      return (
        <StatusBanner text={t("A draft has been created")} border="teal 1px solid" borderColor="teal.100" color="teal">
          <EditBidDraftButton bid={createdBid} size="md" />
        </StatusBanner>
      );
    case "InviteAccepted":
      return (
        <StatusBanner
          text={t("Do you intend to deliver a bid?")}
          border="teal 1px solid"
          borderColor="teal.100"
          color="teal"
        >
          <CreateDraftButton event={event} shouldAcceptInvite={false} refetch={refetch} />
        </StatusBanner>
      );
    case "DeadlinePassedWithoutBidDelivered":
      return <StatusBanner text={t("Deadline has passed, no bid was delivered")} backgroundColor="gray.400" />;
    case "InviteNotAccepted":
      return (
        <StatusBanner
          text={t("Do you intend to deliver a bid?")}
          border="teal 1px solid"
          borderColor="teal.100"
          color="teal"
        >
          <CreateDraftButton event={event} shouldAcceptInvite={true} refetch={refetch} />
        </StatusBanner>
      );
    case "IsLoser":
      if (!createdBid) throw Error("In IsLoser state, but no bid found.");
      return (
        <StatusBanner text={t("You lost this event")} border="teal 1px solid" borderColor="teal.100" color="teal">
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "IsWinner":
      if (!createdBid) throw Error("In IsWinner state, but no bid found.");
      return (
        <StatusBanner
          icon={FaCheckCircle}
          text={t("You won this event!")}
          border="teal 1px solid"
          borderColor="teal.100"
          color="teal"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
  }
};

const deadlineHasPassed = (deadline: Moment): boolean => {
  return moment().isAfter(deadline);
};
