import { Box, Button, Flex, Heading, Icon, Modal, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FaRobot } from "react-icons/fa";
import { AnalyzeDocumentApiResponse, useAnalyzeDocumentMutation } from "../../../../autogen/bff-api";
import { TextAreaInput } from "../../../../common/input/TextAreaInput/TextAreaInput";

const summaryPrompt = `Generate a short summary of the following document.`;

export const DocumentAnalysisModal = ({
  docId,
  setDocumentIdForAnalysis,
}: {
  docId: string;
  setDocumentIdForAnalysis: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [analyzeDocument, { status }] = useAnalyzeDocumentMutation();

  const [prompt, setPrompt] = useState<string>("");
  const [analysis, setAnalysis] = useState<AnalyzeDocumentApiResponse["analysis"]>();

  const handleClick = async () => {
    const response = await analyzeDocument({
      docId,
      analyzeDocumentRequest: {
        prompt,
      },
    });
    if ("data" in response) setAnalysis(response.data.analysis);
    else console.error("Failed to analyze document");
  };

  return (
    <Modal size={"2xl"} isOpen={!!docId} onClose={() => setDocumentIdForAnalysis(undefined)}>
      <ModalOverlay />
      <ModalContent maxHeight={"80%"} height={"80%"} p="5">
        <Flex flexDir={"column"} p={"4"} rounded={"base"} overflow={"hidden"}>
          <Text fontWeight={"bold"} pb={"4"}>
            <Icon mr={"2"} as={FaRobot} />
            {t("Document Analysis")}
          </Text>
          <Flex pb="4">
            <Button
              colorScheme="teal"
              onClick={() => {
                setPrompt(summaryPrompt);
                handleClick();
              }}
            >
              {t("Generate Summary")}...
            </Button>
          </Flex>
          <TextAreaInput
            isLoading={status === "pending"}
            label={`...${t("or ask the AI anything about the document")}:`}
            name="promptInput"
            helperText=""
            errorMessage={undefined}
            value={prompt}
            onChange={(text) => setPrompt(text)}
          />
          <Box width={"full"} textAlign={"right"}>
            <Button colorScheme="purple" onClick={handleClick}>
              {t("Analyze")}
            </Button>
          </Box>
          {analysis && (
            <>
              <Heading as="h2" size="sm" fontWeight={"bold"} color="teal" textAlign={"center"} pb="5">
                {t("Reply from the SourceMagnet AI")}
              </Heading>
              <Box overflow={"auto"} width={"full"} bgColor="smBackgroundSecondary" rounded="lg">
                <Text whiteSpace={"pre-line"} p="5">
                  {analysis?.choices?.[0]?.message?.content}
                </Text>
                {analysis.error && (
                  <Text whiteSpace={"pre-line"} p="5">
                    {analysis.error.message}
                  </Text>
                )}
              </Box>
            </>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};
