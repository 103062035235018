import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { ContractTaskDto } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { displayWrappedWithEllipsis } from "../../../common/formatting/displayWrappedWithEllipsis";
import { ContractTaskStatusTag } from "../../contracts/view-single/tasks/ContractTaskStatusTag";
import { ShowTaskModal } from "../../contracts/view-single/tasks/show";

interface Props {
  task: ContractTaskDto;
}

export const Task = ({ task }: Props) => {
  const [showTaskId, setShowTaskId] = useState<string>();
  return (
    <>
      {showTaskId && <ShowTaskModal taskId={showTaskId} onClose={() => setShowTaskId(undefined)} />}
      <Flex
        key={task.id}
        w="100%"
        borderRadius={"6px"}
        mt="10px"
        padding={"5px"}
        backgroundColor={"smBackground"}
        shadow={"md"}
        _hover={{ shadow: "sm", cursor: "pointer" }}
        _active={{ shadow: "inner", cursor: "pointer" }}
        onClick={() => setShowTaskId(task.id)}
      >
        <Grid
          w="full"
          gridTemplateAreas={`
                "title status"
                "sub status"
                `}
          gridTemplateColumns={"1fr 100px"}
        >
          <GridItem area={"title"}>
            <Flex flex={3} alignItems={"center"} paddingLeft="5px">
              <Text fontSize={"sm"} color="smMuted">
                {displayWrappedWithEllipsis(task.title, 30)}
              </Text>
            </Flex>
          </GridItem>
          <GridItem area={"sub"}>
            <Flex flex={3} alignItems={"center"} paddingLeft="5px">
              <Text fontSize={"xs"} fontWeight={"light"} color="smMuted">
                {displayDate(task.dueDate)}
              </Text>
            </Flex>
          </GridItem>
          <GridItem area={"status"} display="flex" alignItems={"center"} justifyContent="end" paddingRight={"5px"}>
            <ContractTaskStatusTag status={task.status} />
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};
