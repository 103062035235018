import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { ProductTemplateFieldDto } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { createProductGroupThunk } from "../../../../../common/redux/thunks/product/createProductGroupThunk";
import { ProductField, ProductGroup, ProductGroupDraft, ProductTemplateField } from "../../../../../common/types";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { ProductFieldTable } from "./ProductFieldTable";
import { ProductGroupPreview } from "./ProductGroupPreview";

const initializeField = (populatedBy: "Supplier" | "Buyer"): ProductField => ({
  id: uuid(),
  name: "",
  populatedBy,
  type: "Text",
  isRequired: false,
});

const initializeGroup = (defaultBuyerFields: ProductTemplateField[], sourcingEventId: string): ProductGroupDraft => ({
  sourcingEventId,
  name: "",
  products: [],
  productFields: defaultBuyerFields,
});

const isGroupNameValid = (group: ProductGroupDraft) => !!group.name;
const isBuyerFieldsValid = (group: ProductGroupDraft) =>
  !!group.productFields.filter((field) => field.populatedBy === "Buyer").length &&
  group.productFields.filter((field) => field.populatedBy === "Buyer").every((field) => !!field.name);
const isSupplierFieldsValid = (group: ProductGroupDraft) =>
  group.productFields.filter((field) => field.populatedBy === "Supplier").every((field) => !!field.name);

export const ProductTemplateModal = ({
  isOpen,
  setIsOpen,
  productGroupDraft,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  productGroupDraft?: ProductGroup;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const confirmationModal = useConfirmationModal();

  const sourcingEvent = useBasicSourcingEventState();

  const defaultBuyerFields: ProductField[] = useMemo(
    () => [
      { id: uuid(), name: t("Name"), type: "Text", populatedBy: "Buyer", isRequired: true },
      { id: uuid(), name: t("Unit"), type: "Text", populatedBy: "Buyer", isRequired: false },
    ],
    []
  );

  const [group, setGroup] = useState<ProductGroupDraft>(initializeGroup(defaultBuyerFields, sourcingEvent.id));

  useEffect(() => {
    if (productGroupDraft) setGroup(productGroupDraft);
  }, [productGroupDraft]);

  const validateProductGroup = useCallback(() => {
    if (!isGroupNameValid(group)) {
      toast({
        description: t("Looks like you forgot to add a product group title"),
        variant: "subtle",
        status: "info",
        colorScheme: "gray",
      });
    }
    if (!isBuyerFieldsValid(group)) {
      toast({
        description: t("Invalid buyer fields"),
        variant: "subtle",
        status: "info",
        colorScheme: "gray",
      });
    }
    if (!isSupplierFieldsValid(group)) {
      toast({
        description: t("Invalid supplier fields"),
        variant: "subtle",
        status: "info",
        colorScheme: "gray",
      });
    }
    return isGroupNameValid(group) && isBuyerFieldsValid(group) && isSupplierFieldsValid(group);
  }, [group, toast]);

  const saveProductGroup = useCallback(async () => {
    if (!validateProductGroup()) return;
    const res = await dispatch(
      createProductGroupThunk({
        sourcingEventId: sourcingEvent.id,
        name: group.name,
        productFields: group.productFields
          .filter((field) => field.type !== "Quantity")
          .map((field) => ({
            id: field.id,
            name: field.name,
            type: field.type as ProductTemplateFieldDto["type"],
            populatedBy: field.populatedBy,
            isRequired: false,
          })),
        products:
          group.products?.map((p) => ({
            quantity: p.quantity,
            productFields: p.productFields.map((field) => ({
              ...field,
              value: field.value ?? undefined,
            })),
          })) ?? [],
      })
    );
    if ("error" in (res.payload as Record<string, unknown>) || !("data" in (res.payload as Record<string, unknown>))) {
      toast({
        description: `${t("Sorry, something went wrong")}...`,
        status: "error",
        variant: "subtle",
        colorScheme: "gray",
      });
      return;
    }
    toast({
      description: group.products?.length
        ? `${t("Product group with")} ${group.products?.length} ${t("products created")}!`
        : `${t("Empty product group created")}!`,
      variant: "subtle",
      status: "success",
    });
    setGroup(initializeGroup(defaultBuyerFields, sourcingEvent.id));
    setIsOpen(false);
  }, [dispatch, group, setIsOpen, sourcingEvent.id, toast, validateProductGroup, defaultBuyerFields]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setGroup(initializeGroup(defaultBuyerFields, sourcingEvent.id));
        setIsOpen(false);
      }}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent width="full" height={"80%"} maxHeight={"80%"} padding="5">
        {confirmationModal.modal}
        <ModalCloseButton />
        <ModalBody display={"flex"} flexDirection={"column"} width="full" height="full" maxHeight={"full"}>
          <FormControl p="4">
            <Input
              type="text"
              placeholder={`${t("Product group name") ?? ""}...`}
              value={group.name ?? ""}
              onChange={(e) => setGroup((group) => ({ ...group, name: e.target.value }))}
            />
            <FormHelperText>{t("Enter a name describing the product group")}</FormHelperText>
          </FormControl>
          <Flex flexDir={"column"} flexGrow={1} overflowY={"auto"} minHeight={0}>
            <Flex flexDirection={"column"} backgroundColor="smBackgroundSecondary" rounded={"xl"}>
              {group.products?.length && <ProductGroupPreview group={group} />}
              <Heading size="sm" fontWeight={"bold"} p="5">
                {t("Information from buyer")}
              </Heading>
              <Box backgroundColor="smBackgroundSecondary" p="4" rounded="lg">
                <ProductFieldTable
                  group={group}
                  populatedBy="Buyer"
                  setGroup={setGroup}
                  confirmationModal={confirmationModal}
                />
                <Button
                  mr="5px"
                  variant={"outline"}
                  colorScheme={"teal"}
                  size={"xs"}
                  ml="5"
                  mt="4"
                  onClick={() =>
                    setGroup({
                      ...group,
                      productFields: [
                        ...group.productFields.filter((f) => f.populatedBy === "Buyer"),
                        initializeField("Buyer"),
                        ...group.productFields.filter((f) => f.populatedBy === "Supplier"),
                      ],
                    })
                  }
                >
                  {t("Add product field")} <Icon as={FaPlus} ml="2" />
                </Button>
              </Box>
              <Heading size="sm" fontWeight={"bold"} p="5">
                {t("Information from supplier")}
              </Heading>
              <Box backgroundColor="smBackgroundSecondary" p="4" rounded="lg">
                <ProductFieldTable
                  group={group}
                  populatedBy="Supplier"
                  setGroup={setGroup}
                  confirmationModal={confirmationModal}
                />
                <Button
                  mr="5px"
                  variant={"outline"}
                  colorScheme={"teal"}
                  size={"xs"}
                  ml="5"
                  mt="4"
                  onClick={() =>
                    setGroup({
                      ...group,
                      productFields: [...group.productFields, initializeField("Supplier")],
                    })
                  }
                >
                  {t("Add product field")} <Icon as={FaPlus} ml="2" />
                </Button>
              </Box>
            </Flex>
          </Flex>
          <Box pt="8" flexGrow={1}>
            <Button float={"right"} variant={"outline"} colorScheme={"teal"} size={"sm"} onClick={saveProductGroup}>
              {t("Add to Sourcing Event")} <Icon as={FaPlus} ml="2" />
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
