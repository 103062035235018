import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CounterpartyDto, CounterpartyInputDto } from "../../../../../autogen/bff-api";
import { OrganizationSelector } from "../../../../../common/input/Selector/OrganizationSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { OrganizationCombination, useOrganizationSearch } from "../../../../organizations/search/useOrganizationSearch";
import { useContractState } from "../../useContractState";
import { CreateOrganizationEntryModal } from "../create-entry/CreateOrganizationEntryModal";

export const Counterparty: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const [value, setValue] = useState<OrganizationCombination | null>(null);
  const [showCreateEntryModal, setShowCreateEntryModal] = useState(false);
  const errorMessage = containsError(contractState, "EditCounterparty") ? t("Update failed") : null;

  const [isLoading, setIsLoading] = useState(false);
  const organizationSearch = useOrganizationSearch({
    excludedOrgId: contractState.contract.owningOrganizationId,
  });
  const isRequiredButNotProvided = !contractState.contract.counterparty;

  useEffect(() => {
    setValue(
      contractState.contract.counterparty ? toOrganizationCombination(contractState.contract.counterparty) : null
    );
  }, []);

  const update = async (value: OrganizationCombination) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditCounterparty",
          value: toCounterpartInputDto(value),
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <>
      {showCreateEntryModal && (
        <CreateOrganizationEntryModal
          onConfirm={async (e) => {
            organizationSearch.reload();
            update({
              type: "OrganizationEntry",
              content: e,
            });
            setValue({
              type: "OrganizationEntry",
              content: e,
            });
          }}
          onClose={() => setShowCreateEntryModal(false)}
        />
      )}
      <FormControl id="counterparty" isRequired={isRequiredButNotProvided} isInvalid={errorMessage !== null}>
        <FormLabel>
          {t("Counterparty")} {isLoading && <Spinner size="xs" />}
        </FormLabel>
        <OrganizationSelector
          placeholderText={t("Select counterparty")}
          options={organizationSearch.organizationCombinations ?? []}
          value={value}
          onChange={(value) => {
            setValue(value);
            update(value);
          }}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
        <FormHelperText>
          {t("Please select an existing company, or")}{" "}
          <Button
            variant={"link"}
            colorScheme="blue"
            fontWeight={"bold"}
            fontSize="sm"
            onClick={() => {
              setShowCreateEntryModal(true);
            }}
          >
            {t("create a new company entry")}
          </Button>
        </FormHelperText>
      </FormControl>
    </>
  );
};

export const toOrganizationCombination = (value: CounterpartyDto): OrganizationCombination => {
  if (value.organization) return { type: "Organization", content: value.organization };
  if (value.organizationEntry) return { type: "OrganizationEntry", content: value.organizationEntry };
  throw Error("Neither organization nor organization entry defined.");
};

const toCounterpartInputDto = (value: OrganizationCombination): CounterpartyInputDto => {
  switch (value.type) {
    case "Organization":
      return { organizationId: value.content.id };
    case "OrganizationEntry":
      return { organizationEntryId: value.content.id };
  }
};
