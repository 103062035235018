import {
    BackgroundProps,
    Flex,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
} from '@chakra-ui/react'
import { Size } from 'chakra-react-select'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useBaseData } from '../../../common/useBaseData'

export interface PhoneNumberInput {
    country: {
        name: string
        alpha3Code: string
        callingCode: string
    } | null
    number: string
}

interface Props {
    onChange: (phoneNumber: PhoneNumberInput | null) => void
    onClear: () => void
    value: PhoneNumberInput | null
    size?: Size
    bg?: BackgroundProps['bg']
    isDisabled?: boolean
}

export const PhoneNumberInput: React.FC<Props> = ({
    onChange,
    onClear,
    value,
    size,
    bg,
    isDisabled,
}) => {
    const baseData = useBaseData()
    const { t } = useTranslation()
    const countryAlpha3Codes = baseData.countries.map((e) => e.alpha3Code)
    const callingCodes = baseData.callingCodes.filter(
        (e) => countryAlpha3Codes.indexOf(e.countryAlpha3Code) !== -1
    )

    return (
        <Flex flexDirection={'row'} w="100%">
            <Flex w="200px">
                <Select
                    bg={bg}
                    size={size}
                    placeholder={t('Select country') ?? ''}
                    value={
                        value?.country
                            ? value.country.alpha3Code +
                              value.country.callingCode
                            : ''
                    }
                    isDisabled={isDisabled}
                    onChange={(e) => {
                        if (
                            e.target.value !== undefined &&
                            e.target.value !== ''
                        ) {
                            const alpha3Code = e.target.value.substring(0, 3)
                            const callingCode = e.target.value.substring(3, 100)

                            onChange({
                                country: {
                                    name: baseData.countries.filter(
                                        (e) => e.alpha3Code === alpha3Code
                                    )[0].name,
                                    alpha3Code,
                                    callingCode,
                                },
                                number: value?.number ?? '',
                            })
                        } else {
                            onChange({
                                country: null,
                                number: value?.number ?? '',
                            })
                        }
                    }}
                >
                    {callingCodes.map((e) => {
                        const key = e.countryAlpha3Code + e.value

                        return (
                            <option key={key} value={key}>
                                {e.countryName}
                            </option>
                        )
                    })}
                </Select>
            </Flex>
            <Flex flex={1} ml="5px">
                <InputGroup size={size} bg={bg}>
                    <InputLeftAddon color={isDisabled ? '#c9cfd6' : undefined}>
                        +{value?.country?.callingCode ?? ''}
                    </InputLeftAddon>
                    <Input
                        size={size}
                        id={'phoneNumber'}
                        placeholder={t('Phone number') ?? ''}
                        type="tel"
                        value={value?.number ?? ''}
                        isDisabled={isDisabled}
                        onChange={(e) => {
                            onChange({
                                country: value?.country ?? null,
                                number: e.target.value,
                            })
                        }}
                    />
                </InputGroup>
            </Flex>
            {value !== null && (
                <Flex w={'50px'} ml="5px">
                    <IconButton
                        size={size}
                        aria-label="clear"
                        icon={<Icon as={FaTimes} w="15px" h="15px" />}
                        variant={'solid'}
                        colorScheme="gray"
                        w="100%"
                        onClick={() => {
                            onChange(null)
                            onClear()
                        }}
                    ></IconButton>
                </Flex>
            )}
        </Flex>
    )
}
