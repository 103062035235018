import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TimestampSelector } from "../../../../../../common/input/TimestampSelector/TimestampSelector";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  id: string;
  title: string;
  placeHolderText: string;
  helperText: string;
  date: string | null;
  errorMessage: string | null;
  onDateChange: (newDate: string) => Promise<void> | void;
  removeDataField?: () => Promise<void> | void;
  isRequiredButNotProvided: boolean;
  isDisabled?: boolean;
}

export const GenericDateField = ({
  id,
  title,
  placeHolderText,
  helperText,
  date,
  errorMessage,
  onDateChange,
  removeDataField,
  isRequiredButNotProvided,
  isDisabled,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <FormControl id={id} isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
      <FormLabel fontSize={"sm"}>
        {title}
        {isLoading && <Spinner ml="5px" size="xs" />}
      </FormLabel>
      <Flex flexDirection={"row"}>
        <TimestampSelector
          datePlaceholderText={placeHolderText}
          date={date}
          showTimeInput={false}
          errorMessage={errorMessage}
          dateFormat={"dd MMM yyyy"}
          allowPastDates={true}
          isDisabled={isDisabled}
          onDateChange={async (newDate) => {
            setIsLoading(true);
            await onDateChange(newDate);
            setIsLoading(false);
          }}
        />
        {removeDataField && <RemoveDataFieldButton removeDataField={removeDataField} />}
      </Flex>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
