import { Button, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { BasicBidDocumentUploader } from "./form-elements/BasicBidDocumentUploader";
import { Comment } from "./form-elements/Comment";

interface Props {
  nextStep: () => void;
}

export const Details = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={"column"}>
      <Comment />
      <BasicBidDocumentUploader />
      <Flex mb="40px">
        <Button
          rightIcon={<Icon as={FaArrowRight} w="15px" h="15px" />}
          variant={"solid"}
          w="100%"
          colorScheme={"teal"}
          onClick={nextStep}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};
