import { Alert, AlertIcon, Box, Button, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CONTENT_WIDTH } from "../../config";
import { ConfirmEmailModal } from "../auth/ConfirmEmailModal";
import { useLoggedInWithOrgContextState } from "../auth/useLoggedInWithOrgContextState";
import { clearImpersonatedPersonId, getImpersonatedPersonId } from "../local-storage/impersonated-person";
import { useAppDispatch } from "../redux/hooks";
import { reloadMeThunk } from "../redux/thunks/auth/reload-me-thunk";
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar/Navbar";

interface Props {
  children: React.ReactNode;
  showFooter?: boolean;
  enableScrolling?: boolean;
}

export const Layout = ({ children, showFooter, enableScrolling }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const dispatch = useAppDispatch();
  const [isReloading, setIsReloading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const impersonatedPersonId = getImpersonatedPersonId();

  const getVerifyEmailBanner = (email: string) => {
    return (
      <Banner>
        <Box mt="20px">
          <Alert status="warning" borderRadius={"10px"}>
            <AlertIcon />
            <Text>
              {t("Please verify your email address")}
              <Button
                variant="link"
                colorScheme="black"
                ml="3px"
                mr="3px"
                onClick={() => setShowConfirmEmailModal(true)}
              >
                <u>
                  <b>{email}</b>
                </u>
              </Button>
              {t("or")}
              <Button
                variant="link"
                colorScheme="black"
                ml="3px"
                mr="3px"
                isLoading={isReloading}
                onClick={async () => {
                  setIsReloading(true);
                  await dispatch(reloadMeThunk());
                  setIsReloading(false);
                  toast({
                    title: "Page reloaded!",
                    status: "info",
                    position: "top-right",
                  });
                }}
              >
                <u>
                  <b>{t("refresh the page")}</b>
                </u>
              </Button>
              {t("if it's already done")}
            </Text>
          </Alert>
        </Box>
      </Banner>
    );
  };
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {showConfirmEmailModal && <ConfirmEmailModal onClose={() => setShowConfirmEmailModal(false)} />}
      {impersonatedPersonId && (
        <Alert status="info">
          <AlertIcon />
          You are impersonating user: <b>{authState.me.email}</b>
          <Button
            size="sm"
            variant={"solid"}
            colorScheme="blue"
            onClick={() => {
              clearImpersonatedPersonId();
              window.location.reload();
            }}
          >
            Stop
          </Button>
        </Alert>
      )}
      <Grid
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`50px minmax(${enableScrolling === true ? "0" : "min-content"}, calc(100vh - 51px)) ${
          showFooter === false ? "" : "300px"
        }`}
        templateAreas={`
                "nav nav nav"
                "content content content"
                "leftFooter footer rightFooter"
                `}
      >
        <GridItem area={"leftNav"} borderBottom="1px solid" borderColor="smBorder"></GridItem>
        <GridItem
          area={"nav"}
          colSpan={3}
          display="flex"
          justifyContent={"center"}
          justifySelf={"stretch"}
          borderBottom="1px solid"
          borderColor="smBorder"
        >
          <Navbar />
        </GridItem>
        <GridItem area={"rightNav"} borderBottom="1px solid" borderColor="smBorder"></GridItem>

        <GridItem area={"content"} h="100%" overflow="hidden" maxWidth="full">
          {!authState.me.emailVerified && getVerifyEmailBanner(authState.me.email)}
          {children}
        </GridItem>

        <GridItem area={"leftFooter"} borderTop="1px solid" borderColor="smBorder"></GridItem>
        {showFooter === false ? undefined : (
          <GridItem area={"footer"} borderTop="1px solid" borderColor="smBorder">
            <Footer />
          </GridItem>
        )}
        <GridItem area={"rightFooter"} borderTop="1px solid" borderColor="smBorder"></GridItem>
      </Grid>
    </div>
  );
};
