import { Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { assets } from "../../../assets/assets";
import { AcceptOrgUserInvite } from "../../../common/email-invites/AcceptOrgUserInvite";
import { useQueryParams } from "../../../common/useQueryParams";
import { requireStringEnvVar } from "../../../config";
import { AcceptSourcingEventEmailInvite } from "./AcceptSourcingEventEmailInvite";
import { parseEmailInviteType } from "./parseEmailinviteType";

export const AcceptEmailInviteWhenLoggedInWithOrgContext = () => {
  const { emailInviteId } = useParams();
  const params = useQueryParams();
  const secret = params.get("secret");
  const rawType = params.get("type");
  const type = parseEmailInviteType(rawType);
  const { t } = useTranslation();
  if (!emailInviteId || !secret || !type) {
    return <div>{t("Invalid invite params!")}</div>;
  }

  let content = null;
  switch (type) {
    case "SourcingEvent":
      content = <AcceptSourcingEventEmailInvite inviteId={emailInviteId} secret={secret} />;
      break;
    case "Supplier":
      window.location.replace(requireStringEnvVar("VITE_WEBAPP_BASE_URL"));
      break;
    case "OrgUser":
      content = <AcceptOrgUserInvite inviteId={emailInviteId} secret={secret} />;
      break;
  }
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Grid
        gridTemplateColumns={"1fr 450px 1fr"}
        height="100%"
        gridTemplateRows={"1fr auto 2fr"}
        templateAreas={`
                "left top right"
                "left login right"
                "left bottom right"
                `}
      >
        <GridItem area={"left"} bg="#f8f8f8"></GridItem>
        <GridItem area={"top"} bg="#f8f8f8"></GridItem>
        <GridItem area={"bottom"} bg="#f8f8f8"></GridItem>
        <GridItem area={"right"} bg="#f8f8f8"></GridItem>
        <GridItem area={"login"} borderRadius={10} boxShadow="0 9px 46px 8px rgb(0,0,0,0.2)" padding={10}>
          <Flex w="100%" h={"100%"} flexDirection="column">
            <Flex>
              <Image src={assets.logo} alt="Logo" h="30px" mt="10px" />
            </Flex>
            <br /> <br />
            {content}
          </Flex>
        </GridItem>
      </Grid>
    </div>
  );
};
