import { useToast } from "@chakra-ui/react";
import AwsS3 from "@uppy/aws-s3";
import Uppy, { UppyFile } from "@uppy/core";
import ProgressBar from "@uppy/progress-bar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { UploadDto, ViewDocumentAsOwnerDto, bffApi } from "../../../autogen/bff-api";
import { useApiError } from "../../errors/useApiError";
import { useAppDispatch } from "../../redux/hooks";
import { loadBasicBidThunk } from "../../redux/thunks/basic-bid/load-basic-bid-thunk";
import { loadBseThunk } from "../../redux/thunks/basic-sourcing-event/load-bse-thunk";
import { loadContractThunk } from "../../redux/thunks/contract/load-contract-thunk";
import { FileUpload } from "./FileRow";
import { DocumentUploadContext, useDocumentsUploader } from "./useDocumentsUploader";

export const useUppyUploader = (props: { entityType: DocumentUploadContext; entityId: string }) => {
  const [uploads, setUploads] = useState<UploadDto[]>([]);
  const [documents, setDocuments] = useState<ViewDocumentAsOwnerDto[]>([]);
  const { t } = useTranslation();
  const displayer = useApiError();
  const [tempFiles, setTempFiles] = useState<UppyFile<Record<string, unknown>, Record<string, unknown>>[]>([]);
  const toast = useToast();
  const { createPostRequest } = useDocumentsUploader({
    entityId: props.entityId,
    entityType: props.entityType,
  });
  const [target, setTarget] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTarget("#dnd");
  }, []);

  const reloadEntity = async (): Promise<boolean> => {
    switch (props.entityType) {
      case "Bid":
        await dispatch(
          loadBasicBidThunk({
            bidId: props.entityId,
          })
        );
        return true;
      case "Contract":
        await dispatch(
          loadContractThunk({
            contractId: props.entityId,
          })
        );
        return true;
      case "SourcingEvent":
        await dispatch(
          loadBseThunk({
            eventId: props.entityId,
          })
        );
        return true;
    }
  };

  const uppy = new Uppy({
    id: "AwsS3",
    autoProceed: true,
    onBeforeFileAdded(currentFile) {
      currentFile["id"] = uuidv4();
      return currentFile;
    },
  })
    .use(ProgressBar, {
      target: target,
    })
    .use(AwsS3, {
      id: "AwsS3",
      limit: 1,
      async getUploadParameters(file) {
        if (isFile(file.data)) {
          const response = await createPostRequest({
            id: file.id,
            name: file.name,
            file: file.data,
            extension: file.extension,
          });
          if ("data" in response) {
            return {
              url: response.data.url,
              method: "POST",
              fields: {
                "x-amz-date": response.data.renamedXAmzDate,
                "x-amz-signature": response.data.renamedXAmzSignature,
                "x-amz-algorithm": response.data.renamedXAmzAlgorithm,
                "x-amz-credential": response.data.renamedXAmzCredential,
                policy: response.data.policy,
                key: response.data.key,
              },
            };
          } else {
            displayer.trigger(response.error);
            throw Error("Should never happen");
          }
        } else {
          throw Error("Should never happen");
        }
      },
    })
    .on("file-added", (file) => {
      setTempFiles((files) => [...files, file]);
    })
    .on("complete", () => {
      if (reloadEntity) {
        reloadEntity();
      }
    })
    .on("error", (error) => {
      // TODO: log error
      console.error(error);

      toast({
        title: t("Upload failed!"),
        description: t("Something went wrong, please make sure the file is valid"),
        status: "error",
      });
    })
    .on("upload-error", (failedFile) => {
      // TODO: log errors
      if (failedFile) {
        setTempFiles((files) => files.filter((e) => e.id !== failedFile.id));
      }
      toast({
        title: t("Upload failed!"),
        description: t("Something went wrong, please make sure the file is valid"),
        status: "error",
      });
    })
    .on("restriction-failed", (failedFile) => {
      // TODO: log errors
      if (failedFile) {
        setTempFiles((files) => files.filter((e) => e.id !== failedFile.id));
      }
      toast({
        title: t("Upload failed!"),
        description: t("Did you try to upload the same file twice?"),
        status: "error",
      });
    });

  useEffect(() => {
    return () => {
      uppy.close();
    };
  }, []);

  const removeFile = (id: string) => {
    uppy.removeFile(id);
    setTempFiles((files) => files.filter((e) => e.id !== id));
  };

  const removeUpload = async (file: FileUpload) => {
    switch (file.status) {
      case "UploadCompleted":
        switch (props.entityType) {
          case "Bid": {
            const response = dispatch(
              bffApi.endpoints.updateBseBid.initiate({
                bidId: props.entityId,
                editBidRequest: {
                  removeDocument: {
                    value: file.documentId,
                  },
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeFile(file.uploadId);
              await reloadEntity();
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              displayer.trigger(result.error);
            }
            break;
          }
          case "Contract": {
            if (!file.documentId) {
              throw Error("should never happen");
            }
            const response = dispatch(
              bffApi.endpoints.updateContract.initiate({
                contractId: props.entityId,
                updateContractRequest: {
                  removeDocument: file.documentId,
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeFile(file.uploadId);
              await reloadEntity();
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              displayer.trigger(result.error);
            }
            break;
          }
          case "SourcingEvent": {
            const response = dispatch(
              bffApi.endpoints.updateBasicSourcingEvent.initiate({
                eventId: props.entityId,
                editSourcingEventRequest: {
                  removeDocument: file.documentId,
                },
              })
            );
            response.reset();
            const result = await response;
            if ("data" in result) {
              removeFile(file.uploadId);
              await reloadEntity();
              toast({
                title: t("Document removed!"),
                description: t("The document was removed successfully"),
                status: "success",
              });
            } else {
              displayer.trigger(result.error);
            }
            break;
          }
        }
        break;
      case "UploadFailed":
        removeFile(file.uploadId);
        break;
      case "UploadingToBrowser":
        removeFile(file.uploadId);
        break;
      case "UploadingToServer": {
        const response = dispatch(
          bffApi.endpoints.deleteUpload.initiate({
            uploadId: file.uploadId,
          })
        );
        response.reset();
        const result = await response;
        if ("data" in result) {
          removeFile(file.uploadId);
          await reloadEntity();
          toast({
            title: t("Upload canceled!"),
            description: t("The upload was canceled successfully"),
            status: "success",
          });
        } else {
          displayer.trigger(result.error);
        }
        break;
      }
    }
  };

  let allFiles: FileUpload[] = [];

  const uploadIdsInDocuments = documents.map((e) => e.fromUploadId);
  const uploadIds = uploads.map((e) => e.id).filter((id) => uploadIdsInDocuments.indexOf(id) === -1);

  allFiles = allFiles.concat(
    tempFiles
      .filter((e) => uploadIds.indexOf(e.id) === -1 && uploadIdsInDocuments.indexOf(e.id) === -1)
      .map((e) => ({
        status: "UploadingToBrowser",
        name: e.name,
        uploadId: e.id,
      }))
  );

  allFiles = allFiles.concat(
    uploads
      .filter((e) => uploadIdsInDocuments.indexOf(e.id) === -1)
      .map((e) => ({
        status: "UploadingToServer",
        name: e.fileName,
        uploadId: e.id,
      }))
  );

  allFiles = allFiles.concat(
    documents.map((e) => ({
      status: "UploadCompleted",
      name: e.name,
      documentId: e.id,
      uploadId: e.fromUploadId,
    }))
  );

  return {
    uppy,
    removeUpload,
    allFiles,
    setDocuments,
    setUploads,
    reloadEntity,
  };
};

const isFile = (file: File | Blob): file is File => {
  return (file as File).lastModified !== undefined;
};
