import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi } from "../../../../autogen/bff-api";
import { ContractState } from "../../reducers/contractReducer";

export const loadContractThunk = createAsyncThunk(
  "contract/load",
  async (
    props: {
      contractId: string;
    },
    { dispatch }
  ): Promise<ContractState | null> => {
    const response = dispatch(
      bffApi.endpoints.getContract.initiate({
        contractId: props.contractId,
      })
    );
    response.unsubscribe();
    const result = await response;

    if ("data" in result && result.data) {
      const body = result.data;
      return {
        id: body.id,
        contract: body as ContractState["contract"],
        lastChanged: body.modifiedAt,
        errors: [],
      };
    } else {
      return null;
    }
  }
);
