import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import moment from "moment";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BidDto, useListBidsCreatedByDepartmentQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useConfirmationModal } from "../../../common/useConfirmationModal";
import { urls } from "../../../urls";
import { Created } from "../../sourcing-events/buyer/view-all/CreatedBy";
import { ViewDeliveredBseBid } from "../../sourcing-events/supplier/view-single/ViewDeliveredBseBid";
import { BidInvitationStatus } from "./BidInvitationStatus";
import { DeleteDraftButton } from "./DeleteDraftButton";
import { EditBidDraftButton } from "./EditBidDraftButton";
import { ViewEventButton } from "./ViewEventButton";

export const DeliveredBidsTable = () => {
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModal();
  const authState = useLoggedInWithOrgContextState();
  const { data, error, isLoading, refetch } = useListBidsCreatedByDepartmentQuery({
    depId: authState.selectedOrg.departments[0].id,
  });
  const { t } = useTranslation();
  const [bidToView, setBidToView] = useState<BidDto>();
  const displayer = useApiError();

  if (isLoading) return <div>{t("Loading")}...</div>;
  if (error) return displayer.show(error);
  if (!data) return <Box>{t("Sorry, something went wrong")}...</Box>;

  const getButtons = (bid: BidDto): ReactNode => {
    switch (bid.status) {
      case "DraftCreated":
        return (
          <>
            <EditBidDraftButton bid={bid} colorScheme={"teal"} size="sm" />
            <Box w="5px"></Box>
            <DeleteDraftButton bid={bid} refetch={refetch} />
          </>
        );
      case "BidDelivered":
      case "InviteNotAccepted":
      case "InviteAccepted":
      case "IsLoser":
      case "IsWinner":
        return <ViewEventButton bid={bid} />;
    }
  };

  return (
    <>
      {confirmationModal.modal}
      {bidToView && (
        <ViewDeliveredBseBid
          bidId={bidToView.id}
          isOpen={bidToView !== undefined}
          onClose={() => setBidToView(undefined)}
        />
      )}
      <Box mt="15px">
        <Text fontSize="medium" color="smMuted">
          {t("Overview of your delivered bids")}
        </Text>
      </Box>
      <Box
        backgroundColor="smBackgroundSecondary"
        borderRadius="lg"
        mt="5"
        boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t("Event")}</Th>
              <Th>{t("Buyer")}</Th>
              <Th>{t("Created by")}</Th>
              <Th>{t("Status")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <>
              {data.bids.map((e) => {
                return (
                  <Tr key={e.id}>
                    <Td width={"80"} maxWidth={"80"} overflow={"hidden"} textAlign={"left"}>
                      <Button
                        variant={"link"}
                        colorScheme={"teal"}
                        textAlign={"left"}
                        whiteSpace={"pre-wrap"}
                        onClick={() => navigate(urls.events.supplierView.go(e.forEvent.id))}
                      >
                        {e.forEvent.title}
                      </Button>
                    </Td>
                    <Td>
                      <Text>{e.forEvent.owningOrganization.name}</Text>
                    </Td>
                    <Td>
                      <Created person={e.createdBy} createdAt={moment(e.createdAt)} />
                    </Td>
                    <Td>
                      <BidInvitationStatus status={e.status} />
                    </Td>
                    <Td>
                      <Flex flexDirection={"row"}>{getButtons(e)}</Flex>
                    </Td>
                  </Tr>
                );
              })}
              {data.bids.length === 0 && (
                <Tr>
                  <Td colSpan={4} width={"100%"} textAlign="center">
                    {t("Your organization has not delivered any bids, yet!")}
                  </Td>
                </Tr>
              )}
            </>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
