import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import { useState } from "react";
import { FormState, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContractDto } from "../../../../../../../autogen/bff-api";
import { FormTimestampSelector } from "../../../../../../../common/input/TimestampSelector/FormTimestampSelector";
import { useAppDispatch } from "../../../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../../../common/redux/thunks/contract/edit-contract-thunk";

interface FormValues {
  fromDate: string;
  currencyAmount: number;
  contractId: string;
}

interface Props {
  contract: ContractDto;
  onCancel: () => void;
}

export const AddCurrencyAmountAdjustment = ({ contract, onCancel }: Props) => {
  const methods = useForm<FormValues>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [dateErrorMsg, setDateErrorMsg] = useState<string | null>(null);

  const isInvalid = ({
    contract,
    formState,
    values,
  }: {
    contract: ContractDto;
    formState: FormState<FormValues>;
    values: FormValues;
  }): boolean => {
    if (formState.errors.currencyAmount !== undefined) return true;
    if (isDateInvalid({ contract, values })) return true;
    return false;
  };

  const isDateInvalid = ({ contract, values }: { contract: ContractDto; values: FormValues }) => {
    const startDate = contract.dataFields?.ifrsValues?.startDate;
    const endDate = contract.dataFields?.endDate?.date;
    const adjustmentDate = values.fromDate;
    if (!adjustmentDate) {
      setDateErrorMsg(t("Adjustment date must be set"));
      return true;
    }
    if (startDate && adjustmentDate < startDate) {
      setDateErrorMsg(t("Adjustment date must be after contract start date"));
      return true;
    }
    if (endDate && endDate < adjustmentDate) {
      setDateErrorMsg(t("Adjustment date must be before contract end date"));
      return true;
    } else {
      setDateErrorMsg(null);
      return false;
    }
  };

  const handleSubmit = async ({
    contract,
    formState,
    values,
  }: {
    contract: ContractDto;
    formState: FormState<FormValues>;
    values: FormValues;
  }) => {
    if (isInvalid({ contract, formState, values })) return;
    await addCurrencyAmountAdjustment(values);
  };

  const addCurrencyAmountAdjustment = async (values: FormValues) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "AddMonetaryAdjustment",
          value: {
            date: values.fromDate,
            value: values.currencyAmount,
          },
        },
      })
    );
    setIsLoading(false);
    onCancel();
  };

  return (
    <form
      onSubmit={methods.handleSubmit(() =>
        handleSubmit({ contract, formState: methods.formState, values: methods.getValues() })
      )}
    >
      <Box mt="15px">
        <FormTimestampSelector
          helperText={""}
          control={methods.control}
          name="fromDate"
          title={t("Month")}
          datePlaceholderText={t("Provide month")}
          dateFormat="MMM yyyy"
          showMonthYearPicker={true}
          allowPastDates={true}
          errorMessage={dateErrorMsg}
        />
      </Box>
      <FormControl isInvalid={methods.formState.errors.currencyAmount !== undefined}>
        <FormLabel mt="10px">{t("Amount")}</FormLabel>
        <Input
          id={"currencyAmount"}
          type="number"
          autoComplete="off"
          placeholder={`${t("Amount in")} ${contract.currency}` ?? ""}
          {...methods.register("currencyAmount", {
            required: t("Please provide an amount") ?? "",
            min: { value: 0, message: t("Please provide a positive number") },
          })}
        />
        <FormErrorMessage>
          {methods.formState.errors.currencyAmount && methods.formState.errors.currencyAmount.message}
        </FormErrorMessage>
      </FormControl>
      <Button mt="25px" type="submit" variant={"solid"} colorScheme="teal" size={"md"} isLoading={isLoading}>
        {t("Add")}
      </Button>
      <Button mt="25px" ml="5px" variant={"solid"} colorScheme="orange" size={"md"} onClick={onCancel}>
        {t("Cancel")}
      </Button>
    </form>
  );
};
