import { Flex, Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  showBelow?: boolean;
  errorMessage: string | null;
}

export const ReviewRow = ({ title, children, showBelow, errorMessage }: Props) => {
  return (
    <Flex padding={"10px"} flexDirection={showBelow && !errorMessage ? "column" : "row"}>
      <Flex flex={1} w="100%">
        <Text fontSize={"md"} fontWeight="bold">
          {title}
        </Text>
      </Flex>
      <Flex flex={4}>
        {errorMessage ? (
          <Text fontSize={"md"} color={"red.500"}>
            {errorMessage}
          </Text>
        ) : (
          children
        )}
      </Flex>
    </Flex>
  );
};
