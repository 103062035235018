import React from 'react'
import { OrganizationEntryModal } from '.'
import { useGetOrganizationEntryQuery } from '../../../../autogen/bff-api'

interface Props {
    entryId: string
    onClose: () => void
}

export const OrganizationEntryModalWrapper: React.FC<Props> = ({
    entryId,
    onClose,
}) => {
    const { data } = useGetOrganizationEntryQuery({
        entryId: entryId,
    })

    if (!data) {
        return null
    }
    return <OrganizationEntryModal entry={data} onClose={onClose} />
}
