import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewDocumentAsOwnerDto } from "../../../../../autogen/bff-api";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { CreateSignedDocument } from "./CreateSignedDocument";
import { SignerInput } from "./InvitedSigner";

interface Props {
  documents: ViewDocumentAsOwnerDto[];
  sendForSigning: (values: { contractDocument: SelectorValue; signers: SignerInput[] | undefined }) => Promise<boolean>;
  onClose: () => void;
}

export const SendForSigningModal = ({ documents, sendForSigning, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={true} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Send document for signing")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateSignedDocument documents={documents} sendForSigning={sendForSigning} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
