import { Button, Flex, Icon, Input, Select, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, isEqual, isFinite } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import {
  ContractDateReminderDto,
  ContractDateReminderType,
  ContractRole,
  DefaultNotificationSettingsDto,
  LeadTimeDto,
  LeadTimeUnit,
  OrganizationRoleType,
  useUpdateOrganizationMutation,
} from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelector } from "../../common/input/Selector/MultiSelector";
import { useAppDispatch } from "../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../common/redux/reducers/authStateReducer";
import { initSettings } from "./Settings";

type ContractDateReminder = Omit<ContractDateReminderDto, "leadTime"> & { leadTime: Partial<LeadTimeDto> };

const initSetting = (): ContractDateReminder => ({
  leadTime: {
    amount: undefined,
    unit: "Days",
  },
  receivers: {
    persons: [],
    organizationRoles: [],
    contractRoles: [],
  },
});

const organizationContractRoles: OrganizationRoleType[] = ["Owner", "Admin", "ContractCreator", "ContractViewer"];
const contractRoles: ContractRole[] = ["CounterParty", "InternalOwner", "InvitedParty"];

const isContractDateReminderDto = (setting: ContractDateReminder): setting is ContractDateReminderDto => {
  return !!setting.leadTime.unit && isFinite(setting.leadTime.amount);
};

export const ContractNotificationSettingForm = ({ type }: { type: ContractDateReminderType }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const toast = useToast();

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const [isVisible, setIsVisible] = useState(false);
  const [setting, setSetting] = useState<ContractDateReminder>(initSetting());

  const saveSetting = useCallback(
    async (setting: ContractDateReminder) => {
      if (!isContractDateReminderDto(setting)) throw Error("Invalid date reminder setting!");
      const settings: DefaultNotificationSettingsDto =
        cloneDeep(authState.selectedOrg.defaultNotificationSettings) ?? initSettings();
      switch (type) {
        case "StartDateReminder":
          if (settings?.contractNotificationSettings.startDateNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.startDateNotifications.push(setting);
          break;
        case "EndDateReminder":
          if (settings?.contractNotificationSettings.endDateNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.endDateNotifications.push(setting);
          break;
        case "RenewalDateReminder":
          if (settings?.contractNotificationSettings.renewalDateNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.renewalDateNotifications.push(setting);
          break;
        case "WarrantyExpirationDateReminder":
          if (settings?.contractNotificationSettings.warrantyExpirationNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.warrantyExpirationNotifications.push(setting);
          break;
        case "TerminationDateReminder":
          if (settings?.contractNotificationSettings.terminationDateNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.terminationDateNotifications.push(setting);
          break;
        case "NoticePeriodReminder":
          if (settings?.contractNotificationSettings.noticePeriodNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.noticePeriodNotifications.push(setting);
          break;
        case "OptionExecutionDeadlineReminder":
          if (
            settings?.contractNotificationSettings.optionExecutionDeadlineNotifications.find((s) => isEqual(s, setting))
          ) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.optionExecutionDeadlineNotifications.push(setting);
          break;
        case "OptionStartDateReminder":
          if (settings?.contractNotificationSettings.optionStartDateNotifications.find((s) => isEqual(s, setting))) {
            toast({ description: "This setting already exists", variant: "subtle", status: "error" });
            return;
          }
          settings.contractNotificationSettings.optionStartDateNotifications.push(setting);
          break;
      }
      const response = await updateOrg({
        orgId: authState.selectedOrg.id,
        editOrganizationRequest: {
          defaultNotificationSettings: settings,
        },
      });

      if ("data" in response) {
        dispatch(updateSelectedOrg(response.data as OrgDto));
        setSetting(initSetting());
        setIsVisible(false);
      }
    },
    [authState.selectedOrg.defaultNotificationSettings, authState.selectedOrg.id, dispatch, toast, type, updateOrg]
  );

  const usersWithContractAccess = useMemo(() => {
    return authState.selectedOrg.users.filter((u) => u.roles.some((role) => organizationContractRoles.includes(role)));
  }, [authState.selectedOrg.users]);

  if (!isVisible)
    return (
      <Flex>
        <Button
          rightIcon={<Icon as={FaPlus} />}
          size="xs"
          variant={"ghost"}
          colorScheme="teal"
          onClick={() => setIsVisible(true)}
        >
          {t("Create new")}
        </Button>
      </Flex>
    );

  return (
    <Flex
      position={"relative"}
      flexDirection={"column"}
      rowGap={"2"}
      border="1px solid"
      borderColor="smBorder"
      rounded="md"
      p="2"
      pb="48"
    >
      <Flex columnGap={"2"}>
        <Input
          type="number"
          width="30%"
          size={"md"}
          rounded="md"
          placeholder={`${t("Duration")}...`}
          min={0}
          value={setting.leadTime.amount === undefined ? "" : setting.leadTime.amount}
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.leadTime.amount = e.target.value === "" ? undefined : +e.target.value;
            setSetting(updatedSetting);
          }}
        />
        <Select
          width={"30%"}
          value={setting.leadTime.unit}
          size={"md"}
          rounded="md"
          onChange={(e) => {
            const updatedSetting = cloneDeep(setting);
            updatedSetting.leadTime.unit = e.target.value as LeadTimeUnit;
            setSetting(updatedSetting);
          }}
        >
          <option value="Days">{t("Days")}</option>
          <option value="Months">{t("Months")}</option>
        </Select>
      </Flex>
      <MultiSelector
        value={usersWithContractAccess
          .filter((u) => setting.receivers.persons.includes(u.person.id))
          .map((u) => ({ label: `${u.person.firstName} ${u.person.lastName}`, value: u.person.id }))}
        options={usersWithContractAccess.map((u) => ({
          label: `${u.person.firstName} ${u.person.lastName}`,
          value: u.person.id,
        }))}
        noOptionsAvailableMessage={`${t("No users found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.persons = options.map((o) => o.value);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select individual receivers")}...` ?? "", color: "gray.500" }}
      />
      <MultiSelector
        value={setting.receivers.organizationRoles.map((role) => ({ label: role, value: role }))}
        options={organizationContractRoles.map((role) => ({
          label: role,
          value: role,
        }))}
        noOptionsAvailableMessage={`${t("No roles found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.organizationRoles = options.map((o) => o.value as OrganizationRoleType);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select receivers by role in organization")}...` ?? "", color: "gray.500" }}
      />
      <MultiSelector
        value={setting.receivers.contractRoles.map((role) => ({ label: role, value: role }))}
        options={contractRoles.map((role) => ({
          label: role,
          value: role,
        }))}
        noOptionsAvailableMessage={`${t("No roles found")}...`}
        onChange={(options) => {
          const updatedSetting = cloneDeep(setting);
          updatedSetting.receivers.contractRoles = options.map((o) => o.value as ContractRole);
          setSetting(updatedSetting);
        }}
        placeholder={{ text: `${t("Select receivers by role in contract")}...` ?? "", color: "gray.500" }}
      />
      <Flex>
        <Button
          colorScheme="blue"
          size={"xs"}
          rightIcon={<Icon as={FaCheck} />}
          isLoading={isLoading}
          isDisabled={setting.leadTime.amount === undefined}
          onClick={() => saveSetting(setting)}
        >
          {t("Save")}
        </Button>
      </Flex>
    </Flex>
  );
};
