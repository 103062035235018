import { Box, Button, Flex, Icon, LinkBox, LinkOverlay, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { BseDto } from "../../../../autogen/bff-api";
import { Explanation } from "../../../../common/support/Explanation";
import { requireStringEnvVar } from "../../../../config";

interface Props {
  data: BseDto;
}

export const BseReports = ({ data }: Props) => {
  const { t } = useTranslation();

  const getCannotDownloadExplanation = (): string | null => {
    if (!data.awardedFields) return t("The event has to be awarded before the protocol can be downloaded");
    if (!data.awardedFields.eventValue) return t("You must provide a contract value before downloading this report");
    return null;
  };

  const cannotDownloadExplanation = getCannotDownloadExplanation();
  const canDownload = cannotDownloadExplanation === null;

  return (
    <Box borderRadius={10} mt="6">
      <Text fontWeight={"semibold"}>{t("Reports")}</Text>
      <Table variant="simple" size={"sm"}>
        <Tbody>
          <Tr>
            <Td>{t("Procurement protocol")}</Td>
            <Td>
              <Flex justifyContent="end">
                <LinkBox>
                  <LinkOverlay
                    href={`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/supplier/basic/events/${
                      data.id
                    }/public-procurement-protocol-part-one`}
                  >
                    <Explanation text={cannotDownloadExplanation ?? ""} enabled={!canDownload}>
                      <Button
                        size="sm"
                        leftIcon={<Icon as={FaDownload} w="15px" h="15px" />}
                        isDisabled={!canDownload}
                        variant={"ghost"}
                        colorScheme="blue"
                      >
                        {t("Download")}
                      </Button>
                    </Explanation>
                  </LinkOverlay>
                </LinkBox>
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td>{t("Questions and announcements")}</Td>
            <Td>
              <Flex justifyContent="end">
                <LinkBox>
                  <LinkOverlay
                    href={`${requireStringEnvVar("VITE_BFF_BASE_URL")}/api/supplier/basic/events/${data.id}/qa-report`}
                  >
                    <Button size="sm" leftIcon={<Icon as={FaDownload} />} variant={"ghost"} colorScheme="blue">
                      {t("Download")}
                    </Button>
                  </LinkOverlay>
                </LinkBox>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
