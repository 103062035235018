import { Box, Button, Collapse, Flex, Icon, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaPaperPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDeliverBseBidMutation } from "../../../autogen/bff-api";
import { DocumentsAsOwnerTable } from "../../../common/documents/DocumentsAsOwnerTable";
import { ReviewRow } from "../../../common/editing/ReviewRow";
import { useApiError } from "../../../common/errors/useApiError";
import { TipTap } from "../../../common/input/TipTap/TipTap";
import { urls } from "../../../urls";
import { useBasicBidState } from "../../sourcing-events/useBasicBidEventState";

interface Props {
  previousStep: () => void;
}

export const Review = ({ previousStep }: Props) => {
  const bidState = useBasicBidState();
  const toast = useToast();
  const navigate = useNavigate();
  const [deliverBid, { isLoading }] = useDeliverBseBidMutation();
  const { t } = useTranslation();
  const apiErrorDisplayer = useApiError();

  const [showFullDescription, setShowFullDescription] = useState(false);

  const deliver = async () => {
    const result = await deliverBid({
      eventId: bidState.forEvent.id,
      bidId: bidState.id,
    });

    if ("data" in result) {
      toast({
        title: t("Bid delivered!"),
        description: t("The bid was delivered successfully"),
        status: "success",
      });
      navigate(urls.bids.viewAll);
    } else {
      apiErrorDisplayer.trigger(result.error);
    }
  };

  const getCommentErrorMessage = (comment: string | null): string | null => {
    if (comment === null) return t("Please provide a comment to your bid");
    return null;
  };

  return (
    <Box pt="8">
      <ReviewRow title={t("Comment")} errorMessage={getCommentErrorMessage(bidState.comment.value)}>
        <Flex flexDirection="column" alignItems="start">
          <Collapse startingHeight="2rem" in={showFullDescription}>
            <TipTap
              content={bidState.comment.value ?? ""}
              editable={false}
              hasBorder={false}
              maxHeight="60"
              padding="0"
            />
          </Collapse>
          <Button size="sm" variant={"link"} mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
            {!showFullDescription ? t("Show more") : t("Show less")}
          </Button>
        </Flex>
      </ReviewRow>
      <ReviewRow title={t("Documents")} showBelow={true} errorMessage={null}>
        <Box w="100%" pt="4">
          <DocumentsAsOwnerTable documents={bidState.documents.value} />
        </Box>
      </ReviewRow>
      <Flex pt="5" px="2.5">
        <Button
          leftIcon={<Icon as={FaArrowLeft} />}
          variant={"outline"}
          mr="10px"
          w="100%"
          colorScheme={"teal"}
          onClick={previousStep}
        >
          {t("Previous")}
        </Button>
        <Button
          leftIcon={<Icon as={FaPaperPlane} />}
          variant={"solid"}
          w="100%"
          colorScheme={"blue"}
          isLoading={isLoading}
          onClick={deliver}
        >
          {t("Deliver bid")}
        </Button>
      </Flex>
    </Box>
  );
};
