import { Flex, Heading, Icon, IconButton } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetContractStatisticsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { urls } from "../../../urls";
import { Invites } from "../../email-invites/manage-supplier-invites/Invites";
import { Stat } from "./Stat";

export const ContractStats = () => {
  const [showSendInvites, setShowSendInvites] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const navigate = useNavigate();
  const { data } = useGetContractStatisticsQuery({
    orgId: authState.selectedOrg.id,
  });

  return (
    <>
      {showSendInvites && <Invites isOpen={true} onClose={() => setShowSendInvites(false)} />}
      {data && (
        <>
          <Heading as="h2" fontSize={"sm"} color="#8c98aa">
            {t("Contracts")}
          </Heading>
          <Flex columnGap={"3"} width={"full"} pb="4">
            <Stat title={t("Active_plural")} value={`${data.numberOfActiveContracts}`} />
            <Stat title={t("Expiring this year")} value={`${data.numberOfContractsExpiringThisYear}`} />
          </Flex>
          <IconButton
            aria-label="go to contracts"
            icon={<Icon as={FaArrowRight} />}
            variant={"link"}
            colorScheme="cyan"
            float="right"
            onClick={() => {
              navigate(urls.contracts.viewPublished);
            }}
          />
        </>
      )}
    </>
  );
};
