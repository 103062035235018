import { Flex, Td, Text, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ContractListItemDto } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { displaySum } from "../../../common/formatting/displaySum";
import { urls } from "../../../urls";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { ContractStateTag } from "../edit/ContractStateTag";
import { getCounterpartyName } from "../view-all-published/PublishedContractRow";

interface Props {
  contract: ContractListItemDto;
}

export const DraftContractRow = ({ contract }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const counterpartyName = contract.counterparty ? getCounterpartyName(contract.counterparty) : null;

  return (
    <Tr
      key={contract.id}
      _hover={{ cursor: "pointer" }}
      onClick={() => {
        navigate(urls.contracts.edit.go(contract.id).details);
      }}
    >
      <Td fontSize={"sm"}>{contract.title}</Td>
      <Td fontSize={"sm"}>
        {counterpartyName ? (
          <Flex>
            <Flex>
              <ProfileImageRounded
                name={counterpartyName}
                backgroundColor={"blue.500"}
                width={"20px"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex mt="-1px" ml="5px">
              {counterpartyName}
            </Flex>
          </Flex>
        ) : (
          <Text as="span" fontStyle={"italic"}>
            {t("Not provided")}
          </Text>
        )}
      </Td>
      <Td fontSize={"sm"}>
        {contract.endDate ? (
          displayDate(contract.endDate)
        ) : contract.hasNoEndDate ? (
          t("Has no end date")
        ) : (
          <Text as="span" fontStyle={"italic"}>
            {t("Not provided")}
          </Text>
        )}
      </Td>
      <Td fontSize={"sm"}>
        {contract.estimatedValue ? (
          displaySum({
            value: contract.estimatedValue,
            currency: contract.currency,
          })
        ) : (
          <Text as="span" fontSize={"sm"} fontStyle={"italic"}>
            {t("Not provided")}
          </Text>
        )}
      </Td>
      <Td fontSize={"sm"}>
        {contract.internalOwner ? (
          <Flex>
            <Flex>
              <ProfileImageRounded
                name={contract.internalOwner.firstName}
                backgroundColor={"teal.500"}
                width={"20px"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex mt="-2px" ml="5px">
              {`${contract.internalOwner.firstName} ${contract.internalOwner.lastName}`}
            </Flex>
          </Flex>
        ) : (
          <Text as="span" fontStyle={"italic"}></Text>
        )}
      </Td>
      <Td>
        <ContractStateTag state={contract.state} />
      </Td>
    </Tr>
  );
};
