import { EmailInviteLanguage } from "../../autogen/bff-api"

const localStorageKey = 'selected-language'

const customLanguageDict: Record<string, EmailInviteLanguage | undefined> = {
    no: 'No',
    nb: 'No',
    nn: 'No',
    nb_NO: 'No',
    nn_NO: 'No',
    'nb-NO': 'No',
    'nn-NO': 'No',
    en: 'En',
}

export const getPreferredLanguage = (): EmailInviteLanguage => {
    const storedLanguage = getStoredLanguage()

    if (!storedLanguage) {
        const browserLanguage = navigator.language

        const customLanguage = customLanguageDict[browserLanguage]

        if (customLanguage) {
            return customLanguage
        } else {
            return 'En'
        }
    } else {
        return storedLanguage as EmailInviteLanguage
    }
}

export const setPreferredLanguage = (lang: EmailInviteLanguage) => {
    setStoredLanguage(lang)
}

const getStoredLanguage = (): string | null => {
    return localStorage.getItem(localStorageKey)
}

const setStoredLanguage = (lang: string) => {
    localStorage.setItem(localStorageKey, lang)
}
