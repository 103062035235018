import { Box, Button, Grid, GridItem, Heading, Icon, Input, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { CreateProjectDto, useCreateProjectMutation, useGetProjectsQuery } from "../../autogen/bff-api";
import { displayDate } from "../../common/formatting/displayDate";
import { TimestampSelector } from "../../common/input/TimestampSelector/TimestampSelector";
import { Layout } from "../../common/layout/Layout";
import { useSkeletons } from "../../common/useSkeletons";
import { CONTENT_WIDTH } from "../../config";

const initializeProject = (): CreateProjectDto => ({
  id: uuid(),
  externalId: "",
  name: "",
  startDate: undefined,
  endDate: undefined,
});

export const Projects = () => {
  const skeletons = useSkeletons();

  const [createProject, { isLoading: isCreatingProject }] = useCreateProjectMutation();

  const { data, isLoading } = useGetProjectsQuery();

  const [newProject, setNewProject] = useState<CreateProjectDto>(initializeProject());

  return (
    <Layout>
      <Grid
        h="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"minmax(0, 1fr)"}
        templateAreas={`
                    "leftContent content rightContent"
                    `}
      >
        <GridItem h="100%" area={"content"} px="2">
          <Heading as="h2" size="lg" mt="2.5" pb="4">
            {t("Projects")}
          </Heading>
          {!isLoading && (
            <Table>
              <Thead>
                <Tr>
                  <Th>{t("ID")}</Th>
                  <Th>{t("Name")}</Th>
                  <Th>{t("Start date")}</Th>
                  <Th>{t("End date")}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.projects.map((project) => (
                  <Tr key={project.id}>
                    <Td>{project.externalId}</Td>
                    <Td>{project.name}</Td>
                    <Td>{project.startDate ? displayDate(project.startDate) : null}</Td>
                    <Td>{project.endDate ? displayDate(project.endDate) : null}</Td>
                  </Tr>
                ))}
                <Tr>
                  <Td>
                    <Input
                      placeholder={t("Project ID") ?? ""}
                      value={newProject.externalId}
                      onChange={(e) => setNewProject((p) => ({ ...p, externalId: e.target.value }))}
                    />
                  </Td>
                  <Td>
                    <Input
                      placeholder={t("Name") ?? ""}
                      value={newProject.name}
                      onChange={(e) => setNewProject((p) => ({ ...p, name: e.target.value }))}
                    />
                  </Td>
                  <Td>
                    <TimestampSelector
                      datePlaceholderText={`${t("Select date")}...`}
                      showTimeInput={false}
                      date={newProject?.startDate ?? null}
                      allowPastDates
                      errorMessage={null}
                      dateFormat={"dd.MM.yyyy"}
                      onDateChange={(d) => setNewProject((p) => ({ ...p, startDate: d ?? undefined }))}
                    />
                  </Td>
                  <Td>
                    <TimestampSelector
                      datePlaceholderText={`${t("Select date")}...`}
                      showTimeInput={false}
                      date={newProject?.endDate ?? null}
                      allowPastDates
                      errorMessage={null}
                      dateFormat={"dd.MM.yyyy"}
                      onDateChange={(d) => setNewProject((p) => ({ ...p, endDate: d ?? undefined }))}
                    />
                  </Td>
                  <Td>
                    <Button
                      size={"sm"}
                      variant={"outline"}
                      colorScheme="teal"
                      leftIcon={<Icon as={FaCheck} />}
                      isLoading={isCreatingProject}
                      onClick={async () => {
                        await createProject({ createProjectDto: newProject });
                        setNewProject(initializeProject());
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          )}
          {isLoading && <Box py="5">{skeletons.stackedLines(10, 5)}</Box>}
        </GridItem>
      </Grid>
    </Layout>
  );
};
