import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Layout } from "../../../common/layout/Layout";
import { CONTENT_WIDTH } from "../../../config";
import { DeliveredBidsTable } from "./DeliveredBidsTable";

export const DeliveredBids = () => {
  const { t } = useTranslation();
  return (
    <>
      <Layout>
        <Grid
          height="100%"
          gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
          gridTemplateRows={"1fr"}
          templateAreas={`
                "leftContent content rightContent"
                `}
        >
          <GridItem area={"content"}>
            <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
              <GridItem display="flex" alignItems="center" borderBottom={"1px solid"} borderColor="smBorder" px="2">
                <Heading as="h2" size="lg">
                  {t("Delivered bids")}
                </Heading>
              </GridItem>
              <GridItem px="2">
                <DeliveredBidsTable />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Layout>
    </>
  );
};
