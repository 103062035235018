import { Box, Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Layout } from "../../../common/layout/Layout";
import { EditContractStep, useQueryParams } from "../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../config";
import { urls } from "../../../urls";
import { ContractStateTag } from "./ContractStateTag";
import { ContractTemplateTag } from "./ContractTemplateTag";
import { EditContractFormNavigation } from "./EditContractFormNavigation";
import { Details } from "./details/Details";
import { Docs } from "./docs/Docs";
import { Review } from "./review/Review";
import { useContractState } from "./useContractState";

export const EditContractForm = () => {
  const params = useQueryParams();
  const { contractId } = useParams();
  const [searchParams] = useSearchParams();
  const currentStep = params.getEditContractStep();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contractState = useContractState();

  const [originEvent, setOriginEvent] = useState<string>();

  if (!contractId) {
    throw Error("contractId not defined");
  }

  useEffect(() => {
    const eventId = searchParams.get("originEvent");
    if (eventId) setOriginEvent(eventId);
  }, [searchParams]);

  const getTitle = (step: EditContractStep): string => {
    switch (step) {
      case "details":
        return t("Details");
      case "docs":
        return t("Documents");
      case "review":
        return t("Review");
    }
  };

  const navigateToDetails = () => navigate(urls.contracts.edit.go(contractId, originEvent).details);
  const navigateToDocuments = () => navigate(urls.contracts.edit.go(contractId, originEvent).documents);
  const navigateToReview = () => navigate(urls.contracts.edit.go(contractId, originEvent).review);

  const navigateAfterPublish = useCallback(() => {
    if (originEvent) navigate(urls.events.view.go(originEvent));
    else navigate(urls.contracts.viewSingle.go(contractState.id));
  }, [contractState.id, navigate, originEvent]);

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`auto minmax(min-content, auto)`}
        templateAreas={`
                "contentLeft content contentRight"
                `}
        paddingLeft="10px"
        paddingRight={"10px"}
      >
        <GridItem area={"content"} h="100%">
          <Grid
            gridTemplateAreas={`
                        "menuNav contentNav"
                        "menu content"
                    `}
            gridTemplateColumns={"180px 1fr"}
            columnGap="20px"
            gridTemplateRows={"minmax(50px, min-content) minmax(min-content, auto)"}
            h="100%"
            marginTop={"15px"}
          >
            <GridItem area="menuNav" alignItems="end" borderBottom={"1px solid #e1e6ee"}></GridItem>
            <GridItem area="contentNav" alignItems="end" borderBottom={"1px solid #e1e6ee"}>
              <Heading as="h2" size="md" mr="10px">
                {getTitle(currentStep)}
              </Heading>
              <Box display="flex" w="100%" pb="2">
                <Box pr="2" pt="1">
                  <ContractStateTag state={contractState.contract.state} />
                </Box>
                {contractState.contract.template && (
                  <Box pr="2" pt="1">
                    <ContractTemplateTag template={contractState.contract.template} />
                  </Box>
                )}
              </Box>
            </GridItem>
            <GridItem h="100%" area="menu" display={isLargerThan768 ? "block" : "none"}>
              <Box mt="37px">
                <EditContractFormNavigation
                  navigateToDetails={navigateToDetails}
                  navigateToDocs={navigateToDocuments}
                  navigateToReview={navigateToReview}
                  navigateToEvent={originEvent ? () => navigateAfterPublish() : undefined}
                />
              </Box>
            </GridItem>
            <GridItem h="100%" area="content">
              <Box h="100%">
                <Box display={currentStep === "details" ? undefined : "none"}>
                  <Details nextStep={navigateToDocuments} />
                </Box>
                <Box display={currentStep === "docs" ? undefined : "none"}>
                  <Docs previousStep={navigateToDetails} nextStep={navigateToReview} />
                </Box>
                <Box display={currentStep === "review" ? undefined : "none"}>
                  <Review previousStep={navigateToDocuments} navigateAfterPublish={navigateAfterPublish} />
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
