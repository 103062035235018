import { Box, Button, Flex, Icon, Skeleton, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { ContractTemplateDto, useListContractTemplatesQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { ContractTemplate } from "../../../../common/types";
import { CreateTemplateModal } from "./CreateTemplateModal";
import { EditTemplateModal } from "./EditContractTemplateModal";
import { TemplateRow } from "./TemplateRow";

export const ContractTemplates = () => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();

  const { data, error, isLoading } = useListContractTemplatesQuery({
    organizationId: authState.selectedOrg.id,
    orgId: authState.selectedOrg.id,
  });

  const [templateToEdit, setTemplateToEdit] = useState<ContractTemplateDto>();
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  if (error) return displayer.show(error);

  return (
    <>
      {showCreateTemplateModal && <CreateTemplateModal onClose={() => setShowCreateTemplateModal(false)} />}
      <Flex justifyContent={"end"} alignItems={"center"} mb="20px">
        <Button
          variant={"outline"}
          colorScheme={"teal"}
          size="sm"
          onClick={() => {
            setShowCreateTemplateModal(true);
          }}
          leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
        >
          {t("New type")}
        </Button>
      </Flex>
      <Box border="1px solid teal" rounded="lg" pb="2">
        {data && (
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>{t("Name")}</Th>
                <Th>{t("Created at")}</Th>
                <Th>{t("Required fields")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.templates.map((e) => {
                return (
                  <TemplateRow
                    key={e.id}
                    template={e as unknown as ContractTemplate}
                    setTemplateToEdit={setTemplateToEdit}
                  />
                );
              })}
              {data.templates.length === 0 && (
                <Tr>
                  <Td colSpan={7} width={"100%"} textAlign="center">
                    {t("No types found")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
        {isLoading && (
          <Box py="5">
            <Skeleton height={"10"} width="full" endColor="teal.100" my="2"></Skeleton>
            <Skeleton height={"10"} width="full" endColor="teal.100" my="2"></Skeleton>
            <Skeleton height={"10"} width="full" endColor="teal.100" my="2"></Skeleton>
          </Box>
        )}
      </Box>
      {templateToEdit && <EditTemplateModal data={templateToEdit} onClose={() => setTemplateToEdit(undefined)} />}
    </>
  );
};
