import { useContext } from 'react'

import { getGlobalObject } from '../../getGlobalObject';
import { RumComponentContext } from '.';

export const useRumError = () => {
    const componentContext = useContext(RumComponentContext)
    const RumGlobal = getGlobalObject<Window>().DD_RUM

    if (!RumGlobal) {
        console.warn(
            '@datadog/rum-react-integration: Datadog RUM SDK is not initialized.'
        )
        throw Error('Datadog RUM has not been initialized')
    }

    return (error: unknown, customAttributes: object | undefined) => {
        RumGlobal.addError(error, {
            ...componentContext.customAttributes,
            ...customAttributes,
            react: {
                breadcrumbs: componentContext.componentBreadCrumbs,
                component: componentContext.component,
                ...(customAttributes as any)?.react,
            },
        })
    }
}
