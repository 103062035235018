import { Box, Flex, Icon } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

export const ProcessBar = ({ steps, currentStep }: { steps: string[]; currentStep: string }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentIndex(steps.findIndex((s) => s === currentStep));
  }, [steps, currentStep]);

  return (
    <Flex px={{ base: "5", md: "10" }} pt={{ base: "5", md: "10" }} pb="16" color={"teal.500"}>
      {steps.map((step, i) => (
        <Fragment key={step}>
          <Flex flexDirection={"column"} width={"6"} height="6">
            <Flex
              borderColor={i < currentIndex ? "teal.200" : "blue.100"}
              borderWidth={"2px"}
              borderStyle={"solid"}
              width={"6"}
              height="6"
              minHeight={"6"}
              rounded="full"
              alignItems={"center"}
              justifyContent={"center"}
              fontWeight={"bold"}
            >
              {i < currentIndex && <Icon height={"3"} as={FaCheck} />}
            </Flex>
            <Flex
              color={i < currentIndex ? "teal.500" : "blue.300"}
              fontSize={"small"}
              fontWeight={"bold"}
              overflow="visible"
              justifyContent={"center"}
              whiteSpace={{ base: "normal", md: "nowrap" }}
            >
              {step}
            </Flex>
          </Flex>
          {i !== steps.length - 1 && (
            <Flex grow={1} justifyContent={"center"} alignItems={"center"}>
              <Box
                width="full"
                height="0.125rem"
                bgGradient={`linear(to-r, ${i < currentIndex ? "teal.200" : "blue.100"}, ${
                  i < currentIndex - 1 ? "teal.200" : "blue.100"
                })`}
              ></Box>
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
