import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Link, Text, useToast } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { urls } from "../../urls";
import { CriticalError } from "./CriticalError/CriticalError";
import { useRumError } from "./RumComponentContext/useRumError";
import { isApiErrorDto } from "./isApiErrorDto";
import { isFetchBaseQueryError } from "./isFetchBaseQueryError";

export const useApiError = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const rumError = useRumError();

  return {
    trigger: (e: FetchBaseQueryError | SerializedError | undefined) => {
      console.error("critical error triggered", e);
      rumError({ message: "Critical error toast was triggered" }, e);
      if (e && isFetchBaseQueryError(e) && isApiErrorDto(e.data)) {
        toast({ title: t("Whooops!"), description: t(e.data.errorCode), status: "error" });
      } else {
        toast({ title: t("Whooops!"), description: t("Something went wrong!"), status: "error" });
      }
    },
    show: (e: FetchBaseQueryError | SerializedError): React.ReactElement => {
      rumError({ message: "Critical error message was displayed" }, e);
      if (isFetchBaseQueryError(e) && isApiErrorDto(e.data)) {
        return <CriticalError title={t("Whooops!") ?? undefined} message={t(e.data.errorCode) ?? undefined} />;
      } else {
        return <CriticalError title={t("Whooops!") ?? undefined} message={t("Something went wrong!") ?? undefined} />;
      }
    },
    showNoAccess: (): React.ReactElement => {
      return (
        <Box padding="10px">
          <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t("No access!")}
            </AlertTitle>
            <AlertDescription>
              {t("You don't have access to this page")}
              <Text>
                {t("You can")}&nbsp;
                <Link color="teal.500" href={urls.home}>
                  {t("click here")}
                </Link>
              </Text>
              {t("to get back to the home page")}.
            </AlertDescription>
          </Alert>
        </Box>
      );
    },
  };
};
