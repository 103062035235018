import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DeadlineSelector } from "./form-elements/DeadlineSelector";
import { Description } from "./form-elements/Description";
import { ReferenceId } from "./form-elements/ReferenceId";
import { Title } from "./form-elements/Title";

interface Props {
  nextStep: () => void;
}

export const Intro = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  return (
    <Box paddingBottom={"30px"}>
      <Box mt="33px">
        <Title />
      </Box>
      <br />
      <br />
      <ReferenceId />
      <br />
      <br />
      <Description />
      <br />
      <br />
      <DeadlineSelector />
      <br />
      <br />
      <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
        {t("Next")}
      </Button>
    </Box>
  );
};
