import { Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TipTap } from "../../../../common/input/TipTap/TipTap";

interface Props {
  description: string;
}

export const Intro = ({ description }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();

  return (
    <Flex flex={3} py="5" alignItems="start" flexDirection={"column"}>
      <Text fontSize={"sm"} fontWeight={"semibold"}>
        {t("Description")}
      </Text>
      <Collapse startingHeight="2rem" in={showFullDescription}>
        <TipTap content={description} editable={false} hasBorder={false} maxHeight="60" padding="0" />
      </Collapse>
      <Button size="sm" variant={"link"} mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
        {!showFullDescription ? t("Show more") : t("Show less")}
      </Button>
    </Flex>
  );
};
