import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BseSupplierViewDto,
  TextDocumentDto,
  useListTextDocumentsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { SharedDocumentsTable } from "../../../../common/documents/SharedDocumentsTable/SharedDocumentsTable";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { BseBid } from "./BseBid";

interface Props {
  data: BseSupplierViewDto;
  refetchEvent: () => void;
}

export const BseSupplierViewEventInfo = ({ data, refetchEvent }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();

  const { data: textDocRes } = useListTextDocumentsForSourcingEventQuery({ eventId: data.id });
  const [textDocumentToView, setTextDocumentToView] = useState<TextDocumentDto>();

  return (
    <>
      {textDocumentToView && (
        <TextDocumentModal
          textDocument={textDocumentToView}
          onClose={() => setTextDocumentToView(undefined)}
          isUpdating={false}
          editable={false}
        />
      )}
      <Flex paddingTop={"7px"} paddingRight="20px">
        <Flex flex={3} mt="10px" borderRadius="6px" alignItems="start" flexDirection={"column"}>
          <Text fontSize={"sm"} fontWeight="semibold">
            {t("Description")}
          </Text>
          <Collapse startingHeight="2rem" in={showFullDescription}>
            <TipTap content={data.description} editable={false} hasBorder={false} maxHeight="60" padding="0" />
          </Collapse>
          <Button size="sm" variant={"link"} mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
            {!showFullDescription ? t("Show more") : t("Show less")}
          </Button>
        </Flex>
      </Flex>
      <Box display={"flex"} flexDirection={"column"} py="5">
        <Text fontSize={"sm"} fontWeight="semibold" pb="4">
          {t("Uploaded documents")}
        </Text>
        <SharedDocumentsTable documents={data.documents} />
      </Box>
      <Box display={"flex"} flexDirection={"column"} py="5">
        <Text fontSize={"sm"} fontWeight="semibold" pb="4">
          {t("Text documents")}
        </Text>
        {textDocRes && (
          <TextDocumentTable documents={textDocRes.documents} isDownloadable={true} onClick={setTextDocumentToView} />
        )}
      </Box>
      <Box mt="20px">
        <BseBid event={data} refetch={refetchEvent} />
      </Box>
    </>
  );
};
