import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  useToast,
} from "@chakra-ui/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt, FaSignature } from "react-icons/fa";
import {
  ContractDto,
  TextDocumentDto,
  useCreateContractTextDocumentMutation,
  useCreateSignedDocumentMutation,
  useDeleteTextDocumentMutation,
  useListTextDocumentsForContractQuery,
  useUpdateDocumentNameMutation,
  useUpdateTextDocumentMutation,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { FileRow, FileUpload } from "../../../../common/documents/UppyUploader/FileRow";
import { useUppyUploader } from "../../../../common/documents/UppyUploader/useUppyUploader";
import { useApiError } from "../../../../common/errors/useApiError";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { Explanation } from "../../../../common/support/Explanation";
import { requireDocumentFields, requireEditingMetaFields } from "../../typeguards";
import { useContractState } from "../useContractState";
import "./Docs.scss";
import { SignerInput } from "./signatures/InvitedSigner";
import { SendForSigningModal } from "./signatures/SendForSigningModal";
import { SignedDocumentRow } from "./signatures/SignedDocumentRow";
interface Props {
  previousStep: () => void;
  nextStep: () => void;
}

export const Docs = ({ previousStep, nextStep }: Props) => {
  const [showSigningModal, setShowSigningModal] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const contractState = useContractState();
  const documentFields = requireDocumentFields(contractState.contract as ContractDto);
  const editingMetaFields = requireEditingMetaFields(contractState.contract as ContractDto);
  const [createSignedDocument] = useCreateSignedDocumentMutation();
  const [updateDocumentName] = useUpdateDocumentNameMutation();
  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateContractTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();
  const { data } = useListTextDocumentsForContractQuery({ contractId: contractState.id });
  const toast = useToast();
  const displayer = useApiError();

  const { uppy, allFiles, setDocuments, setUploads, removeUpload } = useUppyUploader({
    entityId: contractState.id,
    entityType: "Contract",
  });

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();

  useEffect(() => {
    setUploads(editingMetaFields.uploads);
  }, [editingMetaFields.uploads, setUploads]);

  useEffect(() => {
    setDocuments(documentFields.documents);
  }, [documentFields.documents, setDocuments]);

  const sendForSigning = async (values: {
    contractDocument: SelectorValue;
    signers: SignerInput[] | undefined;
  }): Promise<boolean> => {
    if (!values.signers) {
      throw new Error("Signers are not defined");
    }

    const result = await createSignedDocument({
      contractId: contractState.id,
      createSignedDocumentRequest: {
        mainDocumentId: values.contractDocument.value,
        signers: values.signers,
        language: "No",
      },
    });
    let success = false;
    if ("data" in result) {
      toast({
        title: t("Contract sent for signing"),
        status: "success",
      });
      success = true;
      await dispatch(
        loadContractThunk({
          contractId: contractState.id,
        })
      );
      setShowSigningModal(false);
    } else {
      displayer.trigger(result.error);
    }
    return success;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateName = useCallback(
    debounce(({ docId, name }: { docId: string; name: string }) => {
      updateDocumentName({
        docId,
        updateDocumentNameRequest: { name },
      });
    }, 300),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  const allDocuments = [...allFiles, ...documentFields.signedDocuments];

  return (
    <>
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
        />
      )}
      {showSigningModal && (
        <SendForSigningModal
          documents={documentFields.documents}
          sendForSigning={sendForSigning}
          onClose={() => setShowSigningModal(false)}
        />
      )}
      <FormControl pt={8}>
        <FormLabel>{t("Upload documents")}</FormLabel>
        <Box id="dnd">
          <DragDrop uppy={uppy} style={{ backgroundColor: "transparent" }} />
        </Box>
        <FormHelperText>{t("Upload your contract documents")}</FormHelperText>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Uploaded documents")}</FormLabel>
        {allDocuments.map((d) => {
          if (isFileUpload(d)) {
            return (
              <FileRow
                key={d.uploadId}
                file={d}
                name={d.name}
                remove={removeUpload}
                onUpdateName={
                  d.status === "UploadCompleted"
                    ? async (name: string) => {
                        debouncedOnUpdateName({ docId: d.documentId, name });
                        setDocuments((docs) => {
                          const i = docs.findIndex((doc) => doc.id === d.documentId);
                          const updatedDoc = { ...docs[i], name };
                          const updatedDocs = [...docs];
                          updatedDocs[i] = updatedDoc;
                          return updatedDocs;
                        });
                      }
                    : undefined
                }
              />
            );
          }
          return <SignedDocumentRow name={d.name} key={d.id} document={d} />;
        })}
        {!allDocuments.length && !data?.documents.length && (
          <FormHelperText>{t("No uploaded documents yet")}</FormHelperText>
        )}
        <FormErrorMessage>{null}</FormErrorMessage>
      </FormControl>
      {contractState.contract.owningOrgHasSignatureAccess && (
        <Flex justifyContent={"right"} pt="4">
          <Explanation
            enabled={documentFields.documents.length === 0}
            text={t("Upload at least 1 document before signing")}
          >
            <Button
              leftIcon={<Icon as={FaSignature} w="15px" h="15px" />}
              variant={"solid"}
              mr="5px"
              isDisabled={documentFields.documents.length === 0}
              colorScheme={"teal"}
              size="sm"
              onClick={() => setShowSigningModal(true)}
            >
              {t("Document signing")}
            </Button>
          </Explanation>
        </Flex>
      )}
      <FormControl pt={4}>
        <FormLabel>{t("Create text document")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingTextDocument}
            onClick={async () => {
              const res = await createTextDocument({
                createContractTextDocumentRequest: {
                  name: "",
                  text: "",
                  contractId: contractState.id,
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>{t("Create a new document from scratch or based on templates")}</FormHelperText>
        </Flex>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        {data?.documents.length ? (
          <TextDocumentTable
            documents={data.documents}
            onClick={setTextDocumentToEdit}
            onDelete={async (id: string) => {
              await deleteTextDocument({ id });
            }}
          />
        ) : null}
        {!data?.documents.length && <FormHelperText>{t("No text documents yet")}</FormHelperText>}
      </FormControl>
      <Flex pt="8" pb="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};

const isFileUpload = (e: unknown): e is FileUpload => {
  return (e as FileUpload).uploadId !== undefined;
};
