import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Select,
    Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractPriority } from '../../../../../../autogen/bff-api'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { useTranslationTools } from '../../../../../../common/useTranslationTools'
import { useContractState } from '../../../useContractState'
import { RemoveDataFieldButton } from '../RemoveDataFieldButton'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const Priority: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const contractState = useContractState()
    const [isLoading, setIsLoading] = useState(false)
    const errorMessage = containsError(contractState, 'EditPriority')
        ? t('Update failed')
        : null
    const dispatch = useAppDispatch()
    const [priority, setPriority] = useState<string>()
    const translationTools = useTranslationTools()
    const options: ContractPriority[] = [
        'VeryHigh',
        'AboveAverage',
        'Average',
        'BelowAverage',
        'VeryLow',
    ]

    useEffect(() => {
        if (contractState.contract.dataFields?.priority) {
            setPriority(contractState.contract.dataFields.priority)
        }
    }, [])

    const eventHandler = async (value: ContractPriority) => {
        setIsLoading(true)
        await dispatch(
            editContractThunk({
                command: {
                    type: 'EditPriority',
                    value: value,
                },
            })
        )
        setIsLoading(false)
    }
    return (
        <FormControl
            isInvalid={errorMessage !== null}
            isRequired={isRequiredButNotProvided}
        >
            <FormLabel htmlFor={'value'}>
                {t('Contract priority')} {isLoading && <Spinner size="xs" />}
            </FormLabel>
            <Flex w="100%">
                <Box borderRadius={'10px'} bg="white" w="100%">
                    <Select
                        value={priority}
                        onChange={(e) => {
                            setPriority(e.target.value as ContractPriority)
                            eventHandler(e.target.value as ContractPriority)
                        }}
                    >
                        {options.map((e) => (
                            <option key={e} value={e}>
                                {translationTools.getPriorityTranslation(e)}
                            </option>
                        ))}
                    </Select>
                </Box>
                <RemoveDataFieldButton removeDataField={removeDataField} />
            </Flex>
            <FormHelperText>
                {t('Set the priority level for this contract')}
            </FormHelperText>
            <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
        </FormControl>
    )
}
