import { Box, Flex, FormControl, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useGetProjectsQuery } from "../../../../../../autogen/bff-api";
import { MultiSelector } from "../../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

export const EditProjects = ({
  removeDataField,
  isRequiredButNotProvided,
}: {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}) => {
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const { data } = useGetProjectsQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<SelectorValue[]>([]);

  useEffect(() => {
    if (contractState.contract.dataFields.projects) {
      setSelectedProjects(
        contractState.contract.dataFields.projects.map((p) => ({
          label: p.name,
          value: p.id,
        }))
      );
    }
  }, [contractState.contract.dataFields.projects]);

  const eventHandler = async (projects: string[]) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditProjects",
          projects,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <FormControl isRequired={isRequiredButNotProvided}>
      <FormLabel htmlFor={"value"}>
        {t("Projects")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex w="100%">
        <Box borderRadius={"10px"} bg="white" w="100%">
          <MultiSelector
            value={selectedProjects}
            options={
              data?.projects
                .filter((o) => !contractState.contract.dataFields.projects?.map((p) => p.id).includes(o.id))
                .map((o) => ({
                  label: `${o.externalId ? `${o.externalId} - ` : ""}${o.name}`,
                  value: o.id,
                })) ?? []
            }
            noOptionsAvailableMessage={t("No contracts available")}
            onChange={(value) => {
              setSelectedProjects(value);
              eventHandler(value.map((e) => e.value));
            }}
            searchValues={false}
          />
        </Box>
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Flex>
      <FormHelperText>{t("Provide a list of projects")}</FormHelperText>
    </FormControl>
  );
};
