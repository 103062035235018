import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    setSelectedBseTab: (tab: BseTab) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: tab,
        })}`
      );
    },
    setSelectedContractsTab: (tab: ContractsTab) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: tab,
        })}`
      );
    },
    setSelectedUserManagementTab: (tab: UserManagementTab) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: tab,
        })}`
      );
    },
    setSelectedQuestion: (questionId: string) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "qa",
          question: questionId,
        })}`
      );
    },
    setSelectedAnnouncement: (announcementId: string) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "announcements",
          announcement: announcementId,
        })}`
      );
    },
    setSelectedContractTask: (taskId: string) => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "tasks",
          task: taskId,
        })}`
      );
    },
    clearSelectedContractTask: () => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "tasks",
        })}`
      );
    },
    clearSelectedQuestion: () => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "qa",
        })}`
      );
    },
    clearSelectedAnnouncement: () => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "announcements",
        })}`
      );
    },
    setCreateAnnouncement: () => {
      setSearchParams(
        `?${new URLSearchParams({
          tab: "announcements",
          createannouncement: "true",
        })}`
      );
    },
    clearSearchParams: () => {
      setSearchParams("");
    },
    get: (name: ParamNameWhiteList): string | null => searchParams.get(name),
    getContractsTab: (): ContractsTab => {
      const tab = searchParams.get("tab");
      if (tab === "info" || tab === "documents" || tab === "reminders" || tab === "tasks" || tab === "sharing") {
        return tab;
      } else {
        return "info";
      }
    },
    getEditContractStep: (): EditContractStep => {
      const step = searchParams.get("step");
      if (step === "details" || step === "docs" || step === "review") {
        return step;
      } else {
        return "details";
      }
    },
  };
};

export type EditContractStep = "details" | "docs" | "review";

export type ParamNameWhiteList =
  | "tab"
  | "question"
  | "step"
  | "secret"
  | "type"
  | "announcement"
  | "task"
  | "flow"
  | "createannouncement"
  | "search";

export type BseTab = "info" | "qa" | "announcements" | "evaluation" | "reports";
export type ContractsTab = "info" | "documents" | "reminders" | "tasks" | "sharing";
export type UserManagementTab = "users" | "invites" | "azure" | "advanced" | "api";
