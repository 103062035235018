import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { TimestampSelector } from "./TimestampSelector";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  helperText: string;
  title: string;
  datePlaceholderText: string;
  dateFormat: string;
  errorMessage: string | null;
  required?: {
    value: boolean;
    message: string;
  };
  allowPastDates?: boolean;
  showMonthYearPicker?: boolean;
}

export const FormTimestampSelector = <T extends FieldValues>({
  control,
  helperText,
  name,
  title,
  datePlaceholderText,
  dateFormat,
  errorMessage,
  required,
  allowPastDates,
  showMonthYearPicker,
}: Props<T>) => {
  return (
    <FormControl id={name} isInvalid={errorMessage !== null} isRequired={required !== undefined}>
      <FormLabel>{title}</FormLabel>
      <Flex flexDirection={"row"}>
        <Controller
          control={control}
          name={name}
          rules={{
            required: required,
          }}
          render={({ field: { onChange, value } }) => (
            <TimestampSelector
              datePlaceholderText={datePlaceholderText}
              showTimeInput={false}
              date={value}
              errorMessage={null}
              dateFormat={dateFormat}
              onDateChange={async (value) => {
                onChange(value);
              }}
              allowPastDates={allowPastDates}
              showMonthYearPicker={showMonthYearPicker}
            />
          )}
        />
      </Flex>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
