import { Box, CircularProgress, Flex, Grid, GridItem, Heading, Icon, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ConfirmEmailModal } from "../../../common/auth/ConfirmEmailModal";
import { urls } from "../../../urls";
import { PersonalProfileTask } from "./PersonalProfileTask";
import { usePersonalProfileCompletion } from "./usePersonalProfileCompletion";

export const PersonalProfile = () => {
  const navigate = useNavigate();
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const profileCompletion = usePersonalProfileCompletion();
  const { t } = useTranslation();
  return (
    <Box
      mt="15px"
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      backgroundColor="smBackground"
      padding="15px"
      mb="15px"
    >
      {showConfirmEmailModal && <ConfirmEmailModal onClose={() => setShowConfirmEmailModal(false)} />}
      <Grid
        gridTemplateColumns="50px 90px"
        gridTemplateRows="50px 1fr"
        gridTemplateAreas={`
                "icon title title"
                "unused1 progressBar progressBar"
                "unused2 progressBar progressBar"
                "unused3 progressInfo progressInfo"
                `}
      >
        <GridItem area="icon">
          <Box bg="teal.400" h="100%" borderRadius={"50%"} display="flex" alignItems={"center"} justifyContent="center">
            <Icon as={FaUser} w="18px" h="18px" color={"smBackground"} />
          </Box>
        </GridItem>
        <GridItem area="title" display={"flex"} alignItems="center">
          <Heading as="h2" fontSize="3xl" fontWeight={"semibold"} ml="10px">
            {t("Personal profile")}
          </Heading>
        </GridItem>
        <GridItem area="progressBar">
          <Flex display={"flex"} alignItems="center" mt="15px" ml="10px">
            {profileCompletion.completionPercentage === 100 ? (
              <Box
                bg="teal.500"
                borderRadius={"50%"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                w="70px"
                h="70px"
              >
                <Icon as={FaCheck} w="30px" h="30px" color={"white"} />
              </Box>
            ) : (
              <CircularProgress
                value={profileCompletion.completionPercentage}
                size="90px"
                color="teal.500"
                trackColor="smBackgroundSecondary"
                capIsRound
              />
            )}

            <Text fontSize={"3xl"} fontWeight="bold" mt="-13px" color="#718096" ml="10px">
              {profileCompletion.completionPercentage}%
            </Text>
          </Flex>
        </GridItem>
        <GridItem area="progressInfo" paddingBottom={"20px"}>
          <Flex display={"flex"} flexDirection="column" mt="30px" ml="10px" mr="10px">
            {profileCompletion.completionPercentage === 100 ? (
              <Text fontSize={"sm"} mt="-10px" color="#718096" ml="10px">
                {t("Great job! Your personal profile is 100% complete!")}
              </Text>
            ) : (
              <Text fontSize={"sm"} mt="-10px" color="#718096" ml="10px">
                {t("You personal profile is")} {profileCompletion.completionPercentage}%{" "}
                {t("complete! A good personal profile makes it easier for others to find and collaborate with you")}.
              </Text>
            )}
            <br />
            <Text fontSize={"sm"} color="#718096" ml="10px" fontWeight={"bold"}>
              {t("Profile tasks")}
            </Text>
            <br />
            <PersonalProfileTask
              title={t("Confirm your email")}
              completed={profileCompletion.emailVerified}
              onClick={() => setShowConfirmEmailModal(true)}
            />
            <br />
            <PersonalProfileTask
              title={t("Add your job title")}
              completed={profileCompletion.jobTitleProvided}
              onClick={() => {
                navigate(urls.myProfile.personal.edit);
              }}
            />
            <br />
            <PersonalProfileTask
              title={t("Add your phone number")}
              completed={profileCompletion.phoneNumberProvided}
              onClick={() => {
                navigate(urls.myProfile.personal.edit);
              }}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
