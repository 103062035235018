import { Button, Flex, Icon } from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { InvitedSigner, SignerInput } from "./InvitedSigner";

export const InvitedSigners = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<{
    signers: SignerInput[];
  }>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "signers",
  });

  return (
    <Flex backgroundColor="smBackgroundSecondary" borderRadius={"5px"} flexDirection={"column"}>
      {fields.map((field, index) => (
        <InvitedSigner
          key={field.id}
          id={field.id}
          index={index}
          remove={() => {
            remove(index);
          }}
          containsError={errors.signers !== undefined && errors.signers[index] !== undefined}
          showRemoveButton={fields.length > 1}
        />
      ))}
      <Flex pt="10px" mt={fields.length === 0 ? "-20px" : undefined} pb="20px">
        <Button
          variant={"solid"}
          size="sm"
          colorScheme={"teal"}
          leftIcon={<Icon as={FaPlus} w="15px" h="15px" />}
          onClick={() => {
            append({
              firstName: "",
              lastName: "",
              email: "",
            });
          }}
        >
          {t("Add signer")}
        </Button>
      </Flex>
    </Flex>
  );
};
