import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractStatusDto, ContractTemplateDto, useListContractFilterOptionsQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../../common/subscription/useSub";
import { useTemplateMeta } from "../../settings/ContractTemplates/useTemplateMeta";
import { MultiSelectFilter, MultiSelectOption } from "./MultiSelectFilter";

interface Props {
  selectedCounterparties: MultiSelectOption[];
  onCounterpartsChange: (values: MultiSelectOption[]) => void;
  selectedOwners: MultiSelectOption[];
  onOwnersChange: (values: MultiSelectOption[]) => void;
  selectedStatuses: MultiSelectOption[];
  onStatusesChange: (values: MultiSelectOption[]) => void;
  selectedTypes: MultiSelectOption[];
  onTypesChange: (values: MultiSelectOption[]) => void;
  templates: ContractTemplateDto[];
  selectedInternalParties: MultiSelectOption[];
  onInternalPartiesChange: (value: MultiSelectOption[]) => void;
  selectedProjects: MultiSelectOption[];
  onProjectsChange: (value: MultiSelectOption[]) => void;
}

const statuses: ContractStatusDto[] = ["Awarded", "Active", "Expired", "Terminated"];

export const ContractFilters = ({
  selectedCounterparties,
  onCounterpartsChange,
  selectedOwners,
  onOwnersChange,
  selectedStatuses,
  onStatusesChange,
  selectedTypes,
  onTypesChange,
  templates,
  selectedInternalParties,
  onInternalPartiesChange,
  selectedProjects,
  onProjectsChange,
}: Props) => {
  const { t } = useTranslation();

  const authState = useLoggedInWithOrgContextState();
  const sub = useSub();
  const { data: options, isLoading } = useListContractFilterOptionsQuery({ orgId: authState.selectedOrg.id });

  const templateMeta = useTemplateMeta();

  if (isLoading)
    return (
      <Stack width="full">
        <Skeleton height="5" width="full"></Skeleton>
        <Skeleton height="5" width="full"></Skeleton>
      </Stack>
    );

  return (
    <Flex width="full" flexWrap="wrap" columnGap="1" rowGap="1">
      {sub.hasProjectAccess ? (
        <Flex flex={1} minWidth="40">
          <MultiSelectFilter
            placeholder={t("Project")}
            options={
              options?.projects.map((o) => ({
                label: `${o.externalId ? `${o.externalId} - ` : ""}${o.name}`,
                value: o.id,
              })) ?? []
            }
            value={selectedProjects}
            onChange={(values) => onProjectsChange(values)}
          />
        </Flex>
      ) : null}
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Counterparty")}
          options={options?.organizations.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedCounterparties}
          onChange={(values) => onCounterpartsChange(values)}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Internal party")}
          options={options?.organizations.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedInternalParties}
          onChange={onInternalPartiesChange}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Responsible")}
          options={options?.persons.map((o) => ({ label: o.name, value: o.id })) ?? []}
          value={selectedOwners}
          onChange={(values) => onOwnersChange(values)}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Status")}
          options={statuses.map((status) => ({
            label: t(status),
            value: status,
          }))}
          value={selectedStatuses}
          onChange={(values) => onStatusesChange(values)}
        />
      </Flex>
      <Flex flex={1} minWidth="40">
        <MultiSelectFilter
          placeholder={t("Type")}
          options={templates.map((template) => ({
            label: templateMeta.getTemplateName(template),
            value: template.id,
          }))}
          value={selectedTypes}
          onChange={(values) => onTypesChange(values)}
        />
      </Flex>
    </Flex>
  );
};
