import { useLoggedInWithOrgContextState } from "../auth/useLoggedInWithOrgContextState";

export const useSub = () => {
  const authState = useLoggedInWithOrgContextState();

  if (!authState) {
    throw Error("Auth state with org context not available");
  }

  return {
    hasContractAccess: authState.selectedOrg.subscription.hasContractAccess,
    hasBuyerAccess: authState.selectedOrg.subscription.hasBuyerAccess,
    hasSignatureAccess: authState.selectedOrg.subscription.hasSignatureAccess,
    canCreateEvents: authState.selectedOrg.canCreateEvent,
    canCreateContracts: authState.selectedOrg.canCreateContract,
    hasProductAccess: authState.selectedOrg.hasProductAccess,
    hasProjectAccess: authState.selectedOrg.hasProjectAccess,
    hasAiAccess: authState.selectedOrg.hasAiAccess,
    hasDocumentTemplateAccess: authState.selectedOrg.hasDocumentTemplateAccess,
  };
};
