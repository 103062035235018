import { Button, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { FaNetworkWired } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { useSub } from "../../../subscription/useSub";

export const Projects = () => {
  const navigate = useNavigate();
  const sub = useSub();
  const authState = useLoggedInWithOrgContextState();

  return sub.hasProjectAccess && authState.selectedOrg.canEdit ? (
    <Button
      leftIcon={<Icon as={FaNetworkWired} w="15px" h="15px" />}
      colorScheme="teal"
      variant="ghost"
      size={"sm"}
      marginRight={1}
      onClick={() => {
        navigate(urls.projects);
      }}
    >
      {t("Projects")}
    </Button>
  ) : null;
};
