import { Button, Checkbox, Flex, FormControl, FormErrorMessage, Icon, Textarea, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import { BseQuestionDto } from "../../../../../autogen/bff-api";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { Explanation } from "../../../../../common/support/Explanation";

interface Props {
  addCommentDisabledReason: string | null;
  question: BseQuestionDto;
  addComment: (values: { comment: string; createAnnouncementDraft: boolean }) => Promise<boolean>;
  showCreateAnnouncementDraft: boolean;
}

interface FormValues {
  createAnnouncementDraft: boolean;
  comment: string;
}

export type QuestionRole = "buyer" | "supplier";

export const AddSupplierQuestionComment = ({
  addCommentDisabledReason,
  question,
  addComment,
  showCreateAnnouncementDraft,
}: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      createAnnouncementDraft: false,
      comment: "",
    },
  });

  const currentValue = watch("comment");

  return (
    <Explanation enabled={addCommentDisabledReason !== null} text={addCommentDisabledReason}>
      <form
        onSubmit={handleSubmit(async (values: FormValues) => {
          setIsLoading(true);
          const success = await addComment({
            comment: values.comment,
            createAnnouncementDraft: values.createAnnouncementDraft,
          });
          setIsLoading(false);
          if (success) {
            setValue("comment", "");
            toast({
              title: t("Comment added!"),
              status: "success",
            });
          } else {
            toast({
              title: t("Could not add comment!"),
              status: "error",
            });
          }
        })}
      >
        <FormControl mt="15px" isInvalid={errors.comment !== undefined}>
          <Textarea
            isDisabled={addCommentDisabledReason !== null}
            placeholder={t("Add comment to") + ' "' + displayWrappedWithEllipsis(question.subject, 20) + '"'}
            {...register("comment", {
              minLength: {
                value: 1,
                message: t("Must be at least 1 character long"),
              },
            })}
          />
          <FormErrorMessage>{errors.comment && errors.comment.message}</FormErrorMessage>
        </FormControl>
        <Flex flexDirection={"column"} alignItems="end">
          {showCreateAnnouncementDraft && (
            <Flex mt="5px" mb="10px">
              <FormControl isInvalid={errors.createAnnouncementDraft !== undefined} mt="10px">
                <Controller
                  name="createAnnouncementDraft"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Checkbox isChecked={value} onChange={onChange} isDisabled={currentValue.trim().length < 1}>
                        {t("Create draft for announcement")}
                      </Checkbox>
                    );
                  }}
                />
                <FormErrorMessage>
                  {errors.createAnnouncementDraft && errors.createAnnouncementDraft.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          )}
          <Flex pt="5px">
            <Button
              isDisabled={addCommentDisabledReason !== null || currentValue.trim().length < 1}
              leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" mt="-2px" />}
              variant={"solid"}
              colorScheme="teal"
              size={"sm"}
              isLoading={isLoading}
              type="submit"
            >
              {t("Send")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Explanation>
  );
};
