import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TerminationDateDto } from "../../../../autogen/bff-api";
import { displayDate } from "../../../../common/formatting/displayDate";

interface Props {
  data: TerminationDateDto;
}

export const TerminationDate = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Box padding="15px" w="100%">
      <Text fontWeight={"bold"} fontSize="sm" color="black">
        {t("Contract terminated!")}
      </Text>
      <Divider marginTop={"5px"} marginBottom={"5px"} />
      <Flex flexDirection={"column"}>
        <Text fontWeight={"regular"} fontSize="sm" color="black">
          {`${t("This contract was terminated on")} ${displayDate(data.date)} ${t("with the following reason")}:`}
        </Text>
        <Text fontWeight={"regular"} fontSize={"sm"} color="black" fontStyle={"italic"} whiteSpace={"pre-line"}>
          {data.reason}
        </Text>
      </Flex>
    </Box>
  );
};
