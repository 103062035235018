import { Flex, Skeleton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContractTaskStatus, useListContractsQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { MultiSelectFilter, MultiSelectOption } from "../../view-all-published/filtering/MultiSelectFilter";
import { displayPersonName } from "../../view-single/sharing/AddParticipantModal";

interface Props {
  selectedResponsiblePersons: MultiSelectOption[];
  onSelectedResponsiblePersonsChange: (values: MultiSelectOption[]) => void;
  selectedContracts: MultiSelectOption[];
  onSelectedContractsChange: (values: MultiSelectOption[]) => void;
  selectedStatuses: MultiSelectOption[];
  onSelectedStatusesChange: (values: MultiSelectOption[]) => void;
}

export const TaskFilters = ({
  selectedResponsiblePersons,
  onSelectedResponsiblePersonsChange,
  selectedContracts,
  onSelectedContractsChange,
  selectedStatuses,
  onSelectedStatusesChange,
}: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const { data, isLoading } = useListContractsQuery({ orgId: authState.selectedOrg.id, state: "Published" });

  const statuses: ContractTaskStatus[] = ["Open", "Completed"];

  const distinctValuesFilter = (option: MultiSelectOption, i: number, arr: MultiSelectOption[]) =>
    arr.findIndex((t) => t.value === option.value) === i;

  return (
    <Flex w="full">
      {data && (
        <Flex w="full">
          <Flex flex={1}>
            <MultiSelectFilter
              placeholder={t("Responsible")}
              options={authState.selectedOrg.users.map((e) => ({
                label: displayPersonName(e.person),
                value: e.person.id,
              }))}
              value={selectedResponsiblePersons}
              onChange={(values) => onSelectedResponsiblePersonsChange(values)}
            />
          </Flex>
          <Flex flex={1}>
            <MultiSelectFilter
              placeholder={t("Contract")}
              options={data.contracts
                .map((e) => ({
                  label: e.title,
                  value: e.id,
                }))
                .filter(distinctValuesFilter)}
              value={selectedContracts}
              onChange={(values) => onSelectedContractsChange(values)}
            />
          </Flex>
          <Flex flex={1}>
            <MultiSelectFilter
              placeholder={t("Status")}
              options={statuses.map((status) => ({
                label: t(status),
                value: status,
              }))}
              value={selectedStatuses}
              onChange={(values) => onSelectedStatusesChange(values)}
            />
          </Flex>
        </Flex>
      )}
      {isLoading && !data && (
        <Skeleton
          height="8"
          width="full"
          rounded="md"
          startColor="smBackgroundSecondary"
          endColor="smBackgroundTertiary"
        />
      )}
    </Flex>
  );
};
