import { debounce } from "lodash";
import { useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";
import { GenericDateField } from "./GenericDateField";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const StartDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const date = dataFields.startDate ?? null;
  const errorMessage = containsError(contractState, "EditStartDate") ? t("Update failed") : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDateChange = useCallback(
    debounce(async (newDate) => {
      await dispatch(editContractThunk({ command: { type: "EditStartDate", value: newDate } }));
    }, 300),
    []
  );

  return (
    <GenericDateField
      id={"startDate"}
      title={t("Start date")}
      placeHolderText={""}
      helperText={t("Provide a start date")}
      date={date}
      errorMessage={errorMessage}
      onDateChange={(newDate) => {
        handleDateChange(newDate);
      }}
      removeDataField={removeDataField}
      isRequiredButNotProvided={isRequiredButNotProvided}
    />
  );
};
