import { Box, Button, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaBuilding, FaLink, FaMapMarkerAlt, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { OrgPublicProfileDto } from "../../../../autogen/bff-api";
import { displayOrganizationSize } from "../../../../common/formatting/displayOrganizationSize";
import { urls } from "../../../../urls";

interface Props {
  organization: OrgPublicProfileDto;
}

export const CompanyPitch = ({ organization }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={"column"}
      alignItems="start"
      justifyContent={"start"}
      borderRadius={"10px"}
      boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
      padding="6"
      w="full"
    >
      <Box w="100%">
        <Flex w="100%">
          <Flex w="60px" h="60px" borderRadius={"4px"} border={"1px solid #e1e6ee"} padding={"2px"}>
            <Box
              bg="teal.500"
              borderRadius={"4px"}
              w="100%"
              h="100%"
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              <Text fontWeight={"bold"} fontSize="xl" color="white">
                {organization.name.charAt(0)}
              </Text>
            </Box>
          </Flex>
          <Flex flex={1} alignItems={"center"} justifyContent="end">
            <Button
              leftIcon={<Icon as={FaBuilding} w="15px" h="15px" />}
              variant={"outline"}
              colorScheme="teal"
              size={"sm"}
              onClick={() => navigate(urls.organizations.profile.go(organization.id))}
            >
              {t("View profile")}
            </Button>
          </Flex>
        </Flex>
        <Flex mt="7px">
          <Icon as={FaBuilding} w="15px" h="15px" mt="4px" color={"#717171"} />
          <Text fontSize={"sm"} color="#717171" ml="10px">
            {organization.name}
          </Text>
        </Flex>
        <Flex mt="7px">
          <Icon as={FaMapMarkerAlt} w="15px" h="15px" mt="4px" color={"#717171"} />
          <Text fontSize={"sm"} color="#717171" ml="10px">
            {organization.country.name}
          </Text>
        </Flex>
        {organization.size && (
          <Flex mt="6px">
            <Icon as={FaUsers} w="15px" h="15px" mt="3px" color={"#717171"} />
            <Text fontSize={"sm"} color="#717171" ml="10px">
              {displayOrganizationSize(organization.size)}
            </Text>
          </Flex>
        )}
        {organization.homePageUrl && (
          <Flex mt="5px">
            <Icon as={FaLink} w="13px" h="15px" mt="4px" ml="1px" color={"#717171"} />
            <Text ml="11px" fontSize={"sm"}>
              <Link color="teal.500" href={organization.homePageUrl} target={"_blank"}>
                {organization.homePageUrl.replace("https://", "")}
              </Link>
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
