import { Box } from "@chakra-ui/react";
import { Select, useChakraSelectProps } from "chakra-react-select";
import { useTranslation } from "react-i18next";

export interface MultiSelectOption {
  label: string;
  value: string;
}

interface Props {
  placeholder: string;
  options: {
    value: string;
    label: string;
  }[];
  value: MultiSelectOption[];
  onChange: (values: MultiSelectOption[]) => void;
}

export const MultiSelectFilter = ({ placeholder, options, value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      {...useChakraSelectProps({
        size: "sm",
        isMulti: true,
        value: value,
        onChange: (e) => {
          onChange(
            e.map((e) => ({
              label: e.label,
              value: e.value,
            }))
          );
        },
        isClearable: false,
        selectedOptionStyle: "check",
        selectedOptionColor: "blue",
        placeholder: <Box color={"smMuted"}>{placeholder}</Box>,
        noOptionsMessage: () => <Box>{t("No available options")}</Box>,
      })}
      options={options}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
          rounded: "md",
        }),
        container: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
          borderColor: "smBorder",
          width: "full",
          mr: "1",
        }),
        menuList: (provided) => ({
          ...provided,
          rounded: "md",
        }),
      }}
    />
  );
};
