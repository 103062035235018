import { Flex, Grid, GridItem, MenuItem, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrganizationRoleType } from "../../../../autogen/bff-api";
import { ProfileImageRounded } from "../../../../pages/sourcing-events/buyer/view-all/ProfileImageRounded";
import { urls } from "../../../../urls";
import { useLoggedInWithOrgContextState } from "../../../auth/useLoggedInWithOrgContextState";
import { displayWrappedWithEllipsis } from "../../../formatting/displayWrappedWithEllipsis";

export const PersonalProfileMenuItem: React.FC = () => {
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();
  const fullName = `${authState.me.firstName} ${authState.me.lastName}`;
  const { t } = useTranslation();

  const roleToName = (role: OrganizationRoleType): string => {
    switch (role) {
      case "Admin":
        return t("Administrator");
      case "Owner":
        return t("Organization owner");
      case "SourcingCreator":
        return t("Sourcing Creator");
      case "SourcingViewer":
        return t("Sourcing Viewer");
      case "ContractCreator":
        return t("Contract Creator");
      case "ContractViewer":
        return t("Contract Viewer");
    }
  };

  return (
    <MenuItem
      onClick={() => {
        navigate(urls.myProfile.personal.view);
      }}
    >
      <Grid
        h="100%"
        gridTemplateColumns={`60px 2fr`}
        gridTemplateRows={`20px 20px`}
        templateAreas={`
                "image name"
                "image email"
                "image role"
                `}
        pb="9px"
        pt="5px"
      >
        <GridItem area="image" display={"flex"} alignItems="center">
          <Flex pt="3px">
            <ProfileImageRounded name={authState.me.firstName} backgroundColor="teal.400" width="50px" />
          </Flex>
        </GridItem>
        <GridItem area="name">
          <Text fontSize="md">
            <b>{displayWrappedWithEllipsis(fullName, 30)}</b>
          </Text>
        </GridItem>
        <GridItem area="email">
          <Text fontSize="sm" color="smMuted">
            {displayWrappedWithEllipsis(authState.me.email, 30)}
          </Text>
        </GridItem>
        <GridItem area="role">
          <Text fontSize="sm">{authState.selectedOrg.roles.map((e) => roleToName(e)).join(", ")}</Text>
        </GridItem>
      </Grid>
    </MenuItem>
  );
};
