import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TimestampSelector } from "../../../../../../common/input/TimestampSelector/TimestampSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { requireDataFields } from "../../../../typeguards";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

interface FormValues {
  date: string;
  reason: string;
}

export const TerminationDate = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contractState = useContractState();
  const dataFields = requireDataFields(contractState.contract);
  const date = dataFields.terminationDate?.date;
  const reason = dataFields.terminationDate?.reason;
  let errorMessage = containsError(contractState, "AddTerminationDate") ? t("Update failed") : null;
  const methods = useForm<FormValues>({
    defaultValues: {
      date: date ?? undefined,
      reason: reason ?? undefined,
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEditable, setIsEditable] = useState(!date);

  const addTerminationDate = async (values: FormValues) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "AddTerminationDate",
          value: {
            date: values.date,
            reason: values.reason,
          },
        },
      })
    );
    setIsEditable(false);
    setIsLoading(false);
  };
  if (methods.formState.errors.date?.message) {
    errorMessage = methods.formState.errors.date.message;
  } else if (methods.formState.errors.reason?.message) {
    errorMessage = methods.formState.errors.reason.message;
  }

  return (
    <form onSubmit={methods.handleSubmit(addTerminationDate)}>
      <FormControl id={"terminationDate"} isInvalid={!!errorMessage} isRequired={isRequiredButNotProvided}>
        <FormLabel>
          {t("Termination date")}
          {isLoading && <Spinner ml="5px" size="xs" />}
        </FormLabel>

        <Flex flexDirection={"column"}>
          <Flex width="100%">
            <Controller
              name="date"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t("Please select a date"),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TimestampSelector
                    isDisabled={!isEditable}
                    datePlaceholderText={t("Provide a termination date")}
                    date={value}
                    showTimeInput={false}
                    errorMessage={errorMessage}
                    dateFormat={"dd MMM yyyy"}
                    allowPastDates={true}
                    onDateChange={async (newDate) => {
                      onChange(newDate);
                    }}
                  />
                );
              }}
            />
            <RemoveDataFieldButton removeDataField={removeDataField} />
          </Flex>
          <Flex flex={1} mt="5px">
            <Controller
              name="reason"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t("Please provide a reason"),
                },
                minLength: {
                  value: 2,
                  message: t("Please provide a reason"),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Textarea
                    id={"reason"}
                    isDisabled={!isEditable}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={t("Provide a termination reason") ?? ""}
                    bg="white"
                    value={value}
                  />
                );
              }}
            />
          </Flex>
        </Flex>
        <FormHelperText>{t("Provide a termination date and a reason for the termination")}</FormHelperText>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
        <Flex pt="4" justifyContent={"end"}>
          <Button
            variant={"outline"}
            colorScheme="blue"
            isDisabled={isEditable}
            onClick={() => setIsEditable(true)}
            mr="2"
          >
            {t("Edit")}
          </Button>
          <Button type="submit" variant={"solid"} isDisabled={!isEditable} isLoading={isLoading} colorScheme="red">
            {t("Terminate")}
          </Button>
        </Flex>
      </FormControl>
    </form>
  );
};
