import { BackgroundProps, Flex, Text, TypographyProps } from "@chakra-ui/react";
import React from "react";
interface Props {
  name?: string;
  backgroundColor: BackgroundProps["bg"];
  width: string;
  fontSize?: TypographyProps["fontSize"];
}

export const ProfileImageRounded: React.FC<Props> = ({ backgroundColor, name, width, fontSize }) => {
  return (
    <Flex
      w={width}
      height={width}
      borderRadius="50%"
      bg={backgroundColor}
      alignItems={"center"}
      justifyContent="center"
    >
      <Text fontSize={fontSize ?? "md"} color="white">
        <b>{name?.charAt(0)}</b>
      </Text>
    </Flex>
  );
};
