import { Checkbox, Icon, IconButton, Input, Td, Textarea, Tr } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback } from "react";
import { FaTrash } from "react-icons/fa";
import { Populater } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editProduct as editProductState, removeProduct } from "../../../../../common/redux/reducers/productReducer";
import { editProductGroupThunk } from "../../../../../common/redux/thunks/product/editProductGroupThunk";
import { Product, ProductField, ProductFieldType } from "../../../../../common/types";

export const ProductRowForm = ({ groupId, product }: { groupId: string; product: Product }) => {
  const dispatch = useAppDispatch();

  const handleFieldChange = ({
    id,
    name,
    type,
    isRequired,
    populatedBy,
    value,
  }: {
    id: string;
    name: string;
    type: ProductFieldType;
    value: string | boolean;
    isRequired: boolean;
    populatedBy: Populater;
  }) => {
    const productFields = [...product.productFields];
    const fieldIndex = product.productFields.findIndex((f) => f.name === name);
    productFields.splice(fieldIndex, 1, {
      id,
      name,
      type,
      isRequired,
      populatedBy,
      value: parseInputValue(value, type),
    });
    handleChange({ groupId, product: { ...product, productFields } });
  };

  const handleQuantityChange = (value: string) => {
    handleChange({
      groupId,
      product: { ...product, quantity: { ...product.quantity, value: value === "" ? undefined : Number(value) } },
    });
  };

  const handleChange = ({ groupId, product }: { groupId: string; product: Product }) => {
    dispatch(editProductState({ groupId, product }));
    debouncedEditProduct({ groupId, product });
  };

  const editProduct = useCallback(
    async ({ groupId, product }: { groupId: string; product: Product }) => {
      await dispatch(
        editProductGroupThunk({
          id: groupId,
          editProduct: {
            id: product.id,
            quantity: product.quantity,
            productFields: product.productFields.map((field) => ({
              id: field.id,
              name: field.name,
              value: field.value ? field.value : undefined,
              populatedBy: field.populatedBy,
              isRequired: field.isRequired,
              type: field.type,
            })),
          },
        })
      );
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEditProduct = useCallback(
    debounce(({ groupId, product }: { groupId: string; product: Product }) => editProduct({ groupId, product }), 500),
    [editProduct]
  );

  const parseInputValue = (value: string | boolean, type: ProductField["type"]) => {
    if (type === "Number") return value === "" ? "" : Number(value);
    if (type === "Boolean") return Boolean(value);
    else return value;
  };

  return (
    <Tr>
      {product.productFields
        .filter((field) => field.populatedBy === "Buyer")
        .map((field) => (
          <Td key={field.name} verticalAlign={"top"} p="1">
            {field.type === "Boolean" ? (
              <Checkbox
                colorScheme="teal"
                isChecked={field.value as boolean}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.checked,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : field.type === "Text" ? (
              <Textarea
                backgroundColor="smBackground"
                size="xs"
                rounded="md"
                width="full"
                minHeight={"6"}
                rows={1}
                pt="1"
                pb="0"
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            ) : (
              <Input
                backgroundColor="smBackground"
                size="xs"
                rounded="md"
                type={"number"}
                isRequired={field.isRequired}
                value={(field.value as string | number) ?? ""}
                onChange={(e) =>
                  handleFieldChange({
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: e.target.value,
                    isRequired: field.isRequired,
                    populatedBy: field.populatedBy,
                  })
                }
              />
            )}
          </Td>
        ))}
      <Td verticalAlign={"top"} p="1">
        <Input
          backgroundColor="smBackground"
          size="xs"
          type={"number"}
          isRequired={product.quantity.isRequired}
          value={product.quantity.value ?? ""}
          rounded="md"
          onChange={(e) => handleQuantityChange(e.target.value)}
        />
      </Td>
      <Td width="20" textAlign={"right"} verticalAlign={"top"} pt="1" pr="2">
        <IconButton
          aria-label="Delete product"
          size="xs"
          width="6"
          variant={"ghost"}
          color="gray.400"
          icon={<Icon as={FaTrash} />}
          onClick={() => {
            if (product.id) {
              dispatch(
                editProductGroupThunk({
                  id: groupId,
                  deleteProduct: { productId: product.id },
                })
              );
            }
            dispatch(removeProduct({ groupId, productId: product.id }));
          }}
        />
      </Td>
    </Tr>
  );
};
