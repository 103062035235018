import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { eventIsLive } from "../../../../eventIsLive";
import { useBasicSourcingEventState } from "../../../../useBasicSourcingEventState";
import styles from "./DeadlineSelector.module.scss";
import "./DeadlineSelector.scss";

export const DeadlineSelector = () => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment>();
  const [selectedTimezone, setSelectedTimezone] = useState<string>("");
  const dispatch = useAppDispatch();
  const eventState = useBasicSourcingEventState();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const deadline = eventState.deadline.value;
  const timezone = eventState.timezone.value;
  const errorMessage = eventState.deadline.errorMessage || eventState.timezone.errorMessage;
  const timezones = moment.tz.names();
  useEffect(() => {
    setSelectedDate(deadline ? moment(deadline) : undefined);
    setSelectedTimezone(timezone);
  }, []);

  const deadlineEventHandler = async (value: Date) => {
    setIsLoading(true);

    await dispatch(
      editBseThunk({
        command: {
          type: "EditDeadline",
          value: value.toISOString(),
        },
      })
    );
    setIsLoading(false);
  };

  const debouncedDeadlineEventHandler = useMemo(
    () =>
      debounce(async (value: Date) => {
        await deadlineEventHandler(value);
      }, 300),
    []
  );

  const timezoneEventHandler = async (value: string) => {
    setIsLoading(true);
    await dispatch(
      editBseThunk({
        command: {
          type: "EditTimezone",
          value: value,
        },
      })
    );
    setIsLoading(false);
  };

  const debouncedTimezoneEventHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await timezoneEventHandler(value);
      }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedDeadlineEventHandler.cancel();
      debouncedTimezoneEventHandler.cancel();
    };
  }, []);

  const valuesHaveChanged = (): boolean => {
    const existingDeadline = deadline ? moment(deadline).toISOString() : undefined;
    const newDeadline = selectedDate?.toISOString();

    const existingTimezone = timezone;
    const newTimezone = selectedTimezone;

    if (existingDeadline !== newDeadline) {
      return true;
    } else if (existingTimezone !== newTimezone) {
      return true;
    } else {
      return false;
    }
  };

  const eventIsPublished = eventIsLive(eventState.event);
  const valuesHaveBeenChanged = valuesHaveChanged();
  const showSaveButton = eventIsPublished && valuesHaveBeenChanged;

  return (
    <FormControl id="description" isInvalid={errorMessage !== null} isRequired>
      <FormLabel>
        {t("Deadline")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Flex flexDirection={"row"}>
        <DatePicker
          wrapperClassName={errorMessage !== null ? styles.datePickerError : styles.datePickerBase}
          calendarClassName={styles.calendar}
          showTimeInput
          selected={selectedDate?.toDate()}
          placeholderText={t("Please select a deadline") ?? ""}
          dateFormat="dd MMM yyyy HH:mm"
          onChange={(newValue) => {
            if (newValue) {
              try {
                const modifiedNewValue = moment(newValue);
                if (!selectedDate) {
                  modifiedNewValue.endOf("day").seconds(59);
                }
                setSelectedDate(modifiedNewValue);
                if (newValue && !eventIsPublished) {
                  debouncedDeadlineEventHandler(modifiedNewValue.toDate());
                }
              } catch (e) {
                console.log("could not parse date");
              }
            }
          }}
        />
        <Select
          placeholder={t("Change timezone") ?? ""}
          ml="3px"
          value={selectedTimezone}
          onChange={(e) => {
            setSelectedTimezone(e.target.value);
            if (!eventIsPublished) {
              debouncedTimezoneEventHandler(e.target.value);
            }
          }}
        >
          {timezones.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </Select>
      </Flex>
      <FormHelperText>{t("Please select a deadline and a timezone for the event")}</FormHelperText>
      {showSaveButton && (
        <Button
          mt="10px"
          leftIcon={<Icon as={FaSave} w="15px" h="15px" mt="-2px" />}
          variant={"solid"}
          colorScheme="teal"
          size={"sm"}
          isLoading={isLoading}
          onClick={async () => {
            if (selectedDate) {
              await deadlineEventHandler(selectedDate.toDate());
            }
            await timezoneEventHandler(selectedTimezone);
          }}
        >
          {t("Save")}
        </Button>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
