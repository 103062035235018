import { Box, Button, Flex, GridItem, Heading, Icon, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaHourglass } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ContractStatusDto, ContractTemplateMinDto, CounterpartyDto } from "../../../autogen/bff-api";
import { displayDate } from "../../../common/formatting/displayDate";
import { urls } from "../../../urls";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { ContractStatusTag } from "../edit/ContractStatusTag";
import { ContractTemplateTag } from "../edit/ContractTemplateTag";
import { getCounterpartyName } from "../view-all-published/PublishedContractRow";

interface Props {
  id: string;
  title: string;
  counterparty: CounterpartyDto;
  status: ContractStatusDto;
  expirationDate: string | null;
  canEdit: boolean;
  template: ContractTemplateMinDto | null;
}

export const ContractMeta = ({ id, title, counterparty, status, expirationDate, canEdit, template }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const counterpartyName = getCounterpartyName(counterparty);

  return (
    <>
      <GridItem area={"title"} display="flex" alignItems={"end"} pt="10px">
        <Heading as="h2" size="md">
          {title}
        </Heading>
        <Box ml="10px">
          <ContractStatusTag status={status} />
        </Box>
        {template && (
          <Box ml="10px">
            <ContractTemplateTag template={template} />
          </Box>
        )}
        <Spacer />
      </GridItem>
      {canEdit && (
        <GridItem area="edit" display={"flex"} justifyContent="end" paddingTop={"24px"}>
          <Button
            leftIcon={<Icon as={FaEdit} w="15px" h="15px" mt="-2px" />}
            variant={"outline"}
            colorScheme="teal"
            size={"sm"}
            onClick={() => navigate(urls.contracts.edit.go(id).details)}
          >
            {t("Edit")}
          </Button>
        </GridItem>
      )}
      <GridItem area={"createdMeta"}>
        <Flex mt="9px">
          <Flex>
            <ProfileImageRounded
              name={counterpartyName}
              width="20px"
              backgroundColor="blue.500"
              fontSize={"xx-small"}
            />
          </Flex>
          <Flex justifyContent={"center"} mt="-3px" ml="5px">
            <Text fontSize={"lg"}>{counterpartyName}</Text>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        area={"deadline"}
        borderBottom="1px solid #cbcccc"
        display={"flex"}
        alignItems="center"
        paddingBottom="20px"
        paddingTop="3px"
      >
        {expirationDate && (
          <Flex alignItems="center">
            <Flex paddingLeft={"3px"}>
              <Icon as={FaHourglass} color="red.600" w="14px" h="14px" />
            </Flex>
            <Flex ml="8px">
              <Text fontSize={"lg"} color="red.600" fontWeight={"bold"}>
                {`${t("Expiry date")}: ${displayDate(expirationDate)}`}
              </Text>
            </Flex>
            <Spacer />
          </Flex>
        )}
      </GridItem>
    </>
  );
};
