import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractTaskDto } from "../../../autogen/bff-api";
import { ShowTaskModal } from "../view-single/tasks/show";
import { TaskRow } from "./TaskRow";

interface Props {
  tasks: ContractTaskDto[];
}

export const TasksTable = ({ tasks }: Props) => {
  const { t } = useTranslation();
  const [showTaskId, setShowTaskId] = useState<string>();

  return (
    <Box borderRadius={"lg"} border="1px solid" borderColor="smBorder">
      {showTaskId && <ShowTaskModal taskId={showTaskId} onClose={() => setShowTaskId(undefined)} />}
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>{t("Title")}</Th>
            <Th w="140px">{t("Due date")}</Th>
            <Th w="200px">{t("Responsible")}</Th>
            <Th>{t("Contract")}</Th>
            <Th>{t("Contract owner")}</Th>
            <Th>{t("Status")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <>
            {tasks.map((e) => {
              return <TaskRow key={e.id} task={e} showTask={(e) => setShowTaskId(e)} />;
            })}
            {tasks.length === 0 && (
              <Tr>
                <Td colSpan={7} width={"100%"} textAlign="center">
                  {t("No tasks found")}
                </Td>
              </Tr>
            )}
          </>
        </Tbody>
      </Table>
    </Box>
  );
};
