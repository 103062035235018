import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ListSourcingEventsFilterStatus, useListEventsCreatedByDepartmentQuery } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { useSkeletons } from "../../../../common/useSkeletons";
import { EventRow } from "./EventRow";

interface Props {
  subtitle: string;
  status: ListSourcingEventsFilterStatus;
}

export const SourcingEventsTableForBuyer = ({ subtitle, status }: Props) => {
  const confirmationModal = useConfirmationModal();
  const authState = useLoggedInWithOrgContextState();
  const { data, error } = useListEventsCreatedByDepartmentQuery({
    depId: authState.selectedOrg.departments[0].id,
    status,
  });
  const { t } = useTranslation();
  const skeletons = useSkeletons();
  const displayer = useApiError();

  if (error) return displayer.show(error);

  return (
    <>
      {confirmationModal.modal}
      <Box mt="15px">
        <Text fontSize="medium" color="smMuted">
          {subtitle}
        </Text>
      </Box>
      <Box rounded="lg" mt="5" border="1px solid" borderColor="smBorder">
        {!data && skeletons.stackedLines(20, 10)}
        {data && (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("Event name")}</Th>
                <Th>{t("Internal reference")}</Th>
                <Th>{t("Created by")}</Th>
                <Th width={"150px"}>{t("Deadline")}</Th>
                <Th>{t("Status")}</Th>
                {status !== "Drafts" && <Th>{t("Winner")}</Th>}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              <>
                {data.events.map((e) => (
                  <EventRow key={e.id} event={e} confirmationModal={confirmationModal} />
                ))}
                {data.events.length === 0 && (
                  <Tr>
                    <Td colSpan={6} width={"100%"} textAlign="center">
                      {t("No events have been created")}
                    </Td>
                  </Tr>
                )}
              </>
            </Tbody>
          </Table>
        )}
      </Box>
    </>
  );
};
