import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadDto, ViewDocumentAsOwnerDto, useUpdateDocumentNameMutation } from "../../../autogen/bff-api";
import { FileRow } from "./FileRow";
import { SustainabilityAnalysisModal } from "./SustainabilityAnalysisModal";
import { DocumentUploadContext } from "./useDocumentsUploader";
import { useUppyUploader } from "./useUppyUploader";

interface Props {
  uploadEntityType: DocumentUploadContext;
  entityId: string;
  uploads: UploadDto[];
  documents: ViewDocumentAsOwnerDto[];
  isRequired?: boolean;
}

export const UppyUploader = ({ documents, uploads, uploadEntityType, entityId, isRequired = true }: Props) => {
  const { t } = useTranslation();
  const [updateDocumentName] = useUpdateDocumentNameMutation();
  const { uppy, allFiles, setDocuments, setUploads, removeUpload } = useUppyUploader({
    entityId: entityId,
    entityType: uploadEntityType,
  });
  useEffect(() => {
    setUploads(uploads);
  }, [setUploads, uploads]);

  useEffect(() => {
    setDocuments(documents);
  }, [documents, setDocuments]);
  const [showSusModal, setShowSusModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateName = useCallback(
    debounce(({ docId, name }: { docId: string; name: string }) => {
      updateDocumentName({
        docId,
        updateDocumentNameRequest: { name },
      });
    }, 300),
    []
  );

  const finishedUploads = allFiles.filter((e) => e.status === "UploadCompleted");

  return (
    <>
      {showSusModal && (
        <SustainabilityAnalysisModal fileRows={finishedUploads} onClose={() => setShowSusModal(false)} />
      )}
      <FormControl mt={8} h="100%" isInvalid={false} isRequired={isRequired}>
        <FormLabel>{t("Add documents")}</FormLabel>
        <div id="dnd">
          <DragDrop style={{ backgroundColor: "transparent" }} uppy={uppy} />
        </div>
        <FormHelperText>{t("Upload documents describing the requirements of this sourcing event")}</FormHelperText>
        <br />
        <FormLabel>{t("Added documents")}</FormLabel>
        {allFiles.map((d) => (
          <FileRow
            key={d.uploadId}
            file={d}
            name={d.name}
            remove={removeUpload}
            onUpdateName={
              d.status === "UploadCompleted"
                ? async (name: string) => {
                    debouncedOnUpdateName({ docId: d.documentId, name });
                    setDocuments((docs) => {
                      const i = docs.findIndex((doc) => doc.id === d.documentId);
                      const updatedDoc = { ...docs[i], name };
                      const updatedDocs = [...docs];
                      updatedDocs[i] = updatedDoc;
                      return updatedDocs;
                    });
                  }
                : undefined
            }
          />
        ))}
        {allFiles.length === 0 && <FormHelperText>{t("No documents added yet")}</FormHelperText>}
        <FormErrorMessage>{null}</FormErrorMessage>
      </FormControl>
    </>
  );
};
