import { Button, Flex, Icon, Spacer, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaFile } from "react-icons/fa";
import { SignedDocumentDto } from "../../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { DocumentSignersModal } from "./DocumentSignersModal";
import { SignedDocumentStatusTag } from "./SignedDocumentStatusTag";

interface Props {
  document: SignedDocumentDto;
  name: string;
}

export const SignedDocumentRow = ({ document, name }: Props) => {
  const { t } = useTranslation();
  const [showDocumentSignersModal, setShowDocumentSignersModal] = useState(false);
  return (
    <>
      {showDocumentSignersModal && (
        <DocumentSignersModal signedDocument={document} onClose={() => setShowDocumentSignersModal(false)} />
      )}
      <Flex
        key={document.id}
        backgroundColor="smBackgroundSecondary"
        minHeight={"50px"}
        alignItems="center"
        borderRadius={"10px"}
        mt="5px"
        paddingTop={"5px"}
        paddingBottom={"5px"}
      >
        <Flex>
          <Icon as={FaFile} mr="20px" ml="20px" color={"blue.500"} />
        </Flex>
        <Flex flexDirection={"column"} mr="15px" w="100%">
          <Text fontSize={"sm"} fontWeight={"normal"}>
            {name}
          </Text>
        </Flex>
        <Spacer />
        <Flex mr="20px">
          <SignedDocumentStatusTag status={document.status} />
        </Flex>
        <Flex pr="20px">
          <Button
            leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
            size="xs"
            variant={"solid"}
            colorScheme="blue"
            onClick={() => setShowDocumentSignersModal(true)}
          >
            {t("View signers")}
          </Button>
        </Flex>

        {document.status === "Signed" ? (
          <>
            <Flex mr="10px">
              <DownloadDocumentIconButton documentId={document.id} isSignedDocument={true} />
            </Flex>
          </>
        ) : (
          <Flex w="95px"></Flex>
        )}
      </Flex>
    </>
  );
};
