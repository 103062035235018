import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OrganizationEntryDto, OrganizationRoleType } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";

interface Props {
  entry: OrganizationEntryDto;
  onClose: () => void;
  linkWithExistingOrg?: () => void;
}

export const OrganizationEntryModal = ({ entry, onClose, linkWithExistingOrg }: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  return (
    <Modal isOpen={true} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent pb="10px">
        <ModalHeader>
          {entry.name} <br />
          <Box mt="5px">
            <OrganizationTypeTag type="OrganizationEntry" />
          </Box>
        </ModalHeader>
        <ModalBody mt="-15px">
          <Flex backgroundColor="smBackgroundSecondary" flexDirection={"column"} padding="10px" borderRadius={"10px"}>
            <Text fontWeight="bold">{t("Country")}</Text>
            <Text>{entry.country.name}</Text>
            <br />
            <Text fontWeight="bold">{t("Organization number")}</Text>
            <Text>{entry.organizationNumber}</Text>
          </Flex>
        </ModalBody>
        {!entry.link &&
          linkWithExistingOrg &&
          (["Admin", "Owner"] as OrganizationRoleType[]).some((role) => authState.selectedOrg.roles.includes(role)) && (
            <ModalFooter mt="-10px">
              <Button variant="solid" colorScheme={"purple"} onClick={linkWithExistingOrg}>
                {t("Link with existing org")}
              </Button>
            </ModalFooter>
          )}
      </ModalContent>
    </Modal>
  );
};
