import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationEntryPitchDto } from "../../../../autogen/bff-api";
import { OrganizationEntryModalWrapper } from "../../entry/view/OrganizationEntryModalWrapper";

interface Props {
  entries: OrganizationEntryPitchDto[];
}

export const LinkedEntries = ({ entries }: Props) => {
  const { t } = useTranslation();
  const [showEntryId, setShowEntryId] = useState<string>();

  return (
    <>
      {showEntryId && <OrganizationEntryModalWrapper entryId={showEntryId} onClose={() => setShowEntryId(undefined)} />}
      <Flex
        flexDirection={"column"}
        alignItems="start"
        justifyContent={"start"}
        borderRadius={"10px"}
        boxShadow="0 3px 15px rgba(71, 85, 105, 0.16)"
        padding="25px"
        bg="white"
        w="100%"
      >
        <Box w="100%">
          <Text fontSize={"md"} fontWeight="bold" color="#a4a7a6">
            {t("Linked organization entries")}
          </Text>
          <Flex flexDirection={"column"} w="100%">
            {entries.map((e) => (
              <Flex key={e.id} mt="10px">
                <Button variant={"link"} colorScheme="blue" onClick={() => setShowEntryId(e.id)}>
                  {e.name}
                </Button>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
