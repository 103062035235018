import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaCheckSquare, FaTimesCircle } from "react-icons/fa";
import { displayDate } from "../../../../common/formatting/displayDate";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";
import { GenericDetailRow, GenericDetails } from "./GenericDetails";

interface Props {
  data: ContractState["contract"];
}

export const Options = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const rows: GenericDetailRow[] = [];
  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);

  if (addedDataFields.includes("Options")) {
    rows.push({
      type: "Data",
      name: "",
      displayBelow: true,
      value: (
        <Box pt="2">
          {dataFields.options?.map((option) => (
            <Box key={option.id} width="full" pb="4">
              <Text fontWeight={"bold"} fontSize={"sm"}>
                {option.name}
              </Text>
              <Box as="pre" fontFamily={"body"} whiteSpace={"pre-wrap"} fontSize={"sm"} pb="2">
                {option.description}
              </Box>
              {option.startDate && (
                <Text fontSize={"sm"}>
                  {t("Start date")}: {displayDate(option.startDate)}
                </Text>
              )}
              {option.endDate && (
                <Text fontSize={"sm"}>
                  {t("End date")}: {displayDate(option.endDate)}
                </Text>
              )}
              {option.deadline && (
                <Text fontSize={"sm"}>
                  {t("Option execution deadline")}: {displayDate(option.deadline)}
                </Text>
              )}
              <Flex alignItems={"center"} fontSize={"sm"}>
                {t("Executed")}?{" "}
                {option.isExecuted ? (
                  <Icon as={FaCheckSquare} boxSize={4} color={"teal"} ml="2" />
                ) : (
                  <Icon as={FaTimesCircle} boxSize={4} ml="2" />
                )}
              </Flex>
            </Box>
          ))}
        </Box>
      ),
    });
  }

  return <GenericDetails title={t("Optional contract extensions")} rows={rows} />;
};
