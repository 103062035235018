import { Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EsgValue } from './EsgValue'

export const EsgScoring: React.FC = () => {
    const { t } = useTranslation()
    return (
        <Flex
            border="1px solid #e1e6ee"
            flexDirection={'column'}
            alignItems="start"
            justifyContent={'start'}
            borderRadius={'10px'}
            padding="25px"
            bg="white"
            w="100%"
            h="auto"
        >
            <Flex>
                <Text fontSize={'md'} fontWeight="bold" color="#a4a7a6">
                    {t('ESG Scorecard')}
                </Text>
            </Flex>
            <Grid gridTemplateColumns={`1fr 1fr 1fr`} w="100%" mt="15px">
                <GridItem>
                    <EsgValue
                        name={t('Environmental')}
                        color="green.500"
                        score={80}
                        size="140px"
                    />
                </GridItem>
                <GridItem>
                    <EsgValue
                        name={t('Social')}
                        color="blue.500"
                        score={30}
                        size="140px"
                    />
                </GridItem>
                <GridItem>
                    <EsgValue
                        name={t('Governance')}
                        color="orange.500"
                        score={50}
                        size="140px"
                    />
                </GridItem>
            </Grid>
            <Flex mt="20px">
                <Text color="#727272" fontSize={'sm'}>
                    {t('Learn more about how the scores are calculated')}{' '}
                    <Link color="teal.500" href="#">
                        {t('here')}
                    </Link>
                </Text>
            </Flex>
        </Flex>
    )
}
