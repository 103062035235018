import { Button, Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FaEye, FaRobot } from "react-icons/fa";
import { PersonDto, SignedDocumentDto, ViewDocumentAsOwnerDto } from "../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { displayDate } from "../../../../common/formatting/displayDate";
import { requireBooleanEnvVar } from "../../../../config";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { DocumentSignersModal } from "../../edit/docs/signatures/DocumentSignersModal";
import { SignedDocumentStatusTag } from "../../edit/docs/signatures/SignedDocumentStatusTag";
import { isDocument, isSignedDocument } from "./typeguards";

interface Props {
  contractDocument: ViewDocumentAsOwnerDto | SignedDocumentDto;
  setDocumentIdForAnalysis: Dispatch<SetStateAction<string | undefined>>;
}

export const ContractDocumentRow = ({ contractDocument, setDocumentIdForAnalysis }: Props) => {
  const [showDocumentSignersModal, setShowDocumentSignersModal] = useState<SignedDocumentDto>();
  const getDocumentName = (): string => {
    if (isSignedDocument(contractDocument)) {
      return contractDocument.name;
    } else if (isDocument(contractDocument)) {
      return contractDocument.name;
    } else {
      throw new Error("Should never happen");
    }
  };

  const getCreatedBy = (): PersonDto => {
    if (isSignedDocument(contractDocument)) {
      return contractDocument.sentForSigningBy;
    } else if (isDocument(contractDocument)) {
      return contractDocument.uploadedBy;
    } else {
      throw new Error("Should never happen");
    }
  };

  const uploadedBy = getCreatedBy();

  const showDownloadButton =
    isDocument(contractDocument) || (isSignedDocument(contractDocument) && contractDocument.status === "Signed");

  return (
    <>
      {showDocumentSignersModal && (
        <DocumentSignersModal
          signedDocument={showDocumentSignersModal}
          onClose={() => setShowDocumentSignersModal(undefined)}
        />
      )}
      <Tr key={contractDocument.id}>
        <Td>
          <Text fontSize={"sm"}>{getDocumentName()}</Text>
        </Td>
        <Td>
          <Flex>
            {isSignedDocument(contractDocument) && <SignedDocumentStatusTag status={contractDocument.status} />}
            {isSignedDocument(contractDocument) && (
              <Flex pl="10px">
                <Button
                  leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
                  size="xs"
                  variant={"solid"}
                  colorScheme="blue"
                  onClick={() => setShowDocumentSignersModal(contractDocument)}
                >
                  {t("View signers")}
                </Button>
              </Flex>
            )}
          </Flex>
        </Td>
        <Td>
          <Flex>
            <Flex>
              <ProfileImageRounded
                name={uploadedBy.firstName}
                backgroundColor={"teal.500"}
                width={"20px"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex mt="-2px" ml="5px">
              <Text fontSize={"sm"}>{`${uploadedBy.firstName} ${uploadedBy.lastName}`}</Text>
            </Flex>
          </Flex>
        </Td>
        <Td>
          <Text fontSize={"sm"}>{displayDate(contractDocument.uploadedAt)}</Text>
        </Td>
        <Td>
          {showDownloadButton && (
            <DownloadDocumentIconButton
              documentId={contractDocument.id}
              isSignedDocument={isSignedDocument(contractDocument)}
            />
          )}
        </Td>
        {requireBooleanEnvVar("VITE_ENABLE_DOCUMENT_ANALYSIS") && (
          <Td>
            <Icon cursor={"pointer"} as={FaRobot} onClick={() => setDocumentIdForAnalysis(contractDocument.id)} />
          </Td>
        )}
      </Tr>
    </>
  );
};
