import { Box, Button, Flex, Icon, Input, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { ClipboardEvent, DragEvent, useCallback, useEffect, useRef, useState } from "react";
import { FaPlus, FaUpload } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { read, utils } from "xlsx";
import { useGetProductGroupsForSourcingEventQuery } from "../../../../../autogen/bff-api";
import { useRumError } from "../../../../../common/errors/RumComponentContext/useRumError";
import { getParsedType, parseToType } from "../../../../../common/parsers/parseString";
import { useAppDispatch, useAppSelector } from "../../../../../common/redux/hooks";
import { mapDtoToProductGroup, setProductGroups } from "../../../../../common/redux/reducers/productReducer";
import { ProductField, ProductFieldType, ProductGroup, ProductTemplateField } from "../../../../../common/types";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { ProductGroupForm } from "./ProductGroupForm";
import { ProductTemplateModal } from "./ProductTemplateModal";

const mapTableToProductGroup = (table: string[][], name = "", sourcingEventId: string): ProductGroup => {
  const tableData = table.slice(1);
  const cleanedTableData = tableData.map((row) => row.map((d) => `${d ?? ""}`.trim()));
  const productFields = getFieldsFromTable(table[0], cleanedTableData);
  return {
    id: uuid(),
    sourcingEventId,
    name,
    products: cleanedTableData.map((row) => ({
      // Temporary id. ProductId will be generated backend.
      id: uuid(),
      quantity: {
        id: uuid(),
        name: "Quantity",
        populatedBy: "Buyer",
        isRequired: false,
      },
      productFields: productFields.map((_, i) => ({
        id: productFields[i].id,
        name: productFields[i].name,
        type: productFields[i].type,
        populatedBy: productFields[i].populatedBy,
        isRequired: productFields[i].isRequired,
        value:
          productFields[i].populatedBy === "Buyer"
            ? parseToType({ val: row[i], type: productFields[i].type })
            : undefined,
      })),
      conversation: [],
    })),
    productFields,
  };
};

const getParsedColumnType = (column: string[]): ProductFieldType => {
  if (column.some((datum) => !!datum && getParsedType(datum) === "Text")) return "Text";
  if (column.every((datum) => !datum || getParsedType(datum) === "Number")) return "Number";
  if (column.every((datum) => !datum || getParsedType(datum) === "Boolean")) return "Boolean";
  return "Text";
};

const getColumnPopulater = (column: string[]): ProductTemplateField["populatedBy"] => {
  if (column.some((datum) => !!datum)) return "Buyer";
  return "Supplier";
};

const getFieldsFromTable = (titles?: string[], tableData?: string[][]): ProductField[] => {
  if (!titles || !tableData) {
    throw Error("No titles or table data");
  }
  return titles.map<ProductField>((title, i) => ({
    id: uuid(),
    name: title,
    type: getParsedColumnType(tableData.map((row) => row[i])),
    populatedBy: getColumnPopulater(tableData.map((row) => row[i])),
    isRequired: false,
  }));
};

const findValidfile = (fileList: FileList) =>
  Array.from(fileList).find((file) => file.name.split(".").pop() === "xlsx");

export const Products = ({ nextStep, previousStep }: { nextStep: () => void; previousStep: () => void }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const rumError = useRumError();

  const eventState = useBasicSourcingEventState();
  const { data, isLoading, error } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId: eventState.id });

  const productGroups = useAppSelector((state) => state.product.data.productGroups);

  useEffect(() => {
    if (!productGroups.length && data?.productGroups.length) {
      dispatch(setProductGroups(data?.productGroups.map((group) => mapDtoToProductGroup(group))));
    }
    if (productGroups.length && !data?.productGroups.length) {
      dispatch(setProductGroups([]));
    }
  }, [data, dispatch, productGroups]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [productGroupDraft, setProductGroupDraft] = useState<ProductGroup>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = findValidfile(e.dataTransfer.files);
    if (!file) {
      toast({ description: `${t("The file type is not supported")}...`, status: "warning" });
      return;
    }
    const result = await parseFile(file);
    handleProductData(result?.table, result?.name);
  };

  const handlePaste = async (e: ClipboardEvent<HTMLDivElement>) => {
    const fileList = e.clipboardData.files;
    const file = findValidfile(fileList);
    const result = file ? await parseFile(file) : parsePastedData(e);
    if (!result) return;
    handleProductData(result?.table, result?.name);
  };

  const parsePastedData = useCallback(
    (e: ClipboardEvent<HTMLDivElement>): { table: string[][]; name?: string } | undefined => {
      try {
        const mime = "text/html"; // ["text/plain", "text/html", "text/rtf"];
        const data = e.clipboardData?.getData(mime);
        const workbook = read(data, { type: "string", FS: "," });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const table = utils.sheet_to_json<string[]>(worksheet, { header: 1 });
        return { table };
      } catch (_) {
        toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
      }
    },
    [toast]
  );

  const handleFileUpload = async (fileList: FileList) => {
    const file = findValidfile(fileList);
    if (!file) {
      toast({ description: `${t("The file type is not supported")}...`, status: "warning" });
      return;
    }
    const result = await parseFile(file);
    if (!result) return;
    handleProductData(result?.table, result?.name);
  };

  const handleProductData = (table?: string[][], name?: string) => {
    if (!table?.[0]?.[0]) {
      toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
      return;
    }
    try {
      const trimmedTable = trimEmptyRows(table);
      const groupDraft = mapTableToProductGroup(trimmedTable, name, eventState.id);
      setProductGroupDraft(groupDraft);
      setIsModalOpen(true);
    } catch (e) {
      toast({ description: `${t("We're not able to interpret that data")}...`, status: "warning" });
    }
  };

  const trimEmptyRows = useCallback(
    (table: string[][]) => {
      try {
        return table?.filter((row) => row.some((value) => !!`${value ?? ""}`?.trim()));
      } catch (e) {
        rumError({ message: "Trimming empty rows failed." }, { error: e });
        throw e;
      }
    },
    [rumError]
  );

  const parseFile = useCallback(async (file: File) => {
    const wb = read(await file.arrayBuffer());
    const name = wb.SheetNames[0];
    const workSheet = wb.Sheets[name];
    const table = utils.sheet_to_json<string[]>(workSheet, { header: 1 });
    return { table, name };
  }, []);

  if (isLoading) return <Text>{t("Loading")}...</Text>;

  if (error || !data) return <Text>{t("Something went wrong")}...</Text>;

  return (
    <Box height={"100%"} width="full">
      {productGroups.length === 0 ? (
        <Flex
          justify={"center"}
          align={"center"}
          height={"64"}
          backgroundColor="smBackgroundSecondary"
          rounded="lg"
          border={"2px"}
          borderColor={isHighlighted ? "blue.100" : "gray.50"}
          mb="8"
          mt="8"
          onPaste={(e) => handlePaste(e)}
          onDrop={(e) => {
            handleDrop(e);
            setIsHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsHighlighted(true);
          }}
          onDragLeave={() => setIsHighlighted(false)}
        >
          <Box fontStyle={"italic"} color="smMuted">
            <Text pb="4">{t("Enter your product data by")}...</Text>
            <Text pb="2">1. {t("clicking here and pasting a table or file")},</Text>
            <Text pb="2">2. {t("dragging and dropping a file")},</Text>
            <Text pb="2">3. {t("clicking the upload file button, or")}</Text>
            <Text pb="2">4. {t("adding a new product group manually")}.</Text>
          </Box>
        </Flex>
      ) : (
        <Flex
          justify={"center"}
          align={"center"}
          backgroundColor="smBackgroundSecondary"
          rounded="lg"
          border={"2px"}
          borderColor={isHighlighted ? "blue.100" : "smBackgroundSecondary"}
          mb="8"
          mt="8"
          onPaste={(e) => handlePaste(e)}
          onDrop={(e) => {
            handleDrop(e);
            setIsHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            setIsHighlighted(true);
          }}
          onDragLeave={() => setIsHighlighted(false)}
        >
          <Box fontStyle={"italic"} color={"gray.500"}>
            <Text py="4">Click and paste or drag and drop product data...</Text>
          </Box>
        </Flex>
      )}
      <Flex justify={"space-between"}>
        <Button variant={"outline"} mr="5px" colorScheme={"teal"} size={"sm"} onClick={() => setIsModalOpen(true)}>
          {t("New product group")} <Icon as={FaPlus} ml="2" />
        </Button>
        <label>
          <Button
            variant={"outline"}
            mr="5px"
            colorScheme={"teal"}
            size="sm"
            onClick={() => fileInputRef.current?.click()}
          >
            {t("Upload file")} <Icon as={FaUpload} ml="2" />
          </Button>
          <Input
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {
              const files = e.target.files;
              if (files) handleFileUpload(files);
            }}
          />
        </label>
      </Flex>
      {productGroups?.map((group) => (
        <ProductGroupForm key={group.id} group={group} />
      ))}
      <ProductTemplateModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} productGroupDraft={productGroupDraft} />
      <Flex pt="8">
        <Button variant={"outline"} mr="5px" w="50%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="50%" colorScheme={"teal"} onClick={() => nextStep()}>
          {t("Next")}
        </Button>
      </Flex>
    </Box>
  );
};
