import { Button, Flex, Td, Tr, useToast } from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  OrganizationRoleType,
  useDeleteOrgUserMutation,
  useUpdateOrgUserRoleMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../../common/errors/useApiError";
import { MultiSelector } from "../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { ConfirmationModalConfig } from "../../../../common/useConfirmationModal";
import { selectableRoles } from "./config";
import { User } from "./types";
import { getRoleName } from "./utils";

interface Props {
  user: User;
  confirmationModal: {
    show: (config: ConfirmationModalConfig) => void;
    modal: ReactElement;
  };
}

export const UserRow = ({ user, confirmationModal }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const [deleteUser] = useDeleteOrgUserMutation();
  const [updateRole] = useUpdateOrgUserRoleMutation();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const displayer = useApiError();
  const [showChangeRole, setShowChangeRole] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState<SelectorValue[]>(
    user.roles.map((e) => ({
      value: e,
      label: getRoleName(e),
    }))
  );
  const [isChangingRole, setIsChangingRole] = useState(false);

  const triggerDelete = async () => {
    const result = await deleteUser({
      orgId: authState.selectedOrg.id,
      userId: user.id,
    });
    if ("data" in result) {
      toast({
        title: t("Invite deleted!"),
        status: "success",
      });
      dispatch(updateSelectedOrg(result.data as OrgDto));
    } else {
      displayer.trigger(result.error);
    }
  };

  const triggerChangeRole = async () => {
    setIsChangingRole(true);
    const result = await updateRole({
      orgId: authState.selectedOrg.id,
      personId: user.id,
      updateOrgUserRoleRequest: {
        newRoles: selectedRoles.map((e) => e.value as OrganizationRoleType),
      },
    });
    if ("data" in result) {
      toast({
        title: t("Role changed!"),
        status: "success",
      });
      dispatch(updateSelectedOrg(result.data as OrgDto));
      setShowChangeRole(false);
    } else {
      displayer.trigger(result.error);
    }
    setIsChangingRole(false);
  };

  return (
    <>
      <Tr key={user.id}>
        <Td>{user.name}</Td>
        <Td>{user.email}</Td>
        <Td>
          {showChangeRole ? (
            <MultiSelector
              value={selectedRoles}
              options={selectableRoles}
              noOptionsAvailableMessage={t("No roles available")}
              onChange={(value) => {
                const roleTypes = value.map((e) => e.value as OrganizationRoleType);
                let filteredRoles = value;

                if (roleTypes.includes("Admin")) {
                  filteredRoles = filteredRoles.filter((e) => e.value === "Admin");
                }

                if (roleTypes.includes("ContractCreator") && roleTypes.includes("ContractViewer")) {
                  filteredRoles = filteredRoles.filter((e) => e.value !== "ContractViewer");
                }

                if (roleTypes.includes("SourcingCreator") && roleTypes.includes("SourcingViewer")) {
                  filteredRoles = filteredRoles.filter((e) => e.value !== "SourcingViewer");
                }
                setSelectedRoles(filteredRoles);
              }}
            />
          ) : (
            user.roles.map((e) => t(getRoleName(e))).join(", ")
          )}
        </Td>
        <Td>
          {showChangeRole && (
            <>
              <Button
                mr="5px"
                variant={"solid"}
                colorScheme="blue"
                size={"sm"}
                isLoading={isChangingRole}
                onClick={triggerChangeRole}
              >
                {t("Save")}
              </Button>
              <Button variant={"solid"} colorScheme="orange" size={"sm"} onClick={() => setShowChangeRole(false)}>
                {t("Cancel")}
              </Button>
            </>
          )}
          {user.id !== authState.me.id && !user.roles.includes("Owner") && !showChangeRole && (
            <Flex columnGap="1" rowGap="1">
              <Button variant={"solid"} colorScheme="blue" size={"sm"} onClick={() => setShowChangeRole(true)}>
                {t("Change role")}
              </Button>
              <Button
                variant={"solid"}
                colorScheme="red"
                size={"sm"}
                onClick={() => {
                  confirmationModal.show({
                    title: t("Remove person?"),
                    description: t("Are you sure you want to remove this person?"),
                    onConfirmed: async () => {
                      await triggerDelete();
                    },
                  });
                }}
              >
                {t("Remove")}
              </Button>
            </Flex>
          )}
        </Td>
      </Tr>
    </>
  );
};
