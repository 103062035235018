import { Box, Button, Flex, Grid, GridItem, HStack, Icon, Spacer, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { urls } from "../../../urls";
export const Info: React.FC = () => {
  const { show } = useIntercom();
  const authState = useLoggedInWithOrgContextState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return (
    <Grid gridTemplateColumns={"1fr"} gridTemplateRows={"50px 190px 100px"} mb="20px">
      <GridItem display={"flex"} alignItems="center">
        <Spacer />
        <Button
          mt="30px"
          leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}
          variant={"outline"}
          colorScheme="teal"
          size={"sm"}
          onClick={() => navigate(urls.myProfile.personal.edit)}
        >
          {t("Edit")}
        </Button>
      </GridItem>
      <GridItem>
        <Grid height="100%" gridTemplateColumns={"auto 1fr"} gridTemplateRows="1fr">
          <GridItem display={"flex"} flexDirection="column" justifyContent="center">
            <Flex w="100px" h="100px" bg="teal.500" borderRadius={"50%"} alignItems="center" justifyContent={"center"}>
              <Text fontSize={"4xl"} fontWeight="bold" color={"white"}>
                {authState.me.firstName?.charAt(0)}
              </Text>
            </Flex>
          </GridItem>
          <GridItem display={"flex"} flexDirection="column" justifyContent="center">
            <Box ml="3">
              <Text fontSize="2xl">
                <b>{`${authState.me.firstName} ${authState.me.lastName}`}</b>
              </Text>
            </Box>
            <Box ml="3">
              <Text fontSize="md" color="smMuted">
                {authState.me.email}
              </Text>
            </Box>
            <Box ml="3">
              <Text fontSize="md">{authState.selectedOrg.name}</Text>
            </Box>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        borderRadius={10}
        boxShadow="0 7px 15px rgba(71, 85, 105, 0.16)"
        display={"flex"}
        flexDirection="column"
        justifyContent={"center"}
      >
        <HStack padding={10}>
          <Box>
            <Text fontSize="md">
              <b>{t("Help us get better!")}</b>
            </Text>
            <Text fontSize="xs">
              {t(
                "Something you think is great about our product? Something bad? Give us feedback and we'll do our best to fix it as soon as possible!"
              )}
            </Text>
          </Box>
          <Spacer />
          <Button
            variant={"solid"}
            colorScheme="teal"
            w="170px"
            style={
              !isLargerThan768
                ? {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }
                : undefined
            }
            onClick={show}
          >
            {t("Send feedback")}
          </Button>
        </HStack>
      </GridItem>
    </Grid>
  );
};
