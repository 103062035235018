import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../../common/redux/hooks'
import { containsError } from '../../../../../../common/redux/reducers/contractReducer'
import { editContractThunk } from '../../../../../../common/redux/thunks/contract/edit-contract-thunk'
import { requireDataFields } from '../../../../typeguards'
import { useContractState } from '../../../useContractState'
import { GenericDateField } from './GenericDateField'

interface Props {
    removeDataField?: () => Promise<void>
    isRequiredButNotProvided: boolean
}

export const WarrantyExpirationDate: React.FC<Props> = ({
    removeDataField,
    isRequiredButNotProvided,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const contractState = useContractState()
    const dataFields = requireDataFields(contractState.contract)
    const date = dataFields.warrantyExpirationDate ?? null
    const errorMessage = containsError(
        contractState,
        'EditWarrantyExpirationDate'
    )
        ? t('Update failed')
        : null

    return (
        <GenericDateField
            id={'warrantyExpirationDate'}
            title={t('Warranty expiration date')}
            placeHolderText={''}
            helperText={t('Provide a warranty expiration date')}
            date={date}
            errorMessage={errorMessage}
            onDateChange={async (newDate) => {
                await dispatch(
                    editContractThunk({
                        command: {
                            type: 'EditWarrantyExpirationDate',
                            value: newDate,
                        },
                    })
                )
            }}
            removeDataField={removeDataField}
            isRequiredButNotProvided={isRequiredButNotProvided}
        />
    )
}
