import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FaCheckSquare, FaPlus, FaTimesCircle, FaTrash } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { OptionDto } from "../../../../../autogen/bff-api";
import { displayDate } from "../../../../../common/formatting/displayDate";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";
import { GenericDateField } from "./dates/GenericDateField";

export const ContractOptions = () => {
  const contractState = useContractState();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [options, setOptions] = useState<OptionDto[]>();
  const [optionToBeExecuted, setOptionToBeExecuted] = useState<OptionDto>();

  useEffect(() => {
    if (!options) setOptions(contractState.contract.dataFields.options ?? []);
  }, [contractState.contract.dataFields.options, options]);

  const editOption = useCallback(
    async (option: OptionDto) => {
      await dispatch(
        editContractThunk({
          command: {
            type: "EditOption",
            option,
          },
        })
      );
    },
    [dispatch]
  );

  const executeOption = useCallback(
    async (option?: OptionDto) => {
      if (!option) return;
      setOptions((options) => {
        const index = options?.findIndex((o) => o.id === option.id);
        if (!options || index === -1 || index === undefined) return;
        const updatedOptions = [...options];
        updatedOptions[index] = { ...updatedOptions[index], isExecuted: true };
        return updatedOptions;
      });
      await dispatch(
        editContractThunk({
          command: {
            type: "ExecuteOption",
            id: option.id,
          },
        })
      );
      toast({
        title: `${t("Option executed")}!`,
        description: `${t("Option executed and contract end date updated")}.`,
        colorScheme: "green",
      });
    },
    [dispatch, toast]
  );

  const removeOption = useCallback(
    async (id: string) => {
      await dispatch(
        editContractThunk({
          command: {
            type: "RemoveOption",
            id,
          },
        })
      );
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editOptionDebounced = useCallback(
    debounce((option: OptionDto) => editOption(option), 300),
    [editOption]
  );

  const handleOptionChange = (option: OptionDto) => {
    setOptions((o) => {
      const optionIndex = o?.findIndex((o) => o.id === option.id);
      if (!optionIndex && optionIndex !== 0) return o;
      const updatedOptions = [...(o ?? [])];
      updatedOptions[optionIndex] = option;
      return updatedOptions;
    });
    editOptionDebounced(option);
  };

  return (
    <>
      <Modal isOpen={!!optionToBeExecuted} onClose={() => setOptionToBeExecuted(undefined)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t("Confirm execution of")} {optionToBeExecuted?.name}
          </ModalHeader>
          <ModalBody>
            <Text>
              {t("Are you sure you want to extend the contract?")} {t("The new contract end date will be")}{" "}
              <b>{displayDate(optionToBeExecuted?.endDate ?? "")}</b>.
            </Text>
            <Text pt="4">
              {t("Remember to notify your counterparty that you want to execute this optional extension")}.
            </Text>
            <Text pt="4">{t("Note that once the contract is extended, it cannot be undone")}.</Text>
          </ModalBody>
          <ModalFooter display={"flex"} justifyContent={"space-between"}>
            <Button size="sm" onClick={() => setOptionToBeExecuted(undefined)}>
              {t("Cancel")}
            </Button>
            <Button
              size="sm"
              variant={"outline"}
              colorScheme="teal"
              onClick={() => {
                executeOption(optionToBeExecuted);
                setOptionToBeExecuted(undefined);
              }}
            >
              {t("Execute Option")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex flexDirection={"column"}>
        {options?.map((option) => (
          <Flex key={option.id} flexDirection={"column"} backgroundColor={"white"} rounded="md" p="2" my="2">
            <Input
              backgroundColor={"white"}
              borderColor={"gray.200"}
              size={"sm"}
              rounded="md"
              placeholder={`${t("Title")}...` ?? ""}
              value={option.name}
              isDisabled={option.isExecuted}
              onChange={(e) => handleOptionChange({ ...option, name: e.target.value })}
            />
            <Textarea
              backgroundColor={"white"}
              borderColor={"gray.200"}
              size={"sm"}
              rounded="md"
              mt="2"
              placeholder={`${t("Description")}...` ?? ""}
              value={option.description}
              isDisabled={option.isExecuted}
              onChange={(e) => handleOptionChange({ ...option, description: e.target.value })}
            />
            <Box pt="2">
              <GenericDateField
                id={`${option.id}-start-date`}
                title={t("Start date")}
                placeHolderText={t("Select start date")}
                helperText=""
                date={option.startDate ?? null}
                errorMessage={null}
                onDateChange={(date) => {
                  handleOptionChange({ ...option, startDate: moment(date).toISOString() });
                }}
                isDisabled={option.isExecuted}
                isRequiredButNotProvided={false}
              />
            </Box>
            <Box pt="2">
              <GenericDateField
                id={`${option.id}-deadline`}
                title={t("Option execution deadline")}
                placeHolderText={t("Select deadline")}
                helperText=""
                date={option.deadline ?? null}
                errorMessage={null}
                isDisabled={option.isExecuted}
                onDateChange={(date) => {
                  handleOptionChange({ ...option, deadline: moment(date).toISOString() });
                }}
                isRequiredButNotProvided={false}
              />
            </Box>
            <Box pt="2">
              <GenericDateField
                id={`${option.id}-end-date`}
                title={t("End date")}
                placeHolderText={t("Select end date")}
                helperText=""
                date={option.endDate ?? null}
                errorMessage={null}
                isDisabled={option.isExecuted}
                onDateChange={(date) => {
                  handleOptionChange({ ...option, endDate: moment(date).toISOString() });
                }}
                isRequiredButNotProvided={false}
              />
            </Box>
            <Flex justifyContent={"space-between"} alignItems={"center"} pt="2">
              {option.isExecuted ? (
                <Flex alignItems={"center"} fontSize={"sm"} fontWeight={"bold"} py="2">
                  {t("Executed")}{" "}
                  {option.isExecuted ? (
                    <Icon as={FaCheckSquare} boxSize={4} color={"teal"} ml="2" />
                  ) : (
                    <Icon as={FaTimesCircle} boxSize={4} ml="2" />
                  )}
                </Flex>
              ) : (
                <Box py="2">
                  <Button
                    isDisabled={!option.endDate}
                    size="sm"
                    variant={"outline"}
                    colorScheme="teal"
                    onClick={() => setOptionToBeExecuted(option)}
                  >
                    {t("Execute Option")}
                  </Button>
                </Box>
              )}
              <Box>
                <IconButton
                  aria-label="delete option"
                  size={"sm"}
                  icon={<Icon as={FaTrash} />}
                  onClick={() => {
                    removeOption(option.id);
                    setOptions((options) => options?.filter((o) => o.id !== option.id));
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        ))}
        <Box>
          <Button
            variant={"outline"}
            size="sm"
            colorScheme="teal"
            rightIcon={<Icon as={FaPlus} />}
            onClick={() =>
              setOptions([
                ...(options ?? []),
                {
                  id: uuid(),
                  name: "",
                  description: "",
                  isExecuted: false,
                  deadline: undefined,
                  startDate: undefined,
                  endDate: undefined,
                },
              ])
            }
          >
            {t("Add")}
          </Button>
        </Box>
      </Flex>
    </>
  );
};
