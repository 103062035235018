import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailInviteContextDto, OrgDto as ErroneousOrgDto, MeDto } from "../../../autogen/bff-api";
import { ContractDataFieldSection, CustomContractField, CustomContractFieldSections } from "../../types";
import { CreateOrgError, createOrgThunk } from "../thunks/auth/create-org-thunk";
import { getInitialAuthStateThunk } from "../thunks/auth/get-initial-auth-state-thunk";
import { LoginError, loginThunk } from "../thunks/auth/login-thunk";
import { reloadMeThunk } from "../thunks/auth/reload-me-thunk";
import { signupAsOrgThunk } from "../thunks/auth/signup-as-org-thunk";
import { signupAsPersonThunk, SignupError } from "../thunks/auth/signup-as-person-thunk";

export type OrgDto = Omit<ErroneousOrgDto, "customContractSections"> & {
  customContractSections?: CustomContractFieldSections;
};

type LoggedOutState = {
  type: "LoggedOut";
  isLoggingIn: boolean;
  loginError: LoginError | string | null;
  isSigningUp: boolean;
  signupError: SignupError | string | null;
};

type LoggedInState = {
  type: "LoggedIn";
  me: MeDto;
  organizations: OrgDto[];
  invitationContext: EmailInviteContextDto | null;
  isCreatingOrg: boolean;
  createOrgError: CreateOrgError | null;
};

type LoggedInWithOrgContextState = {
  type: "LoggedInWithOrgContext";
  me: MeDto;
  organizations: OrgDto[];
  selectedOrg: Omit<OrgDto, "customContractSections"> & { customContractSections?: CustomContractFieldSections };
};

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface PersonCredentials {
  firstName: string;
  lastName: string;
}

export interface OrgCredentials {
  companyName: string;
  countryId: string;
  organizationNumber: string;
}

export type AuthState = LoggedOutState | LoggedInState | LoggedInWithOrgContextState;

interface State {
  state: AuthState | null;
}

const initialState: State = {
  state: null,
};

export const slice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    logout: () => ({ state: null }),
    updateSelectedOrg: (state, action: PayloadAction<OrgDto>) => {
      if (state.state?.type === "LoggedInWithOrgContext") {
        const orgs = state.state.organizations.filter((e) => e.id !== action.payload.id);
        const newOrgs = orgs.concat([action.payload]);

        return {
          ...state,
          state: {
            ...state.state,
            organizations: newOrgs,
            selectedOrg: action.payload,
          },
        };
      } else {
        return state;
      }
    },
    addContractSection: (state, action: PayloadAction<ContractDataFieldSection>) => {
      if (state.state?.type === "LoggedInWithOrgContext") {
        const sections =
          {
            [action.payload.id]: action.payload,
            ...state.state.selectedOrg.customContractSections,
          } ?? ({} as { [id: string]: ContractDataFieldSection });
        state.state.selectedOrg.customContractSections = sections;
      }
    },
    addCustomField: (state, action: PayloadAction<{ sectionId: string; field: CustomContractField }>) => {
      if (state.state?.type === "LoggedInWithOrgContext") {
        const section = state.state.selectedOrg.customContractSections?.[action.payload.sectionId] as
          | ContractDataFieldSection
          | undefined;
        if (!section) throw Error("Contract section does not exist");
        section.fields = [...section.fields, action.payload.field];
        const sections = state.state.selectedOrg.customContractSections as { [id: string]: ContractDataFieldSection };
        sections[action.payload.sectionId] = section;
        state.state.selectedOrg.customContractSections = sections;
      }
    },
    editCustomField: (state, action: PayloadAction<{ sectionId: string; field: CustomContractField }>) => {
      const sectionId = action.payload.sectionId;
      const field = action.payload.field;
      if (state.state?.type === "LoggedInWithOrgContext") {
        const section = state.state.selectedOrg.customContractSections?.[sectionId] as
          | ContractDataFieldSection
          | undefined;
        if (!section) throw Error("Contract section does not exist");
        const fieldIndex = section.fields.findIndex((f) => f.id === field.id);
        if (fieldIndex === -1) throw Error("Field does not exist");
        section.fields[fieldIndex] = field;
        const sections = state.state.selectedOrg.customContractSections as { [id: string]: ContractDataFieldSection };
        sections[action.payload.sectionId] = section;
        state.state.selectedOrg.customContractSections = sections;
      }
    },
    removeCustomField: (state, action: PayloadAction<{ sectionId: string; fieldId: string }>) => {
      const sectionId = action.payload.sectionId;
      const fieldId = action.payload.fieldId;
      if (state.state?.type === "LoggedInWithOrgContext") {
        const section = state.state.selectedOrg.customContractSections?.[sectionId] as
          | ContractDataFieldSection
          | undefined;
        if (!section) throw Error("Contract section does not exist");
        section.fields = section.fields.filter((f) => f.id !== fieldId);
        const sections = state.state.selectedOrg.customContractSections as { [id: string]: ContractDataFieldSection };
        sections[action.payload.sectionId] = section;
        state.state.selectedOrg.customContractSections = sections;
      }
    },
    updateMe: (state, action: PayloadAction<MeDto>) => {
      if (state.state?.type === "LoggedInWithOrgContext") {
        return {
          ...state,
          state: {
            ...state.state,
            me: action.payload,
          },
        };
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialAuthStateThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
    builder.addCase(loginThunk.pending, (state) => {
      state.state = {
        type: "LoggedOut",
        isLoggingIn: true,
        loginError: null,
        isSigningUp: false,
        signupError: null,
      };
    });
    builder.addCase(createOrgThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
    builder.addCase(createOrgThunk.pending, (state) => {
      const currentState = state.state;
      if (currentState?.type !== "LoggedIn") {
        throw Error("Should never happen");
      }
      state.state = {
        type: "LoggedIn",
        me: currentState.me,
        organizations: currentState.organizations,
        createOrgError: currentState.createOrgError,
        isCreatingOrg: true,
        invitationContext: currentState.invitationContext,
      };
    });
    builder.addCase(signupAsPersonThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
    builder.addCase(signupAsPersonThunk.pending, (state) => {
      const currentState = state.state;
      if (!currentState || currentState.type !== "LoggedOut") {
        throw Error("Should never happen");
      }
      state.state = {
        type: "LoggedOut",
        isLoggingIn: false,
        loginError: null,
        isSigningUp: true,
        signupError: null,
      };
    });
    builder.addCase(signupAsOrgThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.state = payload;
    });
    builder.addCase(signupAsOrgThunk.pending, (state) => {
      const currentState = state.state;
      if (!currentState || currentState.type !== "LoggedOut") {
        throw Error("Should never happen");
      }
      state.state = {
        type: "LoggedOut",
        isLoggingIn: false,
        loginError: null,
        isSigningUp: true,
        signupError: null,
      };
    });
    builder.addCase(reloadMeThunk.fulfilled, (state, action) => {
      if (state.state?.type === "LoggedIn") {
        state.state.me = action.payload.me;
        state.state.organizations = action.payload.organizations as OrgDto[];
      } else if (state.state?.type === "LoggedInWithOrgContext") {
        state.state.me = action.payload.me;
        state.state.organizations = action.payload.organizations as OrgDto[];
        state.state.selectedOrg = action.payload.selectedOrg as OrgDto;
      } else {
        throw Error('Can only reload me in "LoggedIn" or "LoggedInWithOrgContext" states.');
      }
    });
  },
});

export const {
  logout,
  updateSelectedOrg,
  addContractSection,
  addCustomField,
  editCustomField,
  removeCustomField,
  updateMe,
} = slice.actions;
export default slice.reducer;
